import React from "react";
import "./About.css";
import aboutImg from "../../assets/about-home.jpg";
import { Link } from "react-router-dom";

const About = () => {
  return (
    <section id="about" className="about">
      <Link
        to="/our-work/upcoming-projects"
        className="btn-read-more d-inline-flex align-items-center "
        style={{ height: "25px", borderRadius: "25px" }}
      >
        <span>Our upcomming projects</span>
        <i className="bi bi-arrow-right"></i>
      </Link>
      <div className="container" data-aos="fade-up">
        <div className="row gx-0">
          <div
            className="col-lg-6 d-flex flex-column justify-content-center"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <div className="content">
              <h2>At Sovereign Pacific Capital</h2>
              <p>
                Sovereign Pacific Capital is fully invested in being an
                organization, one can trust to provide the best financial
                solutions to succeed. We foster a diverse, connected,
                collaborative culture that encourages various ways of thinking
                with differentiated insights. We embrace innovation to address
                increasingly complex investment challenges to deliver long term
                sustainable growth.
              </p>
              <div className="text-center text-lg-start">
                <Link
                  style={{ borderRadius: "25px" }}
                  to="/about-us/our-people"
                  className="btn-read-more d-inline-flex align-items-center justify-content-center align-self-center"
                >
                  <span>Read More</span>
                  <i className="bi bi-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>

          <div
            className="col-lg-6 d-flex align-items-center"
            data-aos="zoom-out"
            data-aos-delay="200"
          >
            <img src={aboutImg} className="img-fluid" alt="" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
