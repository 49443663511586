import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import About from "../../components/About-Home/About";
import ApiData from "../../components/API/ApiData";
import Careers from "../../components/Careers-Home/Careers";
import CurrentNews from "../../components/CurrentNews-Home/CurrentNews";
import Hero from "../../components/Hero-Home/Hero";
import Industry from "../../components/Industry-Home/Industry";
import Investments from "../../components/Investments-Home/Investments";
// import Message from "../../components/MessageFromFounder/Message";
// import Message2 from "../../components/MessageFromFounder/Message2";
import Partners from "../../components/Partners/Partners";
import PartnerWithUs from "../../components/PartnerWithUs/PartnerWithUs";
import Services from "../../components/Services-Home/Services";
// import Spotlight from "../../components/SPCSpotlight/Spotlight";
import WorkInProgress from "../../components/WorkInProgress-Home/WorkInProgress";
import SpcBankSpotlight from "../../components/SPC-Bank-Spotlight/SpcBankSpotlight";
import SpcSpotlightLatest from "../../components/SPC-Bank-Spotlight/SpcSpotlightLatest";
import MessageLatest from "../../components/MessageFromFounder/MessageLatest";

const Home = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto",
    });
  }, [pathname]);
  return (
    <>
      <Hero />
      <MessageLatest />
      <SpcSpotlightLatest />
      <SpcBankSpotlight />
      <About />
      {/* <Spotlight/> */}
      <ApiData />
      <WorkInProgress />
      <Industry />
      <CurrentNews />
      <PartnerWithUs />
      <Services />
      <Investments />
      <Partners />
      <Careers />
    </>
  );
};

export default Home;
