import React,{useState} from 'react'
import Button from 'react-bootstrap/esm/Button'

const TabContent = (props) => {
    const [basicActive, setBasicActive] = useState("tab1");
  return (
    <div className="row mb-2 mt-5">
    <div className="col-md-6 mx-auto" style={{ width: "90%" }}>
      <div className="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
        <div className="col p-4 d-flex flex-column position-static">
          <h3 className="mb-0 head text-center mb-3">
         {props.heading}
          </h3>
          <p className="card-text mb-auto lead">
            {props.text}
          </p>
          <div className="d-flex justify-content-center">
            <Button className="mt-3 mb-3" href={props.href} style={{ width: "50%" }}>
             Know More
            </Button>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default TabContent