import React, { useEffect } from "react";
import Header from "../../components/Header/Header";
import SMHeader from "../../assets/SMHeader.png";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Cards from "../../components/Cards/Cards";
import em1 from '../../assets/em1.jpg'
import em2 from '../../assets/em2.jpg'
import em3 from '../../assets/em3.jpg'
import em4 from '../../assets/em4.jpg'
import em5 from '../../assets/em5.jpg'
import em6 from '../../assets/em6.jpg'
import Button from "../../components/Button/Button";
import { Link, useLocation } from "react-router-dom";

const ElectricMobility = () => {
  const {pathname} = useLocation();

  useEffect(() => {
    document.documentElement.scrollTo({
      top:0,
      left:0,
      behavior:"auto"
    })
  }, [pathname])
  return (
    <>
      <Header src={SMHeader} heading="Our Work" />
      <div className="mt-5 mb-5 container">
        <h2 className="mt-5 mb-5 m-3 text-left headings">
          Electric Mobility
        </h2>
        <div className=" mt-5 mb-5 column-full p-3">
          <p className="contentFont mb-2">
            Sovereign Pacific Capital (SPC) nurtures and believes in the idea of
            clean and electric technology. Our investments with Electric
            Mobility will help achieve impactful milestone and help accelerate
            the initiative to zero carbon transportation. Electric Mobility
            combines over a century of engineering expertise with the best
            innovators and cutting-edge technology to deliver unrivalled product
            choice on a global scale. Electric Mobility is the next-generation,
            carbon neutral electric bus and light commercial vehicle to be used
            in India. Designed, developed and manufactured in India and
            utilising a global electric bus experience, The Electronic Vehicle
            is equipped with the latest technology, ultra-modern design, highest
            safety and best-in-class comfort features. The renewed iconic double
            decker bus is designed to revolutionize public transportation in the
            country and will set new standards in the intra-city bus market. All
            of these vehicles will not only help to reduce each commuter’s
            carbon footprint, but also help to realise the goal of a greener
            planet.
          </p>
        </div>
        <Row className="mt-3 mb-3">
          <Col className="">
           <Cards heading="Carbon Neutral Initiative" text="Our Electric Mobility sustainable initiative aims at introducing
              public commuting vehicles producing zero carbon footprint, using
              cleaner energy and smarter transportation building roads that are
              less congested and air that is cleaner."/>
          </Col>
          <Col>
            <Cards heading="Smart Technology" text="Our Green Initiative is introducing an In-house technology
              innovation helping us to transform mobility through an intelligent
              connected fleet that acts like devices on wheels giving every
              customer total control"/>
          </Col>
        </Row>
        <Row className="mt-3 mb-3">
          <Col>
            <Cards heading="Technology Service" text="The initiative also offers monitoring, management and real time
              upgrades and helps collect over 2 TB of data daily to help every
              customer make the best decision for their business."/>
          </Col>
          <Col>
            <Cards heading="Affordable Technology" text="Electric Mobility is helping cities go electric which means
              offering affordable transportation alternatives through their
              lightweight modular buses, vans and pods which have the lowest
              cost of ownership available in the market."/>
          </Col>
        </Row>
        <Row >
          <Col className="text-center"><img  src={em1} height="300px" width="300px"/></Col>
          <Col   className="text-center"><img src={em2} height="300px" width="300px"/></Col>
          <Col  className="text-center"><img src={em3} height="300px" width="300px"/></Col>
        </Row>
        <Row className="mt-3 mb-3">
          <Col  className="text-center"><img src={em4} height="300px" width="300px"/></Col>
          <Col  className="text-center"><img src={em5} height="300px" width="300px"/></Col>
          <Col  className="text-center"><img src={em6} height="300px" width="300px"/></Col>
        </Row>
        <div className="mt-3 text-center">
          <a href="https://www.switchmobility.tech/" className="btn" target="_blank">Know More</a>
        </div>
      </div>
    </>
  );
};

export default ElectricMobility;
