import React, { useEffect } from "react";
import Header from "../../components/Header/Header";
import about1 from "../../assets/ourCulture.png";
// import UsMishra from "../../assets/usm.png";
import UsMishra2 from "./images/usm-2.jpeg";
import Bora from "../../assets/bora.svg";
import analdo from "../../assets/analdo.svg";
import pradeep from "../../assets/pradeep.svg";
import prasad from "../../assets/prasad.svg";
import SKNLogo from "./images/skn_logo.png";
import Leslie from "../../assets/leslie.png";

import Row from "react-bootstrap/Row";
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardImage,
} from "mdb-react-ui-kit";
import { Link, useLocation } from "react-router-dom";

const About = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto",
    });
  }, [pathname]);
  return (
    <>
      <Header src={about1} heading="Our People" />
      <div className="m-4 border-bottom">
        <div className="px-4 pt-3 my-5 text-center ">
          <p
            className="contentFont"
            style={{ textAlign: "justify", fontWeight: "400" }}
          >
            At Sovereign Pacific Capital, we are entirely invested in being an
            organization that our clients can trust to deliver the best
            investment solutions and financial plans for their future. In
            pursuit to achieve success, we foster a diverse, connected,
            collaborative culture that encourages various ways of thinking and
            differentiated insights. We embrace innovation to address
            increasingly complex investment challenges and deliver long term
            Sustainable Financial Growth. At present, SPC manages various
            Multi-Billion Dollar portfolios globally in specialised industry
            segments of Energy, Healthcare, Hospitality, Development and
            Education. <br /> <br />
            Our leadership team has specialised experience in the field of
            Wealth Management, Financial Management, Leading Philanthropic and
            Investment Management. Everything we do at Sovereign Pacific Capital
            is guided by our five core values: Do the right thing, put our
            clients first, lead with exceptional ideas, commit to diversity and
            inclusion and give back. Our leadership team is dedicated to
            conduction first class business in a first class way. Our SPC
            management team includes a global team of market leading investment
            professionals with experience from top tier financial institutes
            supported by an industry leading roster of operational experts
            across various industries we work in.
          </p>
        </div>
      </div>
      <div className="container mb-5 p-5">
        <div className="text-center">
          <div className="d-flex justify-content-center">
            <img src={SKNLogo} width={"100px"} />
          </div>
          <span
            style={{
              fontWeight: 700,
              textAlign: "center",
              fontSize: "20px",
            }}
          >
            <span style={{ fontSize: "14px" }}>HIS EXCELLENCY</span>
            <br /> UMA SHANKER MISHRA
          </span>
        </div>
        <div className="row featurette d-flex mt-3">
          <div className="col-sm-2">
            <MDBCard>
              <MDBCardImage src={UsMishra2} />
            </MDBCard>
            <div className="text-center mt-2">
            Founder & Chairman
              <div className="mb-3">
                <a href="https://www.linkedin.com/in/usmishra/">
                  <img
                    src="https://cdn-icons-png.flaticon.com/512/174/174857.png"
                    width={"20px"}
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="col-sm-10 m-auto">
            <p
              className="contentFont"
              style={{
                textAlign: "justify",
                fontWeight: "400",
              }}
            >
              H.E Uma Shanker Mishra is a Diplomat, Entrepreneur, and
              Philanthropist, currently serving as the Special Envoy (Diplomat)
              to Asia for the Federation of St. Kitts and Nevis. In this role,
              He plays a crucial part in fostering diplomatic relations and
              representing the interests of St. Kitts and Nevis across Asia. His
              diplomatic responsibilities are complemented by his extensive
              entrepreneurial endeavours in various industries, notably in the
              hospitality sector. <br />
              <br />
              He is also the Founder and Executive Chairman of SPC Bank, a
              private international bank, and the Chairman of Sovereign Pacific
              Capital, an international capital investment and wealth management
              firm. His Career spanning over 25 years, during which he has made
              significant contributions as an Attorney, Solicitor, Investment
              Banker, and Philanthropist. He’s expertise in enhancing strategic
              bank portfolios is well-documented, with notable tenures at
              renowned institutions such as UBS and Mitsubishi Bank. <br />
              <br />
              At SPC Group, he oversees a substantial global asset portfolio
              encompassing investment management, wealth management, research,
              and capital markets. As a key member of the bank’s executive
              management team, he provides independent oversight and guidance
              for managing risk and ensuring compliance, Instrumental in
              developing and implementing comprehensive risk management measures
              across the company. In addition to his financial and diplomatic
              roles, he serves as the Founder of the Global Human Care
              Foundation, an organisation dedicated to charitable initiatives
              across Asia, Africa, and the Caribbean. <br />
              <br />
              He actively participates in several organisations focused on Risk,
              Finance, and Sustainable Governance and Policy. His multifaceted
              career is an exemplification of his leadership, vision, and
              commitment to excellence, while contributions in diplomacy,
              finance, and philanthropy impact across various sectors and
              regions.
            </p>
          </div>
        </div>
      </div>

      <Row className="mb-5 container mx-auto">
        <div className="px-4 pt-2 my-5 border-bottom">
          <h4
            className="display-6 text-center headings"
            style={{ color: "#017129" }}
          >
            Management Team
          </h4>
        </div>
        <div className="col-md col-sm-12">
          <MDBCard className="mx-auto">
            <MDBCardImage
              src={prasad}
              className="cardImage"
              position="top"
              alt="..."
            />
            <MDBCardBody
              style={{ textAlign: "center", background: "#c8edda49" }}
            >
              <MDBCardTitle>
                <h6 style={{ color: "#017129", margin: "-5px" }}>
                  <strong>P Gundumogula</strong>
                </h6>
                <span className="contentFont" style={{ fontWeight: "lighter" }}>
                  Director-Advisor
                </span>
              </MDBCardTitle>
              <div className="mb-2">
                <a href="">
                  <img
                    src="https://cdn-icons-png.flaticon.com/512/174/174857.png"
                    width={"20px"}
                  />
                </a>
              </div>
              <Link to="/our-people/our-team" className="link-team">
                Know More
              </Link>
            </MDBCardBody>
          </MDBCard>
        </div>

        <div className="col-md col-sm-12">
          <MDBCard className="mx-auto">
            <MDBCardImage
              src={Leslie}
              position="top"
              className="cardImage"
              alt="..."
            />
            <MDBCardBody
              style={{ textAlign: "center", background: "#c8edda49" }}
            >
              <MDBCardTitle>
                <h6 style={{ color: "#017129", margin: "-5px" }}>
                  <strong>Leslie Thomas</strong>
                </h6>
                <span className="contentFont" style={{ fontWeight: "lighter" }}>
                  {" "}
                  CEO SPC Group
                </span>
              </MDBCardTitle>
              <div className="mb-2">
                <a href="https://www.linkedin.com/in/leslie-k-thomas-p-eng-5922186/">
                  <img
                    src="https://cdn-icons-png.flaticon.com/512/174/174857.png"
                    width={"20px"}
                  />
                </a>
              </div>
              <Link to="/our-people/our-team" className="link-team">
                Know More
              </Link>
            </MDBCardBody>
          </MDBCard>
        </div>
        <div className="col-md col-sm-12">
          <MDBCard className="mx-auto">
            <MDBCardImage
              src={pradeep}
              position="top"
              className="cardImage"
              alt="..."
            />
            <MDBCardBody
              style={{ textAlign: "center", background: "#c8edda49" }}
            >
              <MDBCardTitle>
                <h6 style={{ color: "#017129", margin: "-5px" }}>
                  <strong>Pradeep Udhas</strong>
                </h6>
                <span className="contentFont" style={{ fontWeight: "lighter" }}>
                  {" "}
                  Director
                </span>
              </MDBCardTitle>
              <div className="mb-2">
                <a href="https://www.linkedin.com/in/pradeepudhas/">
                  <img
                    src="https://cdn-icons-png.flaticon.com/512/174/174857.png"
                    width={"20px"}
                  />
                </a>
              </div>
              <Link to="/our-people/our-team" className="link-team">
                Know More
              </Link>
            </MDBCardBody>
          </MDBCard>
        </div>
        <div className="col-md col-sm-12">
          <MDBCard className="mx-auto">
            <MDBCardImage
              src={analdo}
              position="top"
              className="cardImage"
              alt="..."
            />
            <MDBCardBody
              style={{ textAlign: "center", background: "#c8edda49" }}
            >
              <MDBCardTitle>
                <h6 style={{ color: "#017129", margin: "-5px" }}>
                  <strong>N Analdo Bailey</strong>
                </h6>
                <span className="contentFont" style={{ fontWeight: "lighter" }}>
                  {" "}
                  Director
                </span>
              </MDBCardTitle>
              <div className="mb-2">
                <a href="https://www.linkedin.com/in/n-analdo-bailey-jp-44433b32/">
                  <img
                    src="https://cdn-icons-png.flaticon.com/512/174/174857.png"
                    width={"20px"}
                  />
                </a>
              </div>
              <Link to="/our-people/our-team" className="link-team">
                Know More
              </Link>
            </MDBCardBody>
          </MDBCard>
        </div>
        <div className="col-md col-sm-12">
          <MDBCard className="mx-auto">
            <MDBCardImage
              src={Bora}
              className="cardImage"
              position="top"
              alt="..."
            />
            <MDBCardBody
              style={{ textAlign: "center", background: "#c8edda49" }}
            >
              <MDBCardTitle>
                <h6 style={{ color: "#017129", margin: "-5px" }}>
                  <strong>G Bora</strong>
                </h6>
                <span className="contentFont" style={{ fontWeight: "lighter" }}>
                  Chief Strategist
                </span>
              </MDBCardTitle>
              <div className="mb-2">
                <a href="https://www.linkedin.com/in/gbspcgroup">
                  <img
                    src="https://cdn-icons-png.flaticon.com/512/174/174857.png"
                    width={"20px"}
                  />
                </a>
              </div>
              <Link to="/our-people/our-team" className="link-team">
                Know More
              </Link>
            </MDBCardBody>
          </MDBCard>
        </div>
      </Row>
    </>
  );
};

export default About;
