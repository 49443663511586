import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
const SidebarLink = styled(Link)`
  display: flex;
  color: #1B3C2B;
  font-weight: bold;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  list-style: none;
  height: 60px;
  text-decoration: none;
  font-size: 16px;
  text-align: center;

  &:hover {
    background: #1B3C2B;
    color: #fff;
    border-left: 4px solid #fff;
    cursor: pointer;
  }
`;

const SidebarLabel = styled.span`
  margin: auto;
  text-align: center;
`;

const DropdownLink = styled(Link)`
  background: #1B3C2B;
  height: 60px;
  display: flex;
  align-items: center;
  text-decoration: none;
  text-align: center;
  color: white;
  font-size: 16px;
  // &:hover {
  //   background: #fff;
  //   cursor: pointer;
  //   color: #1B3C2B;
  // }
`;


const SubMenu = ({ item,sidebar,setSideBar }) => {
  const [subnav, setSubnav] = useState(false);
  // console.log(item)
  const showSubnav = () => setSubnav(!subnav);
  return (
    <>
      <SidebarLink to={item.path} onClick={item.subNav ? showSubnav : (e) => {setSideBar(!sidebar); setSubnav(false)}} >
        <div>
          {item.icon}
          <SidebarLabel>{item.title}</SidebarLabel>
        </div>
        <div>
          {item.subNav && subnav
            ? item.iconOpen
            : item.subNav
            ? item.iconClose
            : null}
        </div>
      </SidebarLink>
      {subnav &&
        item.subNav.map((item, index) => {
          return (
            <DropdownLink to={item.path} key={index} >
              <SidebarLabel onClick={() => {setSideBar(!sidebar); setSubnav(false)}} >{item.title}</SidebarLabel>
            </DropdownLink>
          );
        })}

        </>
  );
};

export default SubMenu;