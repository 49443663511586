import React, { useEffect } from 'react'
import investmentHeader from "../../assets/wwoHead.jpg";
import wm1 from '../../assets/wm1.png'
import wm2 from '../../assets/wm2.png'
import wm3 from '../../assets/wm3.png'

import Header from '../../components/Header/Header'

import './WhatWeOffer.css'
import { useLocation } from 'react-router-dom';
const WealthManagement = () => {
  const {pathname} = useLocation();

  useEffect(() => {
    document.documentElement.scrollTo({
      top:0,
      left:0,
      behavior:"auto"
    })
  }, [pathname])
  return (
    <>
    <Header src={investmentHeader} heading="What We Offer" />
    <div className='container mt-5 '>

    <div className="row featurette ">
    <div className="col column ">
    <h4 className=" headings mt-0 mb-2 text-left pt-5">
          Wealth Management
        </h4>
      <p className="contentFont">
      Here at SPC, we have various Wealth Management plans that caters to different clients globally. As wealth increases, new issues and opportunities make wealth planning crucial, and often complex. SPC’s experienced planning professionals work with the clients and our professional advisors help integrate the clients current objectives and long-term goals into their overall plan. We strive to deliver practical, executable strategies and solutions, which typically analyse our client’s current investment portfolio, tax situation, retirement plans and executive compensation arrangements, as well as existing estate, gifting and charitable plans.
      </p>
    </div>
    <div className="col text-center">
    <img src={wm1} height='400px' width='500px' />
    </div>
  </div>
  <div className="row featurette mt-5 ">
  <div className="col-md-5">
    <img src={wm2} height='400px' width='500px' />
    </div>
    <div className="col-md-7 column col p-3">
      <p className="contentFont">
      We remain integral to our relationship team, proactively identifying strategies that may be of interest, based on changes to estate planning and wealth preservation techniques, and providing under wealth management services.<br/> We have tax consulting where we develop sophisticated strategies that helps minimize current and future income, gift, estate and generation-skipping transfer taxes. <br/>We offer specialized assistance with equity compensation and also the sale of a business. We work with our advisors to develop customized strategies designed to minimize wealth transfer taxes, build and preserve wealth and help ensure that more of our clients assets are distributed and used as they wish in the future.
      </p>
    </div>

  </div>
    </div>
    </>
  )
}

export default WealthManagement