import React, { useEffect } from "react";
import Header from "../../components/Header/Header";
import CultureHeader from "../../assets/ourCulture.png";
import media1 from "../../assets/media1.jpg";
import media2 from "../../assets/media2.png";
import PressRelease from "./images/press-release-2.png";
import SPCNews from "./images/spc-news.png";
import SPCNews2 from "./images/spc-news-2.png";
import media3 from "./SPCBankNews.png";
import IndiAsianNews from "./images/indo-asian-news.png";
import Beryllus from "./images/beryllus-capital.png";
import MinistryExternalAffairs from "./images/ministry-external-affairs.png";
import YahooNews from "./images/yahoo-news.png";
import BolsamaniaNews from "./images/bolsamania-news.png";
import { MDBCard, MDBCardImage } from "mdb-react-ui-kit";
import Row from "react-bootstrap/esm/Row";
import { useLocation } from "react-router-dom";
import "./Media.css";
import SKIPA from "./images/SKIPA.png";

const Media = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto",
    });
  }, [pathname]);
  return (
    <>
      <Header src={CultureHeader} heading="Our Media" />
      {/*
    
      <div className="m-4">
        <div className="m-4">
          <h4 style={{ textAlign: "center", color: "#017129" }}>
            {" "}
            <strong>Appointment of US Mishra as a Strategic Advisor</strong>
          </h4>{" "}
          <p
            className="contentFont text-center"
            style={{ textAlign: "left", fontWeight: 400 }}
          >
            US Mishra has been appointed as a strategic advisor to the premier
            of nevis, for investments, Economic diversification and global
            relationships w.e.f October 05, 2023
            <br />
          </p>
        </div>
        <Row className="mt-5 mb-5">
          <div className="col-md col-sm-12">
            <a target={"_blank"}>
              <MDBCard className="appointment-img mx-auto mt-3">
                <MDBCardImage src={news2} position="top" alt="..." />
              </MDBCard>
            </a>
          </div>
          <div className="col-md col-sm-12">
            <div style={{ width: "70%" }} className="mx-auto mt-3">
              <MDBCardImage
                src={news1}
                position="top"
                alt="..."
                height="400px"
                style={{ objectFit: "contain" }}
              />
            </div>
          </div>
        </Row>
      </div>
       */}

      <section id="features" class="features">
        {/*     <div class="container">
          <div class="row gy-4 align-items-stretch justify-content-between features-item ">
            <div
              class="col-lg-6 d-flex align-items-center features-img-bg"
              data-aos="zoom-out"
            >
              <a
                href={news3}
                data-gallery="portfolio-gallery-app"
                class="glightbox preview-link"
              >
                <img
                  src={news3}
                  class="img-fluid"
                  alt=""
                  style={{ objectFit: "contain" }}
                />
              </a>
            </div>
            <div
              class="col-lg-5 d-flex justify-content-center flex-column"
              data-aos="fade-up"
            >
              <div style={{ textAlign: "center" }}>
                <img
                  width={"150px"}
                  src={newsLogo}
                  alt=""
                  style={{ boxShadow: "none" }}
                />
              </div>
              <h3>Appointment of Uma Shanker Mishra as a Special Advisor</h3>
              <p>
                This letter serves as an official appointment for Mr. Uma
                Shanker Mishra, Founder and Executive Chairman of SPC Bank Ltd.,
                to the esteemed position of Special Advisor to the Minister of
                Economic Development and Investment for St. Kitts and Nevis. The
                appointee's demonstrated support and keen interest in the
                Government's Program for economic and social development are
                acknowledged, with confidence expressed in their resourcefulness
                and effectiveness in the new role.
              </p>
              <ul>
                <li>
                  <i class="bi bi-check"></i>{" "}
                  <span>
                    The appointment is effective from October 09, 2023.
                  </span>
                </li>
                <li>
                  <i class="bi bi-check"></i>
                  <span>
                    {" "}
                    The government aims to transform the nation into a modern
                    and sustainable island state by 2040, with a focus on
                    targeted investments for economic transformation and
                    sustainable development.
                  </span>
                </li>
                <li>
                  <i class="bi bi-check"></i>{" "}
                  <span>
                    The letter concludes with best wishes for success in the
                    upcoming assignment and an anticipation of collaborative
                    efforts.
                  </span>
                  .
                </li>
              </ul>
              <a
                href={news3}
                data-gallery="portfolio-gallery-app"
                class="btn btn-get-started align-self-start glightbox preview-link"
              >
                View →
              </a>
            </div>
          </div>
        </div>
        */}
      </section>
      <div className="row mt-4 d-flex justify-content-evenly">
        <div className="m-4 text-center">
          <h4 style={{ textAlign: "center", color: "#017129" }}>
            {" "}
            <strong>Press Releases</strong>
          </h4>{" "}
        </div>
        {/*
        <div className="spc-bank-card p-3 col-md-3 icon-box">
          <div>
            {" "}
            <a
              href={news5}
              data-gallery="portfolio-gallery-app"
              class="glightbox preview-link"
            >
              <img
                src={news5}
                alt=""
                width={"100%"}
                style={{ objectFit: "cover" }}
              />
            </a>
            <br />
            <br />
            <a
              href={news5}
              data-gallery="portfolio-gallery-app"
              class="btn btn-get-started align-self-center glightbox preview-link"
            >
              View →
            </a>
          </div>
        </div>
         */}
        <div className="spc-bank-card p-3 col-md-3 icon-box">
          <div>
            {" "}
            <a
              href={
                "https://drive.google.com/file/d/13LpI9__OLEKdxIDQspBiLMEtJvH2wSG3/view?usp=sharing"
              }
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={PressRelease}
                alt=""
                width={"100%"}
                style={{ objectFit: "cover" }}
              />
            </a>
            <br />
            <br />
            <a
              href={
                "https://drive.google.com/file/d/13LpI9__OLEKdxIDQspBiLMEtJvH2wSG3/view?usp=sharing"
              }
              target="_blank"
              rel="noreferrer"
              class="btn btn-get-started align-self-center"
            >
              View →
            </a>
          </div>
        </div>
      </div>
      <br />
      <br />

      <div className="row mt-4 d-flex justify-content-evenly">
        <div className="m-4 text-center"></div>
        <div className="spc-bank-card p-3 col-md-3 icon-box">
          <div>
            {" "}
            <a
              href={
                "https://www.mea.gov.in/press-releases.htm?dtl/38140/Official_Visit_of_HE_Dr_Denzil_Douglas_Foreign_Minister_of_St_Kitts__Nevis_to_India_August_0409_2024"
              }
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={MinistryExternalAffairs}
                alt=""
                width={"100%"}
                style={{ objectFit: "cover" }}
              />
            </a>
            <h6
              style={{
                textAlign: "justify",
                color: "#017129",
                marginTop: "10px",
              }}
            >
              {" "}
              <strong>
                Official Visit of H.E. Dr. Denzil Douglas, Foreign Minister of
                St. Kitts & Nevis to India
              </strong>
            </h6>{" "}
            <a
              href={
                "https://www.mea.gov.in/press-releases.htm?dtl/38140/Official_Visit_of_HE_Dr_Denzil_Douglas_Foreign_Minister_of_St_Kitts__Nevis_to_India_August_0409_2024"
              }
              target="_blank"
              rel="noreferrer"
              class="btn btn-get-started align-self-center"
            >
              View →
            </a>
          </div>
        </div>
        <div className="spc-bank-card p-3 col-md-3 icon-box">
          <div>
            {" "}
            <a
              href={
                "https://berylluscapital.com/beryllus-capital-and-spc-bank-announce-strategic-collaboration/"
              }
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={Beryllus}
                alt=""
                width={"100%"}
                style={{ objectFit: "cover" }}
              />
            </a>
            <h6
              style={{
                textAlign: "justify",
                color: "#017129",
                marginTop: "10px",
              }}
            >
              {" "}
              <strong>
                Beryllus Capital And SPC Bank Announce Strategic Collaboration
              </strong>
            </h6>{" "}
            <a
              href={
                "https://berylluscapital.com/beryllus-capital-and-spc-bank-announce-strategic-collaboration/"
              }
              target="_blank"
              rel="noreferrer"
              class="btn btn-get-started align-self-center"
            >
              View →
            </a>
          </div>
        </div>
        <div className="spc-bank-card p-3 col-md-3 icon-box">
          <div>
            {" "}
            <a
              href={
                "https://ians.in/pr-wire-detail/sovereign-pacific-capital-and-uma-shanker-mishra-expand-their-reach-with-spc-bank-a-global-financial-institution-spanning-europe-asia-and-the-caribbean-region-15-11-2023"
              }
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={IndiAsianNews}
                alt=""
                width={"100%"}
                style={{ objectFit: "cover" }}
              />
            </a>
            <h6
              style={{
                textAlign: "justify",
                color: "#017129",
                marginTop: "10px",
              }}
            >
              {" "}
              <strong>
                SPC was recently featured on Indo Asian News Service.
              </strong>
            </h6>{" "}
            <a
              href={
                "https://ians.in/pr-wire-detail/sovereign-pacific-capital-and-uma-shanker-mishra-expand-their-reach-with-spc-bank-a-global-financial-institution-spanning-europe-asia-and-the-caribbean-region-15-11-2023"
              }
              target="_blank"
              rel="noreferrer"
              class="btn btn-get-started align-self-center"
            >
              View →
            </a>
          </div>
        </div>
      </div>
      <div className="row mt-4 d-flex justify-content-evenly">
        <div className="m-4 text-center"></div>
        <div className="spc-bank-card p-3 col-md-3 icon-box">
          <div>
            {" "}
            <a
              href={
                "https://finance.yahoo.com/news/sovereign-pacific-capital-uma-shanker-201400929.html?guccounter=1&guce_referrer=aHR0cHM6Ly93d3cuZ29vZ2xlLmNvbS8&guce_referrer_sig=AQAAAHkKExmRRMeKJBZDuOmz_Nu688Y4YYi3wXqePql711rRfOewaPiwNx5ULhD4S9Vkzno5-6w5oxCnUvRA52CyvHBWWuwoI4oksOuaKxnpk0q_gArTvDxf-mnbMIyE_Itcx2tsj2iDya7qgcso53zPxv5m7C0-qprsN3lMA0EHfoX3"
              }
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={YahooNews}
                alt=""
                width={"100%"}
                style={{ objectFit: "cover" }}
              />
            </a>
            <h6
              style={{
                textAlign: "justify",
                color: "#017129",
                marginTop: "10px",
              }}
            >
              {" "}
              <strong>SPC was recently featured on Yahoo Finance</strong>
            </h6>{" "}
            <a
              href={
                "https://finance.yahoo.com/news/sovereign-pacific-capital-uma-shanker-201400929.html?guccounter=1&guce_referrer=aHR0cHM6Ly93d3cuZ29vZ2xlLmNvbS8&guce_referrer_sig=AQAAAHkKExmRRMeKJBZDuOmz_Nu688Y4YYi3wXqePql711rRfOewaPiwNx5ULhD4S9Vkzno5-6w5oxCnUvRA52CyvHBWWuwoI4oksOuaKxnpk0q_gArTvDxf-mnbMIyE_Itcx2tsj2iDya7qgcso53zPxv5m7C0-qprsN3lMA0EHfoX3"
              }
              target="_blank"
              rel="noreferrer"
              class="btn btn-get-started align-self-center"
            >
              View →
            </a>
          </div>
        </div>
        <div className="spc-bank-card p-3 col-md-3 icon-box">
          <div>
            {" "}
            <a
              href={"https://investstkitts.kn/8905-2/"}
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={SKIPA}
                alt=""
                width={"100%"}
                style={{ objectFit: "cover" }}
              />
            </a>
            <h6
              style={{
                textAlign: "justify",
                color: "#017129",
                marginTop: "10px",
              }}
            >
              {" "}
              <strong>
                SPC was recently featured on St. Kitts Investment Promotion
                Agency (SKIPA).
              </strong>
            </h6>{" "}
            <a
              href={"https://investstkitts.kn/8905-2/"}
              target="_blank"
              rel="noreferrer"
              class="btn btn-get-started align-self-center"
            >
              View →
            </a>
          </div>
        </div>
        <div className="spc-bank-card p-3 col-md-3 icon-box">
          <div>
            {" "}
            <a
              href={
                "https://www.bolsamania.com/nota-de-prensa/mercados/sovereign-pacific-capital-y-uma-shanker-mishra-amplian-su-alcance-con-spc-bank-una-institucion-financiera-global-que-abarca-europa-asia-y-la-region-del-caribe--15341872.html"
              }
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={BolsamaniaNews}
                alt=""
                width={"100%"}
                style={{ objectFit: "cover" }}
              />
            </a>
            <h6
              style={{
                textAlign: "justify",
                color: "#017129",
                marginTop: "10px",
              }}
            >
              {" "}
              <strong>SPC was recently featured on Bolsamania - Spanish</strong>
            </h6>{" "}
            <a
              href={
                "https://www.bolsamania.com/nota-de-prensa/mercados/sovereign-pacific-capital-y-uma-shanker-mishra-amplian-su-alcance-con-spc-bank-una-institucion-financiera-global-que-abarca-europa-asia-y-la-region-del-caribe--15341872.html"
              }
              target="_blank"
              rel="noreferrer"
              class="btn btn-get-started align-self-center"
            >
              View →
            </a>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="m-4">
        <div className="m-4">
          <h4 style={{ textAlign: "center", color: "#017129" }}>
            {" "}
            <strong>
              Sovereign Pacific Capital and Uma Shanker Mishra Expand their
              Reach with SPC Bank, a Global Financial Institution Spanning
              Europe, Asia, and the Caribbean Region.
            </strong>
          </h4>{" "}
        </div>
        <Row className="mt-5 mb-5">
          <div className="col-md col-sm-12">
            <a
              target={"_blank"}
              href="https://en.prnasia.com/releases/apac/sovereign-pacific-capital-and-uma-shanker-mishra-expand-their-reach-with-spc-bank-a-global-financial-institution-spanning-europe-asia-and-the-caribbean-region-426967.shtml"
            >
              <MDBCard style={{ width: "70%" }} className="mx-auto mt-3">
                <MDBCardImage
                  src={SPCNews}
                  position="top"
                  alt="..."
                  height="400px"
                  style={{ objectFit: "contain" }}
                />
              </MDBCard>
            </a>
          </div>
          <div className="col-md col-sm-12">
            <MDBCard style={{ width: "70%" }} className="mx-auto mt-3">
              <MDBCardImage
                src={SPCNews2}
                position="top"
                alt="..."
                height="400px"
              />
            </MDBCard>
          </div>
        </Row>
        <div className="text-center">
          <a
            target={"_blank"}
            href="https://en.prnasia.com/releases/apac/sovereign-pacific-capital-and-uma-shanker-mishra-expand-their-reach-with-spc-bank-a-global-financial-institution-spanning-europe-asia-and-the-caribbean-region-426967.shtml"
          >
            <button
              className="p-2 mt-5 mb-5"
              style={{
                borderRadius: "25px",
                border: "none",
                background: "#017129",
                color: "white",
              }}
            >
              know more ➜{" "}
            </button>
          </a>
        </div>
      </div>
      <div className="m-4">
        <div className="m-4">
          <h4 style={{ textAlign: "center", color: "#017129" }}>
            {" "}
            <strong>
              Sovereign Pacific's USD $500M Fund for The Caribbean Region
            </strong>
          </h4>{" "}
          <p
            className="contentFont text-center"
            style={{ textAlign: "left", fontWeight: 400 }}
          >
            Sovereign Pacific Capital, an Asia wealth and asset management
            organization establishes a USD $500M Sovereign Pacific Fund, in
            partnership with Possibility Group, for sustainable investments in
            St. Kitts & Nevis and the Caribbean region.
            <br />
          </p>
        </div>
        <Row className="mt-5 mb-5">
          <div className="col-md col-sm-12">
            <a
              target={"_blank"}
              href="https://www.prnewswire.com/news-releases/sovereign-pacific-s-usd-500m-fund-for-
the-caribbean-region-818121528.html"
            >
              <MDBCard style={{ width: "70%" }} className="mx-auto mt-3">
                <MDBCardImage
                  src={media2}
                  position="top"
                  alt="..."
                  height="400px"
                  style={{ objectFit: "contain" }}
                />
              </MDBCard>
            </a>
          </div>
          <div className="col-md col-sm-12">
            <MDBCard style={{ width: "70%" }} className="mx-auto mt-3">
              <MDBCardImage
                src={media1}
                position="top"
                alt="..."
                height="400px"
              />
            </MDBCard>
          </div>
        </Row>
        <div className="text-center">
          <a
            target={"_blank"}
            href="https://www.prnewswire.com/news-releases/sovereign-pacific-s-usd-500m-fund-for-the-caribbean-region-818121528.html"
          >
            <button
              className="p-2 mt-5 mb-5"
              style={{
                borderRadius: "25px",
                border: "none",
                background: "#017129",
                color: "white",
              }}
            >
              know more ➜{" "}
            </button>
          </a>
        </div>
      </div>

      <div className="m-4">
        <div className="m-4">
          <h4 style={{ textAlign: "center", color: "#017129" }}>
            {" "}
            <strong>
              SPC BANK- a private international bank ( Nevis FSRC approved and
              licensed)
            </strong>
          </h4>{" "}
        </div>
        <Row className="mt-5 mb-5">
          <div className="col-md col-sm-12">
            <a
              target={"_blank"}
              href="https://www.nevisfsrc.com/regulated-entities/#1694013525212-6a3a007b-2cf6"
            >
              <MDBCard style={{ width: "70%" }} className="mx-auto mt-3">
                <MDBCardImage
                  src={media3}
                  position="top"
                  alt="..."
                  height="400px"
                  style={{ objectFit: "contain" }}
                />
              </MDBCard>
            </a>
          </div>
        </Row>
        <div className="text-center">
          <a
            target={"_blank"}
            href="https://www.nevisfsrc.com/regulated-entities/#1694013525212-6a3a007b-2cf6"
          >
            <button
              className="p-2 mt-5 mb-5"
              style={{
                borderRadius: "25px",
                border: "none",
                background: "#017129",
                color: "white",
              }}
            >
              know more ➜{" "}
            </button>
          </a>
        </div>
      </div>
    </>
  );
};

export default Media;
