import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import { SideBarData } from './SideBarData';
import SubMenu from './SubMenu';
import { IconContext } from 'react-icons/lib';
import Logo from '../../assets/logospc.svg'
import ClickAwayListener from 'react-click-away-listener';

const Heading = styled.div`
margin: auto;
margin-left: 2rem;
`
const Nav = styled.div`
  position: sticky;
  top: -16px;
  z-index: 10;
  -webkit-backface-visibility: hidden;
  background: #fff;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: '#017129';
  box-shadow: 0px 10px 15px 10px rgb(0 0 0 / 15%);

`;

const NavIcon = styled(Link)`
  margin-left: 2rem;
  font-size: 2rem;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const SidebarNav = styled.nav`
  box-shadow: 0px 10px 15px 10px rgb(0 0 0 / 15%);
  background:#fff;
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: ${({ sidebar }) => (sidebar ? '0' : '-100%')};
  transition: 350ms;
  z-index: 10;
   overflow-y: scroll;
   &::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}
`;

const SidebarWrap = styled.div`

  width: 100%;
`;

const Sidebar = () => {
  const [sidebar, setSidebar] = useState(false);

  const showSidebar = () => setSidebar(!sidebar);

  return (


      <IconContext.Provider value={{ color: '#017129' }}>

        <Nav>
          <NavIcon to='#'>
            <FaIcons.FaBars onClick={showSidebar} style={{color: '#017129'}} />
          </NavIcon>
          <div className='ml-3 pl-3'>
          <Heading><a href="/"><img src={Logo} height='60px' width='120px'/>
          </a>
          </Heading>
          </div>
          <div className='m-3' style={{color: '#017129'}}>
          Sovereign Pacific Capital
          </div>
        </Nav>
      { sidebar && <ClickAwayListener onClickAway={()=>{sidebar && showSidebar()}}>
        <SidebarNav sidebar={sidebar}>
          <SidebarWrap>
            <NavIcon to='#'>
              <AiIcons.AiOutlineClose onClick={showSidebar} style={{color: '#017129'}}/>
            </NavIcon>
            {SideBarData.map((item, index) => {
              return <SubMenu item={item} key={index} setSideBar={setSidebar} sidebar={sidebar} />;
            })}
          </SidebarWrap>
        </SidebarNav>
      </ClickAwayListener >}

      </IconContext.Provider>
  );
};

export default Sidebar;