import React from 'react'
import './Hero.css'
import globeVideo from '../../assets/video.gif'
import * as videoJson from '../../assets/video.json'
const Hero = () => {

  return (
    <section id="hero" className="hero d-flex mb-5">
    <div className="container">
      <div className="row d-flex  flex-row">
        <div className="animation-hero-sec col-md-6  align-items-center mx-auto my-auto">
          <h1 data-aos="fade-up">Building Relationships
            Before <br /> Building Portfolios</h1>
          <div data-aos="fade-up" data-aos-delay="600">
             <div className="text-center text-lg-start">
              <a href="#about" className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center">
                <span>Get Started</span>
                <i className="bi bi-arrow-right"></i>
              </a>
            </div>
          </div>
        </div>

        <div className="col-md-6 col-sm-12 col-xs-12 p-0 text-center" data-aos="zoom-out" data-aos-delay="200">
         <img src={globeVideo} className="mx-auto" width="100%" height="130%" alt='Loading...' />
       {/*  <video className='align-left' width="150%" height="100%" loop muted autoPlay preload="auto" playsInline>
         <source src={globeVideo} type="video/webm" />
         Your browser does not support the video tag.
       </video> */}

    </div>
      </div>
    </div>

  </section>
  )
}

export default Hero