import React from 'react'
import './Industry.css'
import IndustryImg from '../../assets/Industry1.png'
import {Link} from 'react-router-dom';
const Industry = () => {
  return (
    <section id="advertisers" className="advertisers-service-sec pt-5 pb-5">
    <div className="container">
      <div className="row">
        <div className='text-center'>
          <h2 className="headings" style={{fontSize:"2rem"}}>Our Industry</h2>
        </div>
      </div>
      <div className="row mt-5 mt-md-4 row-cols-1 row-cols-sm-1 row-cols-md-3 justify-content-center">
        <div className="col">
          <img src={IndustryImg} className="valImg"/>
        </div>
        <div className="col">
          <div className="service-card">
            <h3 className="text-center">Health Care</h3>
              <p>Barring the pandemic itself, SPC drives investments in the Healthcare Industries and Innovation in areas of diagnostics, detection and patient specialised care. Providing Infrastructure Investments in the field of Super Specialty Hospitals, Sports Medicines Hospitals and Medical Universities.</p>

              <Link to="/industry/healthcare" className="service-btn">Read More ➜</Link>
          </div>
        </div>
        <div className="col">
          <div className="service-card">

            <h3  className="text-center">Energy</h3>
            <p>SPC aims at Scaling up Green Mobility investment, critical to accelerate the overall global energy transformation and reap its many benefits for the country and various organisations globally, while achieving climate and development targets.
</p>
              <br/> <br />
              <Link to="/industry/energy" className="service-btn">Read More ➜</Link>
          </div>
        </div>
        <div className="col">
          <div className="service-card">
            <h3 className="text-center" >Real Estate</h3>
            <p>SPC invests strategically in high-quality assets, focusing on the outsized growth potential driven by global economic and demographic trends. Our investment principles are guided by patience and discipline for the same.
            </p>
              <br/>
              <Link to="/industry/real-estate" className="service-btn">Read More ➜</Link>
          </div>
        </div>
        <div className="col">
          <div className="service-card">
            <h3 className="text-center" >Hospitality</h3>
            <p >SPC invests across the capital stack in luxury hotels, resorts and related assets including single assets, portfolios and companies, while focusing on major cities and tourist destinations worldwide.</p> <br/><br/>
              <Link to="/industry/hospitality" className="service-btn" >Read More ➜</Link>
          </div>
        </div>
        <div className="col">
          <div className="service-card">
            <h3 className="text-center" >Sustainability and Impact</h3>
            <p>Globally, SPC works very passionately on social impact initiatives through our Global Human Care Foundation that has been making tremendous impacts in the field of Education, Green Initiatives, Healthcare and Women Empowerment.
</p>
                <br />
              <Link to="/industry/sustainability-and-impact" className="service-btn">Read More ➜</Link>
          </div>
        </div>
      </div>
    </div>
  </section>
  )
}

export default Industry