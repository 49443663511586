import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/esm/Container";
import Header from "../../components/Header/Header";
import {
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,
} from "mdb-react-ui-kit";
import professional1 from "../../assets/professional1.png";
import Button from "react-bootstrap/esm/Button";
import headerBlog from "../../assets/headerBlog.jpg";
import TabContent from "../../components/Tab/TabContent";
import { useLocation } from "react-router-dom";
const BlogsMain = () => {
  const [basicActive, setBasicActive] = useState("tab1");
  const { pathname } = useLocation();

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto",
    });
  }, [pathname]);
  const handleBasicClick = (value) => {
    if (value === basicActive) {
      return;
    }

    setBasicActive(value);
  };
  return (
    <>
      <Header src={headerBlog} heading="Blogs" />
      <div container-fluid className="mt-5 mb-5">
        <div>
          <h2
            className="fw-bold mt-5 mb-5 text-center"
            style={{ color: "#017129" }}
          >
            Our Blogs
          </h2>
        </div>
        <div className="m-5">
          <MDBTabs pills className="mb-5 mt-5">
            <MDBTabsItem className="">
              <MDBTabsLink
                onClick={() => handleBasicClick("tab1")}
                active={basicActive === "tab1"}
              >
                Medical and Healthcare
              </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem className="">
              <MDBTabsLink
                onClick={() => handleBasicClick("tab2")}
                active={basicActive === "tab2"}
              >
                Finance
              </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem className="">
              <MDBTabsLink
                onClick={() => handleBasicClick("tab3")}
                active={basicActive === "tab3"}
              >
                Infrastructure Development
              </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem className="">
              <MDBTabsLink
                onClick={() => handleBasicClick("tab4")}
                active={basicActive === "tab4"}
              >
                Green Technology
              </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem className="">
              <MDBTabsLink
                onClick={() => handleBasicClick("tab5")}
                active={basicActive === "tab5"}
              >
                Luxury Tourism and Hospitality
              </MDBTabsLink>
            </MDBTabsItem>
          </MDBTabs>
        </div>
        <MDBTabsContent>
          <MDBTabsPane show={basicActive === "tab1"}>
            <TabContent
              heading="Top 10 Challenges Health Companies Face Today"
              text="Let’s take a look at the top 10 healthcare challenges that companies face today and what effective measures can be taken to resolve those challenges. We live in a time when previous technologies have reached their peak and new technologies are on the verge to make a drastic impact on our lives. It seems Pete"
              href="https://dynastypacificwealth.org/top-10-challenges-healthcare-companies-face-today/"
            />
            <TabContent
              heading="The future of healthcare: Value creation through next-generation business models"
              text="While the consumption of healthcare services will likely continue to grow, it is unclear that industry profit pools will expand over the next three years given the impact of the COVID-19 pandemic. Healthcare players who develop new and innovative business models will create disproportionate value in this environment, even as they face long-term challenges"
              href="https://dynastypacificwealth.org/the-future-of-healthcare-value-creation-through-next-generation-business-models/"
            />
            <TabContent
              heading="An opportuinity to reorder the Health Industry"
              text="Leaders will redesign their organizations for speed, accelerate productivity improvements, reshape their portfolio and innovate new business models, and reallocate constrained resources. he once-in-a-century pandemic thrust the healthcare industry into the teeth of the storm. The combination of accelerating affordability challenges, access issues exacerbated by clinical staff shortages and COVID-19, and limited population-wide progress on outcomes"
              href="https://dynastypacificwealth.org/an-opportunity-to-reorder-the-healthcare-industry/"
            />
          </MDBTabsPane>

          <MDBTabsPane show={basicActive === "tab2"}>
            <TabContent
              heading="Global Investment Banking Market Report 2021 – Opportunities And Strategies, Market Forecast And Trends"
              text="The investment banking market consists of sales (charges on transactions, fees and commission) of investment banking services by entities (organizations, sole traders and partnerships) that undertake capital risk in the process of underwriting securities. This market excludes companies acting as agents and/or brokers between buyers and sellers of securities and commodities. These establishments primarily underwrite, […]"
              href="https://dynastypacificwealth.org/global-investment-banking-market-report-2021-opportunities-and-strategies-market-forecast-and-trends/"
            />
            <TabContent
              heading="Global Investment Banking Market Overview And Prospects￼"
              text="The global investment banking market size is expected to grow from $138.95 billion in 2021 to $155.49 billion in 2022 at a compound annual growth rate (CAGR) of 11.9%. The global investment banking market size is expected to grow to $231.12 billion in 2026 at a CAGR of 10.4%. The investment banking market consists of sales (charges […]"
              href="https://dynastypacificwealth.org/global-investment-banking-market-overview-and-prospects%ef%bf%bc/"
            />
            <TabContent
              heading="Global Investments Market Overview And Prospects"
              text="The global investments market size is expected to grow from $3,192.41 billion in 2021 to $3,617.65 billion in 2022 at a compound annual growth rate (CAGR) of 13.3%. The global investment market size is expected to grow to $5,715.92 billion in 2026 at a CAGR of 12.1%. Many wealth management companies are investing in big data analytics […]"
              href="https://dynastypacificwealth.org/global-investments-market-overview-and-prospects/"
            />
          </MDBTabsPane>
          <MDBTabsPane show={basicActive === "tab3"}>
            <TabContent
              heading="Reviving Hotel Industry In India"
              text="OverviewOver the last decade, India has emerged as a popular tourist destination with >2x international tourists visiting between 2010 and 2019. Foreign tourist arrivals increased from 5.78 million in 2010 to 10.93 million in 2019. The hotel industry is largely dependent on the tourism sector, as it is driven by domestic and foreign tourists arriving"
              href="https://dynastypacificwealth.org/reviving-hotel-industry-in-india/"
            />
            <TabContent
              heading="Smart Cities: An Opportunity for Real Estate Sector"
              text="In line with several other initiatives in making India take a major leap in the race of development at a global level, the building of Smart Cities is definitely a rational decision taken by the Indian government towards its execution.  As a matter of fact, India is the seventh largest country by area and as"
              href="https://dynastypacificwealth.org/smart-cities-an-opportunity-for-real-estate-sector/"
            />
            <TabContent
              heading="Real Estate Sector to cross US $1 Trillion by 2030"
              text="OverviewIn India, the real estate sector is the second-highest employment generator after the agriculture sector. In 2021, the sector was responsible for employing >5.5 crore people, garnering a total of 11% of all employment opportunities in the country. The sector showcased quick recovery in the COVID-era and is projected to grow significantly in the coming"
              href="https://dynastypacificwealth.org/real-estate-sector-to-cross-us-1-trillion-by-2030/"
            />
          </MDBTabsPane>
          <MDBTabsPane show={basicActive === "tab4"}>
            <TabContent
              heading="India is well positioned to become one of the leaders in EVS in the world"
              text="Mahindra is already a force to reckon with in the automobile industry. Keeping pace with the changing trends, the company is making strong headway in the electric vehicle segment. Mr Mahesh Babu, CEO, Mahindra Electric shares his perspective on the current standing and future plans of the company in an exclusive interaction with IBEF. Edited excerpts:"
              href="https://dynastypacificwealth.org/india-is-well-positioned-to-become-one-of-the-leaders-in-evs-in-the-world/"
            />
            <TabContent
              heading="Electric Vehicles Market in India"
              text="IntroductionThe global electric vehicle (EV) market is developing at a rapid pace. According to EV volumes, overall electric vehicle reached a global share of 8.3% (including battery electric vehicles [BEVs] and Plug- in hybrid electric vehicles [PHEVs]) in 2021 from 4.2% in 2020 with 6.75 million vehicles on the road. This is an increase of"
              href="https://dynastypacificwealth.org/electric-vehicles-market-in-india/"
            />
          </MDBTabsPane>
          <MDBTabsPane show={basicActive === "tab5"}>
            <TabContent
              heading="Indian Tourism Industry: An Insight"
              text="During the celebration of 73rd Independence Day, Prime Minister Mr Narendra Modi addressed the nation from the Red Fort and underlined that India ‘has much to offer’ to tourists. The government has been focusing on the tourism industry since 2002 with the launch of campaign Incredible India campaign. India has seen a continuous growth in position […]"
              href="https://dynastypacificwealth.org/indian-tourism-industry-an-insight/"
            />
          </MDBTabsPane>
        </MDBTabsContent>
      </div>
    </>
  );
};

export default BlogsMain;
