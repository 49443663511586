import React from 'react'
import './Cards.css'
const Cards = (props) => {
  return (
    <div class="card mb-3 card text-center mx-auto" style={{width: '80%'}}>
  <div class="row">
    <div class="col">
      <div class="card-body">
        <h5 class=" head">{props.heading}</h5>
        <p class="card-text contentFont">
          {props.text}
        </p>
      </div>
    </div>
  </div>
</div>
  )
}

export default Cards