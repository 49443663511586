import React from "react";
import Img1 from "./images/img-1.jpeg";
import Img2 from "./images/img-2.jpeg";
import Img3 from "./images/img-3.jpeg";

const ArchieveTwo = () => {
  return (
    <section id="" className="main_spc_bank">
      <div className="d-flex align-items-center">
        {" "}
        <h4
          style={{
            color: "green",
            margin: "auto",
            textAlign: "center",
            fontFamily: "sans-serif",
            letterSpacing: "1px",
          }}
        >
          “H.E Uma Shanker Mishra attended His Majesty Brunei king birthday{" "}
          <br />
          alongside the royal family in July 24”
          <br />
          <br />
        </h4>
      </div>
      <div className="container mt-3 mb-3">
        <div className="row no-gutters text-center">
          <div className="image col-xl-4">
            <a
              href={Img3}
              data-gallery="portfolio-gallery-app"
              class="glightbox preview-link"
            >
              <img
                src={Img3}
                alt=""
                width={"90%"}
                style={{
                  borderRadius: "25px",
                  background: "green",
                  padding: "3px",
                }}
              />
            </a>
            <p
              style={{
                fontSize: "12px",
                width: "100%",
                textAlign: "center",
                fontWeight: 600,
              }}
            >
              {" "}
              At a birthday ceremony with His Majesty Hassanal Bolkiah
            </p>
          </div>
          <div className="image col-xl-4">
            <a
              href={Img2}
              data-gallery="portfolio-gallery-app"
              class="glightbox preview-link"
            >
              <img
                className="glightbox preview-link"
                src={Img2}
                alt=""
                width={"90%"}
                style={{
                  borderRadius: "25px",
                  background: "green",
                  padding: "3px",
                }}
              />
            </a>
            <p
              style={{
                fontSize: "12px",
                width: "100%",
                textAlign: "center",
                fontWeight: 600,
              }}
            >
              Along Side His Majesty Hassanal Bolkiah and Crown Prince
              <br /> Al-Muhtadee Billah
            </p>
          </div>

          <div className="image col-xl-4">
            <a
              href={Img1}
              data-gallery="portfolio-gallery-app"
              class="glightbox preview-link"
            >
              <img
                src={Img1}
                alt=""
                width={"90%"}
                style={{
                  borderRadius: "25px",
                  background: "green",
                  padding: "3px",
                }}
              />
            </a>
            <p
              style={{
                fontSize: "12px",
                width: "100%",
                textAlign: "center",
                fontWeight: 600,
              }}
            >
              H.E Uma Shanker Mishra with Brunei Prince Abdul Mateen
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ArchieveTwo;
