import React, { useEffect } from 'react'
import investmentHeader from "../../assets/wwoHead.jpg";

import cc from '../../assets/cc.png'
import cc1 from '../../assets/cc1.png'

import Header from '../../components/Header/Header'
import './WhatWeOffer.css'
import { useLocation } from 'react-router-dom';
const CorporateCounselling = () => {
  const {pathname} = useLocation();

  useEffect(() => {
    document.documentElement.scrollTo({
      top:0,
      left:0,
      behavior:"auto"
    })
  }, [pathname])
  return (
    <>
    <Header className="mb-5" src={investmentHeader} heading="What We Offer" />
    <div className='container mt-5 mb-5'>
    <div className="row featurette ">
    <div className="col column">
    <h4 className=" headings mt-0 mb-2 text-left pt-5">
          Corporate Counselling
        </h4>
      <p className="contentFont">
      SPC helps our clients to build a better business succession in terms of scenarios where companies are tagged sick units by appraising their technology, financial planning, assessing their requirements, and restructuring their capital base. In this scenario our team of financial experts and advisors help them design strategic plans where we work on assessment of reviving prospects and planning various rehabilitation programs through modernization and diversification.
      </p>
    </div>
    <div className="col text-center">
    <img src={cc} height='400px' width='500px' />
    </div>
  </div>
  <div className="row featurette mt-5 ">
  <div className="col-md-5">
    <img src={cc1} height='400px' width='500px' />
    </div>
    <div className="col-md-7 column col p-3">
      <p className="contentFont">
      Under this service delivery, SPC promises to fully understand the clients’ situation and objectives such as finance and business and provide them with tailored solutions for all of their requirements including future growth plans. Our holistic approach to achieve our corporate counseling goals is to ensure that your professional situations are taken into account while devising the right strategy for your assets. Whatever your financial goals may be post retirement, we draw from a range of dedicated resources provided by our specialized teams to show you how a prudent planning and the right solutions can help you achieve them.
      </p>
    </div>

  </div>
    </div>
    </>
  )
}

export default CorporateCounselling