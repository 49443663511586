import React, { useEffect } from "react";
import Header from "../../components/Header/Header";
import OurWorkHeader from "../../assets/ourworkHeader.jpg";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import { MDBCard, MDBCardImage } from "mdb-react-ui-kit";
import Img2 from "./images/2.png";
import Img3 from "./images/3.png";
import Img4 from "./images/4.png";
import Img5 from "./images/5.png";
import Img6 from "./images/6.png";
import Img7 from "./images/7.png";
import Img8 from "./images/8.png";
import Img9 from "./images/9.png";
import Img10 from "./images/10.png";
import Img11 from "./images/11.png";

import { useLocation } from "react-router-dom";

const Upcoming = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto",
    });
  }, [pathname]);
  return (
    <>
      <Header heading="Upcoming Projects" src={OurWorkHeader} />
      <div className="container-fluid mt-5 mb-5">
        <Row>
          <div className="col-md col-sm-12 mt-3">
            <MDBCard style={{ width: "600px" }} className="mx-auto">
              <MDBCardImage src={Img2} position="top" />
            </MDBCard>
          </div>
          <div className="col-md col-sm-12 mt-3">
            <MDBCard style={{ width: "600px" }} className="mx-auto">
              <MDBCardImage src={Img3} position="top" />
            </MDBCard>
          </div>
          <div className="col-md col-sm-12 mt-3">
            <MDBCard style={{ width: "600px" }} className="mx-auto">
              <MDBCardImage src={Img4} position="top" />
            </MDBCard>
          </div>
          <div className="col-md col-sm-12 mt-3">
            <MDBCard style={{ width: "600px" }} className="mx-auto">
              <MDBCardImage src={Img5} position="top" />
            </MDBCard>
          </div>
          <div className="col-md col-sm-12 mt-3">
            <MDBCard style={{ width: "600px" }} className="mx-auto">
              <MDBCardImage src={Img6} position="top" />
            </MDBCard>
          </div>
          <div className="col-md col-sm-12 mt-3">
            <MDBCard style={{ width: "600px" }} className="mx-auto">
              <MDBCardImage src={Img7} position="top" />
            </MDBCard>
          </div>
          <div className="col-md col-sm-12 mt-3">
            <MDBCard style={{ width: "600px" }} className="mx-auto">
              <MDBCardImage src={Img8} position="top" />
            </MDBCard>
          </div>
          <div className="col-md col-sm-12 mt-3">
            <MDBCard style={{ width: "600px" }} className="mx-auto">
              <MDBCardImage src={Img9} position="top" />
            </MDBCard>
          </div>
          <div className="col-md col-sm-12 mt-3">
            <MDBCard style={{ width: "600px" }} className="mx-auto">
              <MDBCardImage src={Img10} position="top" />
            </MDBCard>
          </div>
          <div className="col-md col-sm-12 mt-3">
            <MDBCard style={{ width: "600px" }} className="mx-auto">
              <MDBCardImage src={Img11} position="top" />
            </MDBCard>
          </div>
        </Row>
      </div>
    </>
  );
};

export default Upcoming;
