import React, { useEffect, useState } from "react";
import {
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,
} from "mdb-react-ui-kit";
import Header from "../../components/Header/Header";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import energy1 from "../../assets/iwu.png";
import investmentheader from "../../assets/wwoHead2.png";

import iwuimg1 from "../../assets/iwuimg1.jpg";
import iwuimg2 from "../../assets/iwuimg2.jpg";
import iwuimg3 from "../../assets/iwuimg3.jpg";
import iwuimg4 from "../../assets/iwuimg4.jpg";
import Button from "react-bootstrap/esm/Button";
import Modal from "../../components/Modal/Modal";
import { Link, useLocation } from "react-router-dom";

const InvestWithUs = () => {
  const [basicActive, setBasicActive] = useState("tab1");
  const { pathname } = useLocation();

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto",
    });
  }, [pathname]);
  const handleBasicClick = (value) => {
    if (value === basicActive) {
      return;
    }

    setBasicActive(value);
  };
  return (
    <>
      <Header src={investmentheader} heading="Investments" />

      <div className="container">
        <Row className="mt-5">
          <Col className="m-auto">
            <div className="col-lg-12">
              <h2 className="headings mt-4 mb-4 text-left">Partner With Us</h2>
              <p className="contentFont">
                SPC realizes your vision for the future with a multigenerational
                wealth plan that reflects your priorities and preserves your
                legacy. Our investment plans are solutions centred around you
                and your financial growth with every investment plan you create
                with us.
              </p>
            </div>
          </Col>
          <Col>
            <img src={energy1} width="100%" height="300px" />
          </Col>
        </Row>
        <MDBTabs pills className="mb-3 mt-5">
          <MDBTabsItem>
            <MDBTabsLink
              className=""
              onClick={() => handleBasicClick("tab1")}
              active={basicActive === "tab1"}
            >
              Healthcare
            </MDBTabsLink>
          </MDBTabsItem>
          <MDBTabsItem>
            <MDBTabsLink
              className="btn-switch"
              onClick={() => handleBasicClick("tab2")}
              active={basicActive === "tab2"}
            >
              Hospitality
            </MDBTabsLink>
          </MDBTabsItem>
          <MDBTabsItem>
            <MDBTabsLink
              className="btn-switch"
              onClick={() => handleBasicClick("tab3")}
              active={basicActive === "tab3"}
            >
              Real Estate
            </MDBTabsLink>
          </MDBTabsItem>
        </MDBTabs>

        <MDBTabsContent>
          <MDBTabsPane show={basicActive === "tab1"}>
            <div className="mt-5 mb-5">
              <div className="row featurette column-full m-auto">
                <div className="col-md-7 column align-middle  ml-3">
                  <h3 className="headings mb-3 mt-3">Medical Institute</h3>
                  <p className="contentFont">
                    As part of our Medical Healthcare Investment Program, SPC is
                    looking to invest in International Medical University in
                    India. The plan is to integrate modern medical practices and
                    technology with sustainable medical interventions and build
                    a series of sustainable development programs all around the
                    country. We plan to achieve the vision of this collaboration
                    of the two elements by pursuing excellence in translating
                    research work and through the practice of The Ancient
                    Ayurveda, Quality Healthcare, Collaborating and Partnering
                    with international medical universities and Institutes to
                    build the foundational and integral element for the project.
                  </p>
                  <div className="d-flex justify-content-center mb-3">
                    <Modal title="Partner With us" text="Partner With Us" />
                  </div>
                </div>
                <div className="col-md-5">
                  <img src={iwuimg1} height="400px" width="500px" />
                </div>
              </div>
              <div className="row featurette column-full mt-5">
                <div className="col-md-5 m-auto">
                  <img src={iwuimg2} height="400px" width="500px" />
                </div>
                <div className="col-md-7 column align-middle">
                  <h3 className="headings text-left mb-3 mt-3">
                    Multi - Speciality Hospital
                  </h3>
                  <p className="contentFont">
                    A multi-million dollar 400 bedded super – speciality hospital
                    is part of the SPC’s Medical Healthcare Investment Program
                    in India. The plan for this multi-speciality hospital is to
                    have a team of top medical professionals of both resident
                    and visiting faculty members who will be supported by the
                    latest cutting edge medical technology, offering health
                    services in the areas of Oncology Care and Radiotherapy,
                    Cardiac Sciences, Neurosciences, Orthopaedics and Join
                    Replacement, Critical Care, Minimal Invasive Surgery,
                    Urology, Nephrology, Gastroenterology, Endocrinology,
                    Gynaecology, Paediatrics and Neonatal Care, Critical Care
                    (CCU, ICU, NICU, SICU) and Internal Medicine, Trauma
                    Services, Interventional Radiology and Preventive Medicines.
                  </p>
                  <div className="d-flex justify-content-center mb-3">
                    <Modal title="Partner With us" text="Partner With Us" />
                  </div>
                </div>
              </div>
            </div>
          </MDBTabsPane>
          <MDBTabsPane show={basicActive === "tab2"}>
            <div className="mt-5 mb-5">
              <div className="row featurette column-full">
                <div className="col-md-7 column align-middlem ">
                  <h3 className=" headings text-left mb-3 mt-3">
                    Luxury Hotels & Resorts
                  </h3>
                  <p className="contentFont">
                    One of our core investments is in the Hospitality Sector,
                    our story of success in the hospitality sector globally has
                    made us want to expand our investment in this sector. With a plan to build on not less than 100 luxurious
                    rooms for each of our projects, we plan to build a chain of
                    luxury hotels and resorts around the country’s most
                    beautiful landscapes. Blending the rich culture and heritage
                    along with delivering carefully crafted world class services
                    for all our guests, our aim is to build luxury landmarks for
                    the hospitality sector in the country.
                  </p>
                  <div className="d-flex justify-content-center mb-3">
                    <Modal title="Partner With us" text="Partner With Us" />
                  </div>
                </div>
                <div className="col-md-5">
                  <img src={iwuimg3} height="400px" width="500px" />
                </div>
              </div>
            </div>
          </MDBTabsPane>
          <MDBTabsPane show={basicActive === "tab3"}>
            <div className="mt-5 mb-5">
              <div className="row featurette column-full">
                <div className="col-md-7 column align-middlem">
                  <h3 className="mb-0 headings text-left mb-3 mt-3">
                    Cockleshell Bay, St. Kitts
                  </h3>
                  <p className="contentFont">
                    SPC is set to invest in a real estate project in the
                    beautiful part of Cockleshell Bay in St Kitts Island in the
                    Caribbean. We have already invested{" "}
                    <Link to="/about-us/media">millions</Link> in this project.
                    This is to build for future residents looking for a more
                    relaxed getaway. Residents and guests coming to the property
                    can look forward to a place that is surrounded by
                    picturesque landscapes, fine white sandy beaches and also
                    enjoy the property’s exclusive services and amenities. The
                    biophilic property’s design will be to marry the natural
                    surroundings into each living space and create a wide space
                    of breathable private spaces for residents looking for a
                    purely nature emphasized escape of their daily life.
                  </p>
                  <div className="d-flex justify-content-center mb-3">
                    <Modal title="Partner With us" text="Partner With Us" />
                  </div>
                </div>
                <div className="col-md-5">
                  <img src={iwuimg4} height="400px" width="500px" />
                </div>
              </div>
            </div>
          </MDBTabsPane>
        </MDBTabsContent>
      </div>
    </>
  );
};

export default InvestWithUs;
