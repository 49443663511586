import React, { useEffect } from "react";
import Header from "../../components/Header/Header";
import img from "../../assets/ourCulture.png";
import UsMishra from "../../assets/usm.png";
import Bora from "../../assets/bora.svg";
import Prasad from "../../assets/prasad.svg";
import Pradeep from "../../assets/pradeep.svg";
import Leslie from "../../assets/leslie.png";
import Analdo from "../../assets/analdo.svg";
import { useLocation } from "react-router-dom";
import "./AboutUs.css";
const DetailAboutTeam = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto",
    });
  }, [pathname]);
  return (
    <>
      <Header heading="Our Team" src={img} />
      <div className=" main__div m-4">
        {/*   <div className="row border-bottom mb-5 container mx-auto">
          <div className="col-md-8 headings m-auto order-md-first order-small-last order-last">
            <p className="contentFont" style={{ fontWeight: "lighter" }}>
              His Excellency Uma Shanker Mishra has been commissioned as a
              special envoy (Diplomat) to Asia by the government of St. Kitts
              and Nevis. He serves as the Chairman of the SPC Group and the
              Executive Chairman of SPC Bank. He is an attorney, solicitor,
              philanthropist, and investment banker. <br />
              He is the Chairman of Sovereign Pacific Capital, a privately-owned
              international capital investment and wealth management company.
              Additionally, he is the Founder of the Global Human Care
              Foundation, a Canadian organization dedicated to charitable
              projects in Asia, Africa, and the Caribbean region. <br /> His
              career began as an attorney with the Supreme Court of India,
              followed by roles as a special advisor to the Government of India,
              the World Bank Organization, and the International Finance
              Corporation (IFC). He is also recognized for his transformative
              contributions in various directional roles at international banks
              and financial institutions, where he has accumulated over 25 years
              of global experience. Currently, he manages a large global asset
              portfolio under SPC, encompassing investment management, wealth
              management, research, and capital markets.
            </p>
          </div>
          <div className="col-md-4 m-auto text-center order-md-last order-small-first order-first">
            <img src={UsMishra} alt="" width="300px" height="300px" />
            <br /> <br />
            <div>
              <h5
                className="headings text-center"
                style={{ fontSize: "1.2rem" }}
              >
                H. E. Uma Shanker Mishra
              </h5>
            </div>
            <p
              className="contetnFont text-center"
              style={{ color: "#017129", marginTop: "-5px" }}
            >
              Founder - Chairman
            </p>
            <div className="mb-3">
              <a href="https://www.linkedin.com/in/usmishra/">
                <img
                  src="https://cdn-icons-png.flaticon.com/512/174/174857.png"
                  width={"20px"}
                />
              </a>
            </div>
          </div>
        </div>
        */}
        <div className="row border-bottom mb-5 container mx-auto">
          <div className="col-md-8 headings m-auto order-md-first order-small-last order-last">
            <p className="contentFont" style={{ fontWeight: "lighter" }}>
              Prasad is a highly accomplished serial entrepreneur and
              experienced technologist, with a proven track record of building
              successful businesses. As the Founder, Chairman, and CEO of
              Mondee, a travel technology Unicorn and NASDAQ-listed company,
              Prasad has led the company from bootstrap to a $1B valuation.
              Under his leadership, Mondee has emerged as a rapid-growth travel
              technology and marketplace with a portfolio of globally recognized
              brands. Prior to Mondee, Prasad founded and successfully exited
              Metaminds, LogixCube, and POD Technologies, where he created
              highly scalable logistics systems, Artificial & Business
              Intelligence based solutions deployed at Lowe's, Mercedes Benz,
              General Motors, Home Depot, and other global companies.
              <br />
              As a Director at SPC, Prasad will bring his extensive experience
              in entrepreneurship and technology to help drive the company's
              growth and success. We are confident that his vision and
              leadership will be instrumental in shaping SPC's future.{" "}
            </p>
          </div>
          <div className="col-md-4 m-auto text-center order-md-last order-small-first order-first">
            <img src={Prasad} alt="" width="300px" height="300px" />
            <br /> <br />
            <div>
              <h5
                className="headings text-center"
                style={{ fontSize: "1.2rem" }}
              >
                Prasad Gundumogula
              </h5>
            </div>
            <p
              className="contetnFont text-center"
              style={{ color: "#017129", marginTop: "-5px" }}
            >
              Director
            </p>
            <div className="mb-2">
              <a href="">
                <img
                  src="https://cdn-icons-png.flaticon.com/512/174/174857.png"
                  width={"20px"}
                />
              </a>
            </div>
          </div>
        </div>

        <div className="row border-bottom mb-5 container mx-auto">
          <div className="col-md-8 headings pt-3 order-md-first order-small-last order-last">
            <p className="contentFont" style={{ fontWeight: "lighter" }}>
              Leslie Thomas is the CEO of SPC for North America and Caribbean
              Islands. A Caribbean Canadian, born in the Federation of St. Kitts
              & Nevis he attended the University of Toronto. He is a
              professional engineer and entrepreneur, with over 30 years of
              international experience in business development, ownership,
              finance, asset management, acquisitions, private equity, venture
              capital and wealth management experience. He is also Founder and
              CEO of Possibility Capital, a leading value creation company in
              Asia, Caribbean and North America. The firm creates, develops, and
              manages sustainable business for stakeholders based on a life
              cycle that is engineered, performance driven and partnership. He
              is the Director of Global Human Care Foundation for the Caribbean
              Islands, where in the past, the organization has worked on
              projects for sustainable livelihood, sustainable energy and clean
              drinking water for communities in the Caribbean Islands. As the
              CEO of Suncastle Resorts in Saint Kitts and Nevis, his expertise
              in land development and implementing advanced environmental and
              sustainable technologies to reduce Resorts and Commercial
              buildings capital cost has helped minimize operational cost and
              increase shareholder value.{" "}
            </p>
          </div>
          <div className="col-md-4 m-auto text-center order-md-last order-small-first order-first">
            <img src={Leslie} alt="" width="300px" height="300px" />
            <br />
            <br />
            <div>
              <h5
                className="headings text-center"
                style={{ fontSize: "1.1rem" }}
              >
                Leslie Thomas
              </h5>
            </div>
            <p
              className="lead text-center"
              style={{ color: "#017129", marginTop: "-5px" }}
            >
              <strong>
                CEO
                <br /> SPC Group
                <br />
              </strong>
            </p>
            <div className="mb-2">
              <a href="https://www.linkedin.com/in/leslie-k-thomas-p-eng-5922186/">
                <img
                  src="https://cdn-icons-png.flaticon.com/512/174/174857.png"
                  width={"20px"}
                />
              </a>
            </div>
          </div>
        </div>
        <div className="row border-bottom mb-5 container mx-auto">
          <div className="col-md-8 headings m-auto order-md-first order-small-last order-last">
            <p className="contentFont" style={{ fontWeight: "lighter" }}>
              Mr. Pradeep Udhas, a global business executive leader with over 30
              years of industry experience, is set to join SPC Group as a
              director. He is currently a Senior Partner at KPMG in India,
              advising the Chairman on firm matters and leading the largest and
              most strategic national and global accounts. Pradeep is highly
              focused on enhancing KPMG in India's market presence and client
              engagement, making him uniquely qualified to serve clients
              pursuing innovative global strategies. He has an extensive
              background in global business, management consulting, technology,
              private equity, and quality systems. He was previously the
              Managing Director at KPMG in the US, responsible for major
              alliances and global/US relationships. Before that, he was a
              founding partner of KPMG in India, serving as the Chairman of
              India Global and Head of Markets. He also served as a Managing
              Director at Greater Pacific Capital, an alternative investment
              platform focusing on China and India. Pradeep founded and
              subsequently sold e2e Technologies, which offered solution
              architecture and program management services to clients like
              Verizon and PCCW (Hong Kong), among others. He started his career
              at KPMG in Short Hills, New Jersey, in 1993. Before that, he held
              various technology development and marketing roles with IBM in the
              US and started its operations in India, as one of the pioneers of
              the 'off-shore' paradigm.
              <br />
              He holds an MBA with a concentration in Management Information
              Systems from Union College, New York. He is currently a member of
              the National Board of IACC (Indo-American Chamber of Commerce),
              the Founder's Circle of Avasara Leadership Academy, and the global
              board of TiE. He also serves on the advisory board of St. Xavier's
              college, Mumbai. Previously, he served on the executive council of
              NASSCOM, an Indian IT industry think-tank.
              <br />
              He is an accomplished leader and professional, with a wealth of
              knowledge and experience in global business, management
              consulting, technology, and private equity. His appointment to SPC
              Group as a director is a significant addition to the team and will
              undoubtedly bring new perspectives and expertise to the
              organisation.
            </p>
          </div>
          <div className="col-md-4 m-auto text-center order-md-last order-small-first order-first">
            <img src={Pradeep} alt="" width="250px" height="300px" />
            <br /> <br />
            <div>
              <h5
                className="headings text-center"
                style={{ fontSize: "1.2rem" }}
              >
                Pradeep Udhas
              </h5>
            </div>
            <p
              className="contetnFont text-center"
              style={{ color: "#017129", marginTop: "-5px" }}
            >
              Director
            </p>
            <div className="mb-2">
              <a href="https://www.linkedin.com/in/pradeepudhas/">
                <img
                  src="https://cdn-icons-png.flaticon.com/512/174/174857.png"
                  width={"20px"}
                />
              </a>
            </div>
          </div>
        </div>

        <div className="row border-bottom mb-5 container mx-auto">
          <div className="col-md-8 headings pt-3 order-md-first order-small-last order-last">
            <p className="contentFont" style={{ fontWeight: "lighter" }}>
              Mr. N. Analdo Bailey, an accomplished Director at SPC Group with
              over 50 years of experience in commercial banking, education, and
              human and organisational development. He began his career at the
              St Kitts Nevis Anguilla National Bank Limited, where he worked his
              way up from a teller position to becoming the Managing Director,
              having progressed through all departments and branches.
              <br />
              As the Chief Executive Officer of the Eastern Caribbean Institute
              of Banking and Financial Services, He directed efforts towards
              developing and implementing training and professional development
              initiatives to enhance the competence and productivity of
              individuals employed in the field of banking and finance. He is a
              seasoned leader in management, mentoring, visioning and planning,
              personal development and behaviour change,
              motivational/inspirational interventions, training facilitation,
              organisational development, and strategy.
              <br />
              He holds several degrees and certifications, including an MBA and
              Executive Diploma in Management from the University of the West
              Indies (UWI), a Doctor of Divinity Degree from Christ Life
              Seminary in the USA, and an Associate Degree in Theology from
              Grace Bible Institute in the USA. He also holds several
              certificates in leadership and project planning, including the
              Certified Chartered Director (Canada) and Audit Committee
              Certified.
              <br />
              Throughout his career, He has held various board memberships and
              affiliations, including the St. Kitts and Nevis Board of Health,
              Frigate Bay Beach Hotel Ltd, the UWI Extra Mural Centre Council,
              and the Caribbean Association of Banking and Finance Institutes
              (CABFI). Currently, he serves on the Board of Directors for the
              Eastern Caribbean Institute of Banking, the Paradise Development
              Co. Ltd, and the CABFI.
            </p>
          </div>
          <div className="col-md-4 m-auto text-center order-md-last order-small-first order-first">
            <img src={Analdo} alt="" width="250px" height="300px" />
            <br />
            <br />
            <div>
              <h5
                className="headings text-center"
                style={{ fontSize: "1.1rem" }}
              >
                N. Analdo Bailey
              </h5>
            </div>
            <p
              className="lead text-center"
              style={{ color: "#017129", marginTop: "-5px" }}
            >
              <strong> Director</strong>
            </p>
            <div className="mb-2">
              <a href="https://www.linkedin.com/in/n-analdo-bailey-jp-44433b32/">
                <img
                  src="https://cdn-icons-png.flaticon.com/512/174/174857.png"
                  width={"20px"}
                />
              </a>
            </div>
          </div>
        </div>
        <div className="row border-bottom mb-5 container mx-auto">
          <div className="col-md-8 headings pt-3 order-md-first order-small-last order-last">
            <p className="contentFont" style={{ fontWeight: "lighter" }}>
              G Bora is responsible for driving strategic initiatives and policy
              development that align with the corporate vision for growth and
              excellence. His role and responsibilities at SPC encompases the
              following;
              <br />
              - Serve as the primary key advisor to Chairman and BoD on various
              opportunities, providing expert insights to guide executive
              decision-making.
              <br />
              - Foster relationships with key stakeholders, UHNI and HNI clients
              and investors, to enhance the organisation's market position and
              reputation.
              <br />
              - Conduct in-depth market analysis and risk assessments to
              identify emerging opportunities and mitigate potential threats.
              <br />- Develop and implement comprehensive investment strategies
              to optimize portfolio performance across global markets.
            </p>
          </div>
          <div className="col-md-4 m-auto text-center order-md-last order-small-first order-first">
            <img src={Bora} alt="" width="250px" height="300px" />
            <br />
            <br />
            <div>
              <h5
                className="headings text-center"
                style={{ fontSize: "1.1rem" }}
              >
                G Bora
              </h5>
            </div>
            <p
              className="lead text-center"
              style={{ color: "#017129", marginTop: "-5px" }}
            >
              <strong>Chief Strategist</strong>
            </p>
            <div className="mb-2">
              <a href="https://www.linkedin.com/in/gbspcgroup">
                <img
                  src="https://cdn-icons-png.flaticon.com/512/174/174857.png"
                  width={"20px"}
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DetailAboutTeam;
