import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import "../WhatWeAre/WhatWeOffer.css";
import {
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,
} from "mdb-react-ui-kit";
import hosp1 from "../../assets/Hosp (1).png";
import hosp2 from "../../assets/Hosp (2).png";

import wInP from "../../assets/ourworkHeader.jpg";
import { useLocation } from "react-router-dom";
const WorkInProgressHospital = () => {
  const [basicActive, setBasicActive] = useState("tab1");
  const { pathname } = useLocation();

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto",
    });
  }, [pathname]);
  const handleBasicClick = (value) => {
    if (value === basicActive) {
      return;
    }

    setBasicActive(value);
  };
  return (
    <>
      <Header src={wInP} heading="Projects in Progress" />

      <div className="container">
      <div className="mt-5 mb-5">
      <div className="row featurette">
      <div className="col-md-7 m-auto">
      <h2 className=" headings fw-bold mt-1">
        Hospitality
      </h2>
              <p className="contentFont">
                SPC is targeting hospitality assets in key gateway cities of
                Guwahati, Shillong, Gangtok, Agartala and Imphal in Northeast
                India that benefit from a diverse range of demand drivers
                including leisure, medical and business tourism. We seek to
                deploy the team’s significant global real estate private equity
                experience towards identifying and unlocking attractive
                locations and sometimes distressed hospitality real estate
                investment opportunities, most of which require significant
                financial management expertise in order to fully maximize its
                potential. Through active and intensive asset management, our
                competitive edge in hotel operational expertise allows us to
                drive value to the hotel financial management and hence deliver
                superior returns on our investments.
              </p>
            </div>
            <div className="col-md-5 text-center">
              <img src={hosp1} height="400px" width="530px" />
            </div>
          </div>
          <div className="row featurette mt-5">
            <div className="col-md-7 order-md-2 m-auto">
              <p className="contentFont">
                This initiative of developing luxury resorts and hotels in the
                Northeast will bring in international guests who can indulge in
                the rich culture and also experience the natural way of healing
                and explore the various wellness programs that are exclusive
                from the region which, have been successfully proven to be the
                best alternative natural treatments. SPC’s vision in building up
                luxury hospitality brands in various locations in the Northeast
                is driven by a concept of bringing in the rich local culture of
                the region integrated with the traditional therapeutic health
                and wellness practice of the indigenous people to the global
                clients.
              </p>
            </div>
            <div className="col-md-5 order-md-1 text-center">
              <img src={hosp2} height="400px" width="500px" />
            </div>
          </div>
        </div>
        <div>
          <h2 className="fw-bold mt-5 mb-5 text-center head">
            The Vision for the Hospitality Initiative in the Northeast part of
            India is to Bring
          </h2>
        </div>
        <div className="d-flex">
          <MDBTabs pills className="mx-auto">
            <MDBTabsItem className="">
              <MDBTabsLink
                onClick={() => handleBasicClick("tab1")}
                active={basicActive === "tab1"}
              >
                Health and Wellness
              </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem className="">
              <MDBTabsLink
                onClick={() => handleBasicClick("tab2")}
                active={basicActive === "tab2"}
              >
                Rich Cultural Driven Properties
              </MDBTabsLink>
            </MDBTabsItem>
          </MDBTabs>
        </div>
        <MDBTabsContent>
          <MDBTabsPane show={basicActive === "tab1"}>
            <div className="row mb-2 mt-5">
              <div className="col-md-6 mx-auto" style={{ width: "90%" }}>
                <div className="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                  <div className="col p-4 d-flex flex-column position-static">
                    <h3
                      className="mb-0  text-center mb-3 headings"
                      style={{ color: "#017129" }}
                    >
                      <strong>Health and Wellness</strong>
                    </h3>
                    <p
                      className=" contentFont"
                      style={{ fontWeight: "inherit" }}
                    >
                      SPC aims at bringing in the traditional wellness lifestyle
                      that is indigenous and unique to the region and has been
                      proven in time to be one of the best left to be
                      discovered. From natural treatments to ancient ayurvedic
                      treatments, our aim is to provide the most exquisite
                      experience and attract guests from all around the world to
                      these regions' virgin unexplored destinations.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </MDBTabsPane>

          <MDBTabsPane show={basicActive === "tab2"}>
            <div className="row mb-2 mt-5">
              <div className="col-md-6 mx-auto" style={{ width: "90%" }}>
                <div className="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                  <div className="col p-4 d-flex flex-column position-static">
                    <h3
                      className="mb-0 headings text-center mb-3"
                      style={{ color: "#017129" }}
                    >
                      Rich Cultural Driven Properties
                    </h3>
                    <p
                      className=" contentFont"
                      style={{ fontWeight: "inherit" }}
                    >
                      Responding to those seeking luxury, peace, seclusion and
                      appreciation to rich cultural experiences of beauty which
                      is the intrinsic part of all of SPC’s hospitality
                      investment programs in the Northeast. The brand’s journey
                      will be inspired by a singular, authentic original intent:
                      To provide a level of exclusive services and a luxurious
                      experience that is peerless in every way.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </MDBTabsPane>
        </MDBTabsContent>
      </div>
    </>
  );
};

export default WorkInProgressHospital;
