import React from "react";
import styled from "styled-components";
import chairmanMes from "../../assets/charimanMes.jpeg";
const Message2023 = () => {
  const Playfair = styled.p`
    @font-face {
      font-family: Playfair;
      src: url("../../Fonts/PlayFair.ttf");
    }
    font-family: Playfair;
  `;
  const Box1 = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
  `;
  const Box2 = styled.div`
    width: 100%;
    background-color: #017129;
  `;

  const BoxText = styled.div`
    color: white;
  `;

  return (
    <section id="" className="mb-5 p-0">
      <div className="container" data-aos="fade-up">
        <div className="row mb-4">
          <div className="col-lg-3 col-md-6 col-sm-8 col-xs-6">
            <Box2 className="p-3" style={{ width: "100%" }}>
              <BoxText className="">
                <h6>From the Chairman's Desk</h6>
              </BoxText>
            </Box2>
          </div>
        </div>
        <div className="row">
          <Box1 className="col-lg-3 col-md-6 col-sm-8 col-xs-6">
            <img src={chairmanMes} loading="lazy" />
          </Box1>
          <div className="col-lg-9 col-md-6 col-sm-12 col-xs-12">
            <Playfair
              style={{
                textAlign: "justify",
              }}
            >
              Dear esteemed stakeholders,
              <br />
              I am humbled to share with you some extraordinary developments
              within Sovereign Pacific Capital (SPC) Group. In my role as
              Chairman of SPC Group, I want to express my profound gratitude for
              all our well-wishers and clients’ steadfast support and continuing
              trust in SPC Group.
              <br />
              <br />
              Through dedicated efforts from our exceptional executive team and
              partners, I am pleased to announce a significant milestone in our
              journey. Sovereign Pacific Capital Group has successfully acquired
              the Full Service International operations license for SPC Bank, a
              private international bank, effective from October 30th, 2023.
              This achievement marks a pivotal step in our commitment to
              delivering convenient, customer-oriented, swift, and reliable
              global banking services.
              <br />
              This significant milestone serves as a cornerstone of our
              strategic vision to expand our global presence and elevate our
              capacity to serve clients and stakeholders. We are resolute in
              shaping a future characterised by innovation, dependability, and
              exceptional service and I am deeply grateful for your enduring
              support and continued confidence in our organisation. Your trust
              has been instrumental in our success.
              <br />
              <br />
              SPC Bank, a private international bank, will serve our clients
              through a user-friendly and secure digital platform, exclusively
              designed by Tata Consultancy Services (TCS), The global leader in
              reliable technologies and consumer financial protection services.
              This initiative ensures seamless access to our services and
              prioritising transaction security for our valued clients
              worldwide. In addition, I am also sincerely honoured to be
              appointed to serve as a Special Advisor to Dr. Denzil Douglas,
              Minister of Economic Development and Investment in the Federation
              of St. Kitts and Nevis, effective 9th October 23, This role
              emphasises our commitment to the sustainable progress and
              development of the region. We eagerly anticipate the journey ahead
              and deeply appreciate our ongoing partnership, Our heartfelt
              gratitude goes to the administration of St. Kitts and Nevis for
              their invaluable support and unwavering dedication to fostering
              economic growth for all. <br /> <br />
              sincerely,
              <br />
              <strong> Uma Shanker Mishra </strong>
              <br /> Chairman, SPC Group
              <br /> Executive Chairman, SPC Bank- a private international bank, 
            </Playfair>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Message2023;
