import React from "react";
import "./Services.css";
import wm from "../../assets/Wealth Management 420X720.png";
import pm from "../../assets/Portfolio Management.png";
import cc from "../../assets/Corporate Counselling2.png";
import tem from "../../assets/Trust and Endowment Management.png";
import mAndA from "../../assets/m&a2 (2).png"
import { Link } from "react-router-dom";

const Services = () => {
  return (
    <section id="about2" className="about2 pt-4">
      <div className="container2">
        <header className="text-center mb-3">
          <h2 className="headings" style={{fontSize:"2rem"}}>Our Services</h2>
        </header>
        <div className="row no-gutters mb-4">
          <div
            className="content2 col-lg-6 col-md-6 col-sm-12 d-flex"
            data-aos="fade-up"
          >
            <div className="">
              <img src={wm} width="100%" height="100%" /> <br />
            </div>
          </div>
          <div className="content2 col-lg-6 col-md-6 col-sm-12 d-flex m-auto">
            <div>
              <div className="text-left">
              <h4 style={{ color: " #017129", fontWeight: "bold"}}>Wealth Management</h4>
              </div>
              <p style={{ textAlign: "justify" }}>
              SPC works with clients to better plan their estate growth, give them safe and secure financial planning based on market trends and help them plan their tax preparation and financial process support.

              </p>
              <Link to="/what-we-offer/wealth-management" className="about-btn2">
                Know More ➜
              </Link>
            </div>
          </div>
        </div>
        <div className="content2 align-items-stretch">
          <div className=" d-flex flex-column">
            <div className="row no-gutters">
              <div
                className="col-lg-6 col-md-12 mt-4"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div className="row">
                  <div className="col-xl-4 col-lg-5 col-md-4 col-sm-4">
                    <img src={pm} width="100%" height='200px'/>
                  </div>
                  <div className="col-xl-8 col-lg-7 col-md-8 col-sm-8 m-auto">
                    <div>
                      <h4 style={{ color: " #017129 ",fontWeight: "bold" }}>
                        Portfolio Management
                      </h4>
                      <p style={{ textAlign: "justify" }}>
                      We Help Manage your Assets, Provide Alternative Investment options and help Diversify Risk Management.
                      </p>
                      <Link to="/what-we-offer/portfolio-management" className="service-btn">
                        Read More ➜
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 mt-4" data-aos="fade-up">
                <div className="row">
                  <div className="col-xl-4 col-lg-5 col-md-4 col-sm-4">
                    <img src={cc} width="100%" height='200px'/>
                  </div>
                  <div className=" col-xl-8 col-lg-7 col-md-8 col-sm-8 m-auto">
                    <div>
                      <h4 style={{ color: " #017129 " ,fontWeight: "bold"}}>
                        Corporate Counselling
                      </h4>
                      <p style={{ textAlign: "justify" }}>
                      We help clients with a clear business succession plan that strategizes around financial educational seminars and real time counselling.

                      </p>

                      <Link to="/what-we-offer/corporate-counselling" className="service-btn">
                        Read More ➜
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 mt-4" data-aos="fade-up">
                <div className="row">
                  <div className="col-xl-4 col-lg-5 col-md-4 col-sm-4">
                    <img src={tem} width="100%" height='200px'/>
                  </div>
                  <div className=" col-xl-8 col-lg-7 col-md-8 col-sm-8 m-auto">
                    <div>
                      <h4 style={{ color: "#017129",fontWeight: "bold" }}>
                        Trust & Endowment Management
                      </h4>
                      <p style={{ textAlign: "justify" }}>
                      We work with clients looking for better financial plans and management for investments and growth.

                      </p>
                      <Link to="/what-we-offer/trust-and-endowment-management" className="service-btn">
                        Read More ➜
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-6 col-md-12 mt-4"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <div className="row">
                  <div className="col-xl-4 col-lg-5 col-md-4 col-sm-4">
                    <img src={mAndA} width="100%" height='200px'/>
                  </div>
                  <div
                    className=" col-xl-8 col-lg-7 col-md-8 col-sm-8 m-auto"
                  >
                    <div>
                      <h4 style={{ color: "#017129",fontWeight: "bold" }}>
                        Mergers & Acquisitions
                      </h4>
                      <p style={{ textAlign: "justify" }}>
                      SPC helps companies in the different phases - acquiring other companies or selling a business division. Our services are customized to meet the specific needs of every industry.
                      </p>
                      <Link to="what-we-offer/mergers-and-acquisitions" className="service-btn">
                        Read More ➜
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
