import React, { useEffect } from 'react'
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Header from '../../components/Header/Header';
import wInP from "../../assets/ourworkHeader.jpg";

import igf from '../../assets/healthCard.png';
import iwu2 from '../../assets/hospCard.png';
import { Link, useLocation } from 'react-router-dom';
const Index = () => {
  const {pathname} = useLocation();

  useEffect(() => {
    document.documentElement.scrollTo({
      top:0,
      left:0,
      behavior:"auto"
    })
  }, [pathname])
  return (
    <>
    <Header src={wInP} heading="Projects in Progress"/>
    <div className="mt-5 mb-5 container">
      <div className="head mt-5 mb-5 column-full">
      {/*<p className='contentFont mb-5 p-3' style={{textAlign:"center"}}>
      Sovereign Pacific Capital gives you an opportunity to plan your investment wisely
through our insightful research, advisory and investment programs in various
industries.
To the First Time Entrepreneurs and individuals looking for funds, Sovereign Pacific
Capital gives you a platform to springboard your business ideas and turn them into a
reality.
      </p> */}
      </div>

     <Row>
        <Col>
        <Card style={{ width: '80%' }} className="mx-auto">
      <Card.Img variant="top" src={iwu2} />
      <Card.Body>
        <Card.Title className="text-center">Hospitality</Card.Title>
        <Link variant="primary" className="d-flex justify-content-center btn" to='/work-in-progress/hospitality'>Know More</Link>
      </Card.Body>
    </Card>
    </Col>
    <Col>
    <Card style={{ width: '80%' }} className="mx-auto">
      <Card.Img variant="top" src={igf} />
      <Card.Body>
        <Card.Title className="text-center">Healthcare</Card.Title>
        <Link variant="primary" to='/work-in-progress/healthcare' className="d-flex justify-content-center btn">Know More</Link>
      </Card.Body>
    </Card>
    </Col>
  </Row>
  </div>
    </>
  )
}

export default Index
