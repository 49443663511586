import React from 'react'
import { Link } from 'react-router-dom'
import './Investments.css'

const Investments = () => {
  return (
    <section id="pricing" className="pricing"  style={{background:"#c8edda49"}}>

    <div className="container" data-aos="fade-up">

      <header className="text-center mb-3">
        <h2 className="headings MB-3">Investment Programs</h2>
        <span style={{fontSize: 'large'}}>
        Our Investment Programs are driven by the parallel growth plan for both the organisation and clients. We have taken up various global projects with the vision to achieve higher standards inclusively. Here is an opportunity for you to be part of what we are driving with two of our programs for long term investments.

 </span>
        </header>

      <div className="row gy-4" data-aos="fade-left">

        <div className="col-lg-6 col-md-6" data-aos="zoom-in" data-aos-delay="100">
          <div className="line" style={{height:"8px" , backgroundColor: "#017129"}}></div>
          <div className="box">
            <h3 style={{color: "#017129"}}>Partner With Us</h3>

            <div><Link to="/what-we-offer/invest-with-us" className="btn-buy">Know More</Link></div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6" data-aos="zoom-in" data-aos-delay="200">
          <div style={{height:"8px" , backgroundColor: "#017129"}}></div>
          <div className="box">
            <h3 style={{color: "#017129"}}>India Growth Fund</h3>
  <div>  <Link to="/what-we-offer/india-growth-fund" className="btn-buy">Know More</Link></div>
          </div>
        </div>

      </div>

    </div>

  </section>
  )
}

export default Investments