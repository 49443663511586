import React, { useEffect } from "react";
import Header from "../../components/Header/Header";
import SRHeader from "../../assets/SRHeader.jpg";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Cards from "../../components/Cards/Cards";
import sr1 from '../../assets/sr1.jpg'
import sr2 from '../../assets/sr2.webp'
import sr3 from '../../assets/sr3.jpg'
import sr4 from '../../assets/sr4.jpg'
import Button from "../../components/Button/Button";
import { Link, useLocation } from "react-router-dom";

const SuncastleResorts = () => {
  const {pathname} = useLocation();

  useEffect(() => {
    document.documentElement.scrollTo({
      top:0,
      left:0,
      behavior:"auto"
    })
  }, [pathname])
  return (
    <>
      <Header src={SRHeader} heading="Our Work" />
      <div className="mt-5 mb-5 container">
        <h2 className="fw-bold mt-5 mb-5 m-3 text-left headings">
          Suncastle Resorts
        </h2>
        <div className=" mt-5 mb-5 column-full p-3">
          <p className="contentFont mb-3">
          SPC constantly looks for investment opportunities where conventional vacation stays are made better and truly unique with the concept that offers luxury and comfort. Our investment of <Link to="/about-us/media">$200M</Link> in The Sun Castle Resort situated in St. Kitts in the Caribbean Islands is one of our major undertakings providing residents and guests an experience of luxurious state of the art resort and condominium which Introduces the unparalleled for discerning leisure. The project is currently underway and also offers for those looking for a lucrative investment opportunity and visa opportunities in the island.
<br/>The Suncastle Resorts offer luxurious ocean front villas and vacation rentals for residents and guests looking for a Caribbean getaway. Our property offers residents and guests a serene space far from the noise and hustle of the cities to relax and rejuvenate their senses.
<br/>The property offers both residents and guests an accessibility to their own private space equipped with a home away from home experience of a warm fireplace to a relaxing dip in the jacuzzi.

          </p>
        </div>
        <Row className="mt-3 mb-3">
          <Col>
           <Cards heading="Citizen by Investment Program" text="SPC’s Suncastle Resort Project in St Kitts and Nevis offers both investors and buyers an opportunity to be a citizen of this beautiful nation through our citizen by investment program."/>
          </Col>
          <Col>
            <Cards heading="Natural Bounty" text="Suncastle Resorts with its pristine water at the edge and rolling hills at the backdrop makes this resort property one of the most sought after property in the island."/>
          </Col>
        </Row>
        <Row className="mt-3 mb-3">
          <Col>
            <Cards heading="Luxurious Estate Services" text="From state of the art luxurious condominiums and villas to sprawling townhouses, Suncastle Resorts offers both residents and guest a home away from home experience with a touch of luxury in every element of the property."/>
          </Col>
          <Col>
            <Cards heading="World Class Amenities" text="From lush gardens and restaurants in the property, Suncastle resort gives our residents and guest an access to the exquisite pools, health centres to indulge and rejuvenate."/>
          </Col>
        </Row>
        <Row>
          <Col  className="text-center"><img src={sr1} height="300px" width="500px"/></Col>
          <Col  className="text-center"><img src={sr2} height="300px" width="500px"/></Col>
        </Row>
        <Row className="mt-3 mb-3">
          <Col  className="text-center"><img src={sr3} height="300px" width="500px"/></Col>
          <Col  className="text-center"><img src={sr4} height="300px" width="500px"/></Col>
        </Row>
        <div className="mt-3 text-center">
          <a href="https://www.suncastleresorts.com/" className="btn" target="_blank">Know More</a>
        </div>
      </div>
    </>
  );
};

export default SuncastleResorts;
