import React from "react";
import Img1 from "../../assets/mondee 4.jpg";
import Img2 from "../../assets/mondee2.jpg";
import Img3 from "../../assets/mondee3.jpg";
import Img4 from "./images/Spotlightlatest2.jpg";
import Img6 from "../../assets/executiveImg1.jpeg";
import Img7 from "../../assets/executiveImg2.jpeg";
import Img10 from "../../assets/spotlight-3.jpeg";
import Header from "../../components/Header/Header";
import FocusHeader from "../../assets/ourCulture.png";
import SPCTeam from "./images/Spotlightlatest.jpg";
import "./Gallery.css";

const Gallery = () => {
  const imageObjects = [
    {
      id: 4,
      image: Img4,
      text: "Honourable Prime Minister of St. Kitts & Nevis Dr. Terrance Drew along with Chairman SPC Gp",
    },
    {
      id: 1,
      image: SPCTeam,
      text: "Rt. Hon. Dr. Denzil L. Douglas, Minister of Economic Development and Investment, St. Kitts and Nevis with Chairman SPC Gp",
    },
    {
      id: 1,
      image: Img1,
      text: "SPC executives team meet at Mondee Technologies HQ at Austin, Texas",
    },
    {
      id: 2,
      image: Img2,
      text: "SPC executives team meet at Mondee Technologies HQ at Austin, Texas",
    },
    {
      id: 3,
      image: Img3,
      text: "SPC executives team meet at Mondee Technologies HQ at Austin, Texas",
    },

    {
      id: 6,
      image: Img6,
      text: "SPC's executive leadership, along with the Chairman, is meeting Olga Kefalogianni, the honorable Tourism Minister of Greece.",
    },
    {
      id: 7,
      image: Img7,
      text: "SPC's executive leadership, along with the Chairman, met Fokion Karavias, the Chief Executive Officer of Eurobank, and the Executive Board team in Athens, Greece.",
    },
    {
      id: 10,
      image: Img10,
      text: "SPC Bank Executives team with Hon’ble Mark Brantley, Premier of Nevis.",
    },
  ];
  return (
    <>
      <Header src={FocusHeader} heading="Gallery" />

      <section id="portfolio" class="portfolio">
        <div class="container">
          <div
            class="isotope-layout"
            data-default-filter="*"
            data-layout="masonry"
            data-sort="original-order"
          >
            <div
              class="row gy-4 isotope-container"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              {imageObjects?.map((item, index) => (
                <div
                  key={index}
                  class="col-lg-4 col-md-6 portfolio-item isotope-item filter-app"
                >
                  <img src={item.image} class="img-fluid" alt="" />
                  <div class="portfolio-info">
                    <strong>
                      {" "}
                      <p>{item.text}</p>
                    </strong>
                    {/*<a
                      href={item.image}
                      title="App 1"
                      data-gallery="portfolio-gallery-app"
                      class="glightbox preview-link"
                    >
                      <i class="bi bi-zoom-in"></i>
                    </a>
                    */}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Gallery;
