import React from "react";
import styled from "styled-components";
import chairmanMes from "../../assets/charimanMes.jpeg";
const Message2022 = () => {
  const Playfair = styled.p`
    @font-face {
      font-family: Playfair;
      src: url("../../Fonts/PlayFair.ttf");
    }
    font-family: Playfair;
  `;
  const Box1 = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
  `;
  const Box2 = styled.div`
    width: 100%;
    background-color: #017129;
  `;

  const BoxText = styled.div`
    color: white;
  `;

  return (
    <section id="" className="m-0 p-0">
      <div className="container" data-aos="fade-up">
        <div className="row mb-4">
          <div className="col-lg-3 col-md-6 col-sm-8 col-xs-6">
            <Box2 className="p-3" style={{ width: "100%" }}>
              <BoxText className="">
                <h6>From the Chairman's Desk</h6>
              </BoxText>
            </Box2>
          </div>
        </div>
        <div className="row">
          <Box1 className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
            <img src={chairmanMes} loading="lazy" width="100%" />
          </Box1>
          <div
            className="col-lg-9 col-md-9 col-sm-12 col-xs-12 mx-auto"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <Playfair
              style={{
                textAlign: "justify",
              }}
            >
              Dear Esteemed Colleagues and Partners, <br /> <br />
              I am humbled to update you on the progress and developments at
              Sovereign Pacific Capital (SPC) Group. We have successfully
              incorporated SPC Bank in Nevis Island, which is a significant
              milestone for our organization. This achievement has received
              overwhelming support from industry friends, international banks,
              and governments. We are expanding our capabilities and reach to
              better serve our clients and stakeholders globally, with plans to
              set up operations in London UK, Singapore, Dubai UAE Emirates, and
              New Delhi India.
              <br /> <br />
              Our commitment to compliance, transparency, and good corporate
              governance is reinforced through the establishment of SPC Bank in
              Nevis Island. We have stringent measures and policies in place to
              operate in a responsible and ethical manner. At SPC Group, we
              strive to provide innovative and reliable financial solutions that
              cater to the diverse needs of our clients. Our expertise in
              investment banking, wealth management, and corporate advisory
              services allows us to provide comprehensive financial solutions
              tailored to our clients' unique requirements. We continuously
              improve our services by adopting the latest technologies and
              industry best practices.
              <br />
              Our success is closely linked to the success of our clients, and
              we are committed to building long-term relationships with them to
              help them achieve their financial goals. With our expansion plans,
              we aim to serve a broader client base and create a more
              significant impact on the global financial market.
              <br /> <br />
              Thank you for your continued support, and we look forward to
              building a brighter future of abundance for all with our
              innovative and reliable financial solutions.
              <br />
              <br />
              Sincerely,
              <br />
              {/* <img src={signature} width="20%" /> */}
              <strong> Uma Shanker Mishra </strong>
              <br /> Chairman
              <br /> SPC Group
            </Playfair>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Message2022;
