import React, { useEffect } from 'react'
import Header from '../../components/Header/Header'
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardImage,
  MDBBtn
} from 'mdb-react-ui-kit';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import real2 from '../../assets/real2.png';
import real3 from '../../assets/real3.png';
import energy1 from '../../assets/energy1.png'
import health1 from '../../assets/health1.png'
import hotel1 from '../../assets/hotel1.png'
import sustain1 from '../../assets/sustain1.png'
import header from '../../assets/IndustryHeaderIll.jpg'
import './Industry.css';
import Button from '../../components/Button/Button';
import { Link, useLocation } from 'react-router-dom';

const RealEstate = () => {
  const {pathname} = useLocation();

  useEffect(() => {
    document.documentElement.scrollTo({
      top:0,
      left:0,
      behavior:"auto"
    })
  }, [pathname])
  return (
    <>
      <Header src={header} heading="Industry"/>
      <div className='container mb-5 mt-5'>

      <div className="row featurette ">
      <div className="col column align-middle">
      <h2 className="headings fw-bold m-auto">Real Estate</h2>
        <p className="contentFont">
        SPC seeks to utilize our global expertise and presence to generate attractive returns for our clients' investments in every environment and to make a positive impact on every community we invest in. Our Core business features stabilized real estate with a long investment horizon and moderate leverage, where we can unlock additional value through focused business plan and management. Our strategies focus on hospitality, healthcare institutes, and residential assets in global gateway cities. Our real estate business also comprises strategies tailored for income-focused individual investors and organizations with our yield oriented strategy.
Our investment principles are guided by patience and discipline, seeking unique opportunities to generate superior risk-adjusted returns by aligning with best in class partner investors, actively managing financial and currency risks and consistently providing transparency.

        </p>
      </div>
      <div className="col text-center">
      <img src={real2} height='400px' width='500px' />
      </div>
    </div>
    <div className="row featurette mt-5 ">
    <div className="col-md-5 ">
      <img src={real3} height='400px' width='500px' />
      </div>
      <div className="col-md-7 column col">
        <p className="contentFont">
        With deep experience managing global real estate assets our expert asset management teams drive value in investments from acquisition through disposition. In addition, we have an exceptional capability to make judgments on markets and investments and are distinguished by local market knowledge and insight, an industry specific global strategy and research team, plus the ability to leverage the vast resources and talent we have.
Our opportunistic business seeks to acquire undermanaged, well-located assets globally. In connection with these acquisitions, we build businesses that are set up to manage the underlying properties and ultimately maximize their value by instituting best in management. Post-acquisition, we also invest in the properties to improve them before converting the assets and returning capital to our valued partners. How we scale is one of our greatest strengths. The extent of our existing portfolio gives us differentiated perspectives across various sectors and geographies, while our significant discretionary capital base enables us to execute large and complex transactions.

        </p>
      </div>

    </div>
  <h2 className="fw-bold mt-5 mb-5 text-left head">More About Our Industries</h2>

    <Row className="mt-5 mb-5">
  <div className='col-md col-sm-12 mt-3'>
  <MDBCard>
    <MDBCardImage src={energy1} position='top' alt='...' height='180px'/>
    <MDBCardBody>
      <MDBCardTitle className="text-center">Energy</MDBCardTitle>
      <Link to='/industry/energy' className='d-flex justify-content-center btn'>Know More</Link>
    </MDBCardBody>
  </MDBCard>
  </div>
  <div className='col-md col-sm-12 mt-3'>
  <MDBCard>
    <MDBCardImage src={health1} position='top' alt='...' height='180px'/>
    <MDBCardBody>
      <MDBCardTitle className="text-center">Healthcare</MDBCardTitle>
      <Link to='/industry/healthcare' className='d-flex justify-content-center btn'>Know More</Link>

    </MDBCardBody>
  </MDBCard>
  </div>
  <div className='col-md col-sm-12 mt-3'>
  <MDBCard>
    <MDBCardImage src={hotel1} position='top' alt='...' height='180px'/>
    <MDBCardBody>
      <MDBCardTitle className='text-center'>Hospitality</MDBCardTitle>
      <Link to='/industry/hospitality' className='d-flex justify-content-center btn'>Know More</Link>

    </MDBCardBody>
  </MDBCard></div>
  <div className='col-md col-sm-12 mt-3'>
  <MDBCard>
    <MDBCardImage src={sustain1} position='top' alt='...' height='180px'/>
    <MDBCardBody>
      <MDBCardTitle className='text-center'>Sustainability And Impact</MDBCardTitle>
      <Link to='/industry/sustainability-and-impact' className='d-flex justify-content-center btn'>Know More</Link>

    </MDBCardBody>
  </MDBCard></div>
  </Row>
      </div>
    </>
  )
}

export default RealEstate