import React from "react";
import SpcSpotlightLatest from "../../components/SPC-Bank-Spotlight/SpcSpotlightLatest";
import ArchieveOne from "../../components/SPCSpotlightArchiveList/ArchieveOne";
import ArchieveTwo from "../../components/SPCSpotlightArchiveList/ArchieveTwo";

const SpcSpotlightArchive = () => {
  return (
    <div>
      <SpcSpotlightLatest />
      <br />
      <hr />
      <br />
      <ArchieveTwo />
      <br />
      <hr />
      <br />
      <ArchieveOne />
    </div>
  );
};

export default SpcSpotlightArchive;
