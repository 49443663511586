import React, { useEffect } from 'react'
import Header from '../../components/Header/Header'
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardImage,
  MDBBtn
} from 'mdb-react-ui-kit';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import energy2 from '../../assets/energy4.png'
import health1 from '../../assets/health1.png'
import energy3 from '../../assets/energy3.png'
import hotel1 from '../../assets/hotel1.png'
import real1 from '../../assets/real1.jpg'
import sustain1 from '../../assets/sustain1.png'
import header from '../../assets/IndustryHeaderIll.jpg'

import './Industry.css'
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
const Energy = () => {
  const {pathname} = useLocation();

  useEffect(() => {
    document.documentElement.scrollTo({
      top:0,
      left:0,
      behavior:"auto"
    })
  }, [pathname])
  return (
    <>
      <Header src={header} heading="Industry"/>
      <div className='container mb-5 mt-5'>

      <div className="row featurette">
      <div className="col m-auto column">
      <h2 className="headings fw-bold mt-0 mb-4 text-left ">Energy</h2>
        <p className="contentFont">
        SPC nurtures and believes in the idea of clean and electric technology. Our investments with Electric Mobility will help achieve impactful milestones and help accelerate the initiative to zero carbon transportation. Electric Mobility combines over a century of engineering expertise with the best innovators and cutting-edge technology to deliver unrivaled product choice on a global scale. Electric Mobility is the next-generation, carbon neutral electric bus and light commercial vehicle to be used in India. Designed, developed and manufactured in India and utilizing a global electric bus experience, The Electronic Vehicle is equipped with the latest technology, ultra-modern design, highest safety and best-in- class comfort features. The renewed iconic double decker bus is designed to revolutionize public transportation in the country and will set new standards in the intra-city bus market.
        </p>
      </div>
      <div className="col">
      <img src={energy2} height='400px' width='500px' />
      </div>
    </div>
    <div className="row featurette mt-5 ">
    <div className="col">
      <img src={energy3} height='400px' width='500px' />
      </div>
      <div className="col-md-7 col column">
        <p className="contentFont">
        All of these vehicles will not only help reduce each commuter’s carbon footprint, but also to realize the goal of a greener planet. Our Electric Mobility sustainable initiative aims at introducing public commuting vehicles producing zero carbon footprint, using cleaner energy and smarter transportation building roads that are less congested and air that is cleaner. Our Green Initiative is introducing an in-house technology innovation helping us to transform mobility through an intelligent connected fleet that acts like devices on wheels giving every customer total control. Electric Mobility is helping cities go electric which means offering affordable transportation alternatives through their lightweight modular buses, vans and pods which have the lowest cost of ownership available in the market.

        </p>
      </div>

    </div>
  <h2 className="fw-bold mt-5 mb-5 text-left head">More About Our Industries</h2>
    <Row className="mt-5 mb-5">
  <div className='col-md col-sm-12 mt-3'>
  <MDBCard>
    <MDBCardImage src={real1} position='top' alt='...' height='180px'/>
    <MDBCardBody>
      <MDBCardTitle className='text-center'>Real Estate</MDBCardTitle>
      <Link to='/industry/real-estate' className='d-flex justify-content-center btn'>Know More</Link>
    </MDBCardBody>
  </MDBCard>
  </div>
  <div className='col-md col-sm-12 mt-3'>
  <MDBCard>
    <MDBCardImage src={health1} position='top' alt='...' height='180px'/>
    <MDBCardBody>
      <MDBCardTitle className="text-center">Healthcare</MDBCardTitle>
      <Link to='/industry/healthcare' className='d-flex justify-content-center btn'>Know More</Link>

    </MDBCardBody>
  </MDBCard>
  </div>
  <div className='col-md col-sm-12 mt-3'>
  <MDBCard>
    <MDBCardImage src={hotel1} position='top' alt='...' height='180px'/>
    <MDBCardBody>
      <MDBCardTitle className='text-center'>Hospitality</MDBCardTitle>
      <Link to='/industry/hospitality' className='d-flex justify-content-center btn'>Know More</Link>

    </MDBCardBody>
  </MDBCard></div>
  <div className='col-md col-sm-12 mt-3'>
  <MDBCard>
    <MDBCardImage src={sustain1} position='top' alt='...' height='180px'/>
    <MDBCardBody>
      <MDBCardTitle className='text-center'>Sustainability And Impact</MDBCardTitle>
      <Link to='/industry/sustainability-and-impact' className='d-flex justify-content-center btn'>Know More</Link>

    </MDBCardBody>
  </MDBCard></div>
  </Row>
      </div>
    </>
  )
}

export default Energy