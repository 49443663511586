import React, { useEffect } from "react";
import Header from "../../components/Header/Header";

import investmentHeader from "../../assets/wwoHead.jpg";

import ma1 from "../../assets/m&a2 (2).png";
import ma2 from "../../assets/merge&acq.png";
import { useLocation } from "react-router-dom";
const MergersAndAcquisitions = () => {
  const {pathname} = useLocation();

  useEffect(() => {
    document.documentElement.scrollTo({
      top:0,
      left:0,
      behavior:"auto"
    })
  }, [pathname])
  return (
    <>
      <Header src={investmentHeader} heading="What We Offer" />
      <div className="container">
        <div className="row featurette ">
          <div className="col column">
          <h4 className=" headings mt-0 mb-2 text-left pt-5">
          Mergers And Acquisitions
        </h4>
            <p className="contentFont">
            SPC helps with due diligence services which involves analysis of the target company’s operations and informing  our client - the buyer, about possible threats and opportunities related to the structure of the transaction and future operations. Bringing organizations together through mergers and acquisitions, or carving them out through separations and divestitures, are among the most complex business activities an organization will undertake. Whether your organization is focused on tactical growth, business rationalization, or cost reduction, our strategies and solutions are designed to generate maximum value for the business and its shareholders.
            </p>
          </div>
          <div className="col text-center mt-3">
            <img src={ma1} height="400px" width="500px" />
          </div>
        </div>
        <div className="row featurette mt-5 ">
          <div className="col-md-7 order-md-2 column col p-3">
            <p className="contentFont">
            Leveraging our professionals’ industry-leading knowledge and powerful, data-driven insights, SPC’s Mergers and Acquisitions strategy & diligence service will guide you through the key business and risk-management processes, before, during and after a transaction. Our strategic cost transformation service helps organizations identify and realize sustainable cost efficiencies across the enterprise in times of restructuring and reorganization. Our deep sector experience, enhanced by impactful data analysis, enables clients to derive optimal value from the transaction. Our professionals will also strategize, design, and implement end-to-end solutions for successful transactions, from advance-planning to post-transaction restructuring. They’ll help you drive performance and results in every key Mergers and Acquisitions - related functions such as, project management, commercial activity optimization, operations, human capital management and finance activities.
            </p>
          </div>
          <div className="col-md-5 order-md-1">
            <img src={ma2} height="400px" width="500px" />
          </div>
        </div>
      </div>
    </>
  );
};

export default MergersAndAcquisitions;
