import React, { useEffect } from 'react'
import investmentHeader from "../../assets/wwoHead.jpg";

import tm1 from '../../assets/tm1.png'
import tm2 from '../../assets/tm2.png'
import Header from '../../components/Header/Header'

import './WhatWeOffer.css'
import { useLocation } from 'react-router-dom';
const TrustandEndowmentManagement = () => {
  const {pathname} = useLocation();

  useEffect(() => {
    document.documentElement.scrollTo({
      top:0,
      left:0,
      behavior:"auto"
    })
  }, [pathname])
  return (
    <>
    <Header src={investmentHeader} heading="What We Offer" />
    <div className='container mt-5'>
    <div className="row featurette">
    <div className="col column ">
    <h4 className=" headings mt-0 mb-2 text-left pt-5">
          Trust and Endowment Management
        </h4>
      <p className="contentFont">
      SPC also works to empower nonprofits, institutions and
foundations to pursue their financial objectives and mission-driven goals
through innovative investment and advisory solutions. As an independent firm
serving colleges and universities, hospitals, museums, community foundations,
social service agencies, family foundations and charitable trusts, our clients
benefit from the investment of our experience gained over years of sophisticated
endowment-style management, a culture of continuous improvement and a
shared set of values and interests.

      </p>
    </div>
    <div className="col text-center">
    <img src={tm1} height='400px' width='500px' />
    </div>
  </div>
  <div className="row featurette mt-5">
  <div className="col-md-5">
    <img src={tm2} height='400px' width='500px' />
    </div>
    <div className="col-md-7 column col">
      <p className="contentFont">
      We strive to deliver growth that matters to your organization and advance
the achievement of your goals across a sophisticated set of solutions. Your
mission matters to your organization, your community and to us - your
dedicated team at SPC. We leverage cross-firm expertise to help you
mitigate risk, position portfolios for growth or target a specific impact-related
outcome on issues such as climate change or rising wealth inequality. As an
extension of your team, we strive to streamline and simplify your organization’s
financial picture such as guiding boards, investment committees and staff
through the often-complex world of capital markets, reporting, regulatory
issues, governance and other financial matters.

      </p>
    </div>

  </div>
    </div>
    </>
  )
}

export default TrustandEndowmentManagement