import React from 'react'
import './Header.css'
const Header = (props) => {
  return (
   <div className="header">
   <img src={props.src ? props.src : "https://images.unsplash.com/photo-1591696205602-2f950c417cb9?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80"} alt="Header Image" height="300px" width="100%"></img>
   <h1 className="heading" >{props.heading ? props.heading : "Contact Us"}</h1>
   </div>
  )
}

export default Header