import React, { useEffect } from "react";
import Header from "../../components/Header/Header";
import OurWorkHeader from "../../assets/ourworkHeader.jpg";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import UpcomingThumbnail from "./images/1.png";
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardImage,
  MDBCardText,
} from "mdb-react-ui-kit";
import Button from "../../components/Button/Button";
import srout from "../../assets/srout.jpg";
import emout from "../../assets/emout.png";
import crout from "../../assets/crout.jpg";
import { Link, useLocation } from "react-router-dom";

const OurWork = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto",
    });
  }, [pathname]);
  return (
    <>
      <Header heading="Our Projects" src={OurWorkHeader} />
      <div className="container-fluid mt-5 mb-5">
        <Row>
          <div className="col-md-12 col-sm-12 mt-3 mb-3">
            <div className="mx-auto">
              <MDBCardImage
                src={UpcomingThumbnail}
                position="top"
                height="265px"
                style={{ objectFit: "contain" }}
              />
              <MDBCardBody>
                <MDBCardTitle className="head text-center head-color">
                  SPC Group Upcoming Projects
                </MDBCardTitle>
                <MDBCardText className="lead-box text-center">
                  Indulge in Caribbean Luxury
                </MDBCardText>
                <div className="text-center">
                  <Link
                    to="/our-work/upcoming-projects"
                    className="btn d-flex justify-content-center"
                    style={{ width: "fit-content", margin: "auto" }}
                  >
                    Know More
                  </Link>
                </div>
              </MDBCardBody>
            </div>
          </div>
          <div className="col-md col-sm-12 mt-3">
            <MDBCard style={{ width: "600px" }} className="mx-auto">
              <MDBCardImage src={emout} position="top" height="265px" />
              <MDBCardBody>
                <MDBCardTitle className="head text-center head-color">
                  Electric Mobility
                </MDBCardTitle>
                <MDBCardText className="lead-box text-center">
                  Electric buses, trucks and commercial vehicles manufacturing
                  plants in chennai
                </MDBCardText>
                <div className="text-center">
                  <Link
                    to="/our-work/electric-mobility"
                    className="btn d-flex justify-content-center"
                  >
                    Know More
                  </Link>
                </div>
              </MDBCardBody>
            </MDBCard>
          </div>
          <div className="col-md col-sm-12 mt-3">
            <MDBCard style={{ width: "600px" }} className="mx-auto">
              <MDBCardImage src={srout} position="top" height="265px" />
              <MDBCardBody>
                <MDBCardTitle className="head text-center head-color">
                  Suncastle Resorts
                </MDBCardTitle>
                <MDBCardText className="lead-box text-center">
                  Development of Real Estates, Luxury Resorts, Lakefront Villas
                  and Rentals
                </MDBCardText>
                <div className="text-center">
                  <Link
                    to="/our-work/suncastle-resorts"
                    className="btn d-flex justify-content-center"
                  >
                    Know More
                  </Link>
                </div>
              </MDBCardBody>
            </MDBCard>
          </div>
        </Row>
      </div>
    </>
  );
};

export default OurWork;
