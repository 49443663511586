import React, { useEffect, useState } from "react";
import {
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,
} from "mdb-react-ui-kit";
import Header from "../../components/Header/Header";
import img2 from "../../assets/wwoHead2.png";
import igfHeader from "../../assets/indiagate.jpg";
import IGF from "../../assets/IGFEnergy (1).png";
import IGF2 from "../../assets/IGFEnergy (2).png";
import IGF3 from "../../assets/IGFEnergy (3).png";
import igfimg2 from "../../assets/northeast.png";
import igfimg3 from "../../assets/igfimg3.jpg";
import "./WhatWeOffer.css";
import Button from "react-bootstrap/esm/Button";
import Modal from "../../components/Modal/Modal";
import { useLocation } from "react-router-dom";
const IndiaGrowthFund = () => {
  const [basicActive, setBasicActive] = useState("tab1");
  const { pathname } = useLocation();

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto",
    });
  }, [pathname]);
  const handleBasicClick = (value) => {
    if (value === basicActive) {
      return;
    }

    setBasicActive(value);
  };
  return (
    <>
      <Header src={img2} heading="Investments" />
      <div className="container">
        <div className="row featurette">
          <div className="col-md-7 m-auto">
            <h2 className="mb-3 headings">India Growth Fund</h2>
            <p className="contentFont">
            India Growth Funds is an investment program introduced by SPC for entrepreneurs and innovators of the country. This program is to support start-ups, entrepreneurs and innovators by facilitating them with expert advice and funding in the industries we currently work with.

<br/>The primary focus of the grant is the provision of financial advisors assigned to the awarded companies which provide expert guidance as well as connections. Companies also receive excellent portfolio services. The financial help remains a part of the overall support.


            </p>
          </div>
          <div className="col-md-5 mt-5 mb-5">
            <img src={igfHeader} height="400px" width="500px" />
          </div>
        </div>
        <div className="d-flex justify-content-left">
          <MDBTabs pills className="mb-5">
            <MDBTabsItem>
              <MDBTabsLink
                className="btn-switch"
                onClick={() => handleBasicClick("tab1")}
                active={basicActive === "tab1"}
              >
                Healthcare
              </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem>
              <MDBTabsLink
                className="btn-switch"
                onClick={() => handleBasicClick("tab2")}
                active={basicActive === "tab2"}
              >
                Hospitality
              </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem>
              <MDBTabsLink
                className="btn-switch"
                onClick={() => handleBasicClick("tab3")}
                active={basicActive === "tab3"}
              >
                Energy
              </MDBTabsLink>
            </MDBTabsItem>
          </MDBTabs>
        </div>
        <MDBTabsContent>
          <MDBTabsPane show={basicActive === "tab1"}>
            <div className="mt-3 mb-5">
              <div className="row featurette column-full">
                <div className="col-md-7 column align-middlem  ">
                  <h3 className="mb-0 headings text-left mb-3 mt-3">
                    Healthcare
                  </h3>

                  <p className="contentFont">
                    SPC Investment in the Health Sector is driven by
                    Intervention of Ancient Ayurveda, New Innovations in Medical
                    Science and Technology, Health Institutes and Universities.
                    Through our investments in life sciences and healthcare
                    technology infrastructures, we plan to accelerate the
                    development and accessibility of health services with the
                    potential to improve public health and health equity. SPC
                    drives innovation through its global network of partners and
                    collaborators. We welcome new collaborations to advance
                    creative solutions to the nation’s health challenges in
                    terms of technology and medical service. To engage with our
                    organization, discuss technologies and strategic
                    partnerships, please get in touch.
                  </p>
                  <div className="d-flex justify-content-center mb-3">
                    <Modal title="Get Funded" text="Get Funded" />
                  </div>
                </div>
                <div className="col-md-5">
                  <img src={igfimg3} height="400px" width="500px" />
                </div>
              </div>
            </div>
          </MDBTabsPane>

          <MDBTabsPane show={basicActive === "tab2"}>
            <div className="mt-5 mb-5">
              <div className="row featurette column-full">
                <div className="col-md-7 column align-middlem ">
                  <h3 className="mb-0 headings text-left mb-3 mt-3">
                    Luxury Hotels and Resorts
                  </h3>

                  <p className="contentFont">
                    SPC is investing exclusively in the hospitality sector with
                    an emphasis on luxury, full service and lifestyle for hotels
                    and resorts in India. With our successful investments in the
                    luxury hospitality sector globally, we plan to bridge the
                    gap between young entrepreneurs and innovators in the
                    hospitality industry in the country and increase the
                    investment returns offering opportunities and international
                    exposure from guests traveling from around the world and
                    exceeding industry benchmarks. If you are one of the few who
                    has a strong business plan that meets our vision and work
                    ethics for being the first of your time and see yourself as
                    building a luxurious hospitality brand in your country, get
                    in touch with us and let us talk.
                  </p>
                  <div className="d-flex justify-content-center mb-3">
                    <Modal title="Get Funded" text="Get Funded" />
                  </div>
                </div>
                <div className="col-md-5">
                  <img src={igfimg2} height="400px" width="500px" />
                </div>
              </div>
            </div>
          </MDBTabsPane>

          <MDBTabsPane show={basicActive === "tab3"}>
          <h1 className="headings text-center mb-3" style={{fontSize:"2rem"}}>
          India Spotlight Investment
        </h1>
            <div className="mt-5 mb-5">
              <div className="row featurette column-full m-auto">
                <div className="col-md-7 column align-middle  ml-3">
                  <h3 className="headings mb-3 mt-3">
                    Green Mobility Charging Station
                  </h3>
                  <p className="contentFont">
                    Creating a more sustainable future means meeting the needs
                    of the global market. How? By offering state of the art
                    solutions that go beyond the initial charging station. SPC
                    is looking for organizations that are working with green
                    technology and electromobility who have a strong business
                    plan that showcases charging stations set up for commercial
                    electric vehicles in India. To make it a reality, we are
                    looking to partner invest with utilities, fleet operators,
                    companies and cities alike. Our contribution is not just
                    investments, but complete partner solutions for
                    electromobility that combine many leaders in the field for
                    the know-how, innovative software and high-performance
                    products.
                  </p>
                  <div className="d-flex justify-content-center mb-3">
                    <Modal title="Partner With us" text="Partner With Us" />
                  </div>
                </div>
                <div className="col-md-5">
                  <img src={IGF2} height="400px" width="500px" />
                </div>
              </div>
              <div className="row featurette column-full mt-5">
                <div className="col-md-5 m-auto">
                  <img src={IGF} height="400px" width="500px" />
                </div>
                <div className="col-md-7 column align-middle">
                  <h3 className="headings text-left mb-3 mt-3">
                    Lithium-Ion Battery
                  </h3>
                  <p className="contentFont">
                    The advanced battery technology that uses lithium ions as a
                    key component to create Lithium Ion batteries have one of
                    the highest energy densities of any battery technology
                    today. SPC is interested in an advancement in this sector’s
                    manufacturing speed in India and is looking for
                    organizations who can deliver production of large amounts of
                    current for high-power applications with an approach to
                    technology innovations to develop and deliver a safer index
                    of compatibility and reliability. If you are one of them
                    working on building up a production line or expansion, let
                    us know.
                  </p>
                  <div className="d-flex justify-content-center mb-3">
                    <Modal title="Partner With us" text="Partner With Us" />
                  </div>
                </div>
              </div>
              <div className="row featurette column-full m-auto mt-5">
                <div className="col-md-7 column align-middle  ml-3">
                  <h3 className="headings mb-3 mt-3">
                    Semiconductor and Chips
                  </h3>
                  <p className="contentFont">
                    SPC understands that the global semiconductor market is
                    projected to grow tremendously in the next 10 years due to
                    the increasing consumption of consumer electronic devices
                    across the globe. SPC is inviting technology companies who
                    are primarily researching and developing innovative products
                    in this field to be part of our initiative in India and
                    increasing the production of semiconductor and chip devices
                    for the every increasing demand in the global market.
                  </p>
                  <div className="d-flex justify-content-center mb-3">
                    <Modal title="Partner With us" text="Partner With Us" />
                  </div>
                </div>
                <div className="col-md-5">
                  <img src={IGF3} height="400px" width="500px" />
                </div>
              </div>
            </div>
          </MDBTabsPane>
        </MDBTabsContent>
      </div>
    </>
  );
};

export default IndiaGrowthFund;
