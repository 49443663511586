import React from "react";
import styled from "styled-components";
import chairmanMes from "../../assets/charimanMes.jpeg";
const MessageLatest = () => {
  const Playfair = styled.p`
    @font-face {
      font-family: Playfair;
      src: url("../../Fonts/PlayFair.ttf");
    }
    font-family: Playfair;
  `;
  const Box1 = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
  `;
  const Box2 = styled.div`
    width: 100%;
    background-color: #017129;
  `;

  const BoxText = styled.div`
    color: white;
  `;

  return (
    <section id="" className="mb-5 p-0">
      <div className="container" data-aos="fade-up">
        <div className="row mb-4">
          <div className="col-lg-3 col-md-6 col-sm-8 col-xs-6">
            <Box2 className="p-3" style={{ width: "100%" }}>
              <BoxText className="">
                <h6>From the Chairman's Desk</h6>
              </BoxText>
            </Box2>
          </div>
        </div>
        <div className="row">
          <Box1 className="col-lg-3 col-md-6 col-sm-8 col-xs-6">
            <img src={chairmanMes} loading="lazy" />
          </Box1>
          <div className="col-lg-9 col-md-6 col-sm-12 col-xs-12">
            <Playfair
              style={{
                textAlign: "justify",
              }}
            >
              Dear Valued Partners, Associates, and Friends,
              <br />
              It is with immense pride and gratitude that I share a significant
              update regarding a momentous diplomatic visit that I had the
              honour of overseeing. As Chairman of SPC Group and Special Envoy
              for Foreign Direct Investments (ASIA) for Saint Kitts and Nevis, I
              recently had the privilege of coordinating and leading the
              high-level delegation from Saint Kitts and Nevis to India. This
              delegation, led by the Honourable Rt. Dr. Denzil Douglas, Minister
              of Foreign Affairs, marked a historic step forward in
              strengthening the bilateral ties between our two nations.
              <br />
              <br />
              The visit to India was not only a diplomatic success but also an
              embodiment of our shared vision for enhanced collaboration across
              diverse sectors. During the course of our discussions with Cabinet
              Ministers, Senior officials and Industry leaders, we laid the
              groundwork for a stronger partnership through the signing of
              several key Memoranda of Understanding (MoU’s). These agreements
              spanned across vital areas including Healthcare, Digital
              Transformation, Sports Development, and Technological
              Advancements, paving the way for robust future cooperation between
              India and Saint Kitts and Nevis.
              <br />
              As the key facilitator of this visit, I was deeply committed to
              ensuring that each interaction and agreement aligned with our
              broader strategic goals, bringing immense opportunities for both
              nations. The signed MoU’s signal a new chapter in our diplomatic
              and economic engagement, focusing on sustainable growth,
              innovation, and mutual prosperity.
              <br />
              <br />
              For SPC Group, this successful event aligns perfectly with our
              ongoing mission to foster global partnerships that drive growth
              and development across sectors, especially in India and SEA. As we
              continue to expand our global footprint, I am confident that the
              relationships we build today will yield long-term benefits, not
              only for SKN but for all our valued partners around the world. The
              visit to India is an attestation to the importance of
              collaboration, and the role that our team plays in facilitating
              such significant international engagements. I look forward to
              continuing this journey, strengthening our global alliances, and
              creating lasting impact through our projects and partnerships. On
              behalf of SPC Group, I thank you for your continuing support and
              belief in our vision. Together, we will continue to create value
              and drive progress. <br /> <br />
              sincerely,
              <br />
              <strong> H.E. Uma Shanker Mishra</strong>
              <br /> Chairman, SPC Group
              <br /> Executive Chairman, SPC Bank- a private international bank,
            </Playfair>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MessageLatest;
