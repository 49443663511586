import React, { useState } from "react";
import Container from "react-bootstrap/esm/Container";
import Header from "../../components/Header/Header";
import {
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,
} from "mdb-react-ui-kit";
import professional1 from "../../assets/professional1.png";
import "./Careers.css";
import Button from "react-bootstrap/esm/Button";
import CareerInsideHeader from '../../assets/careerHeader11.jpg'
import Modal from "../../components/Modal/Modal";
import Modal2 from "../../components/Modal/Modal2";
const Professional = () => {
  const [basicActive, setBasicActive] = useState("tab1");

  const handleBasicClick = (value) => {
    if (value === basicActive) {
      return;
    }

    setBasicActive(value);
  };
  return (
    <>
            <Header src={CareerInsideHeader} heading="Professionals"/>
      <div container-fluid className="mt-5 mb-5">
        <div className="container mt-5 mb-5">
          <div className="row featurette column-full">
            <div className="col-md-7 column align-middlem col">
              <p className="contentFont">
                We believe that by embracing diverse experiences and
                backgrounds, we can collaborate to create better outcomes for
                our people, our clients and our society. We are committed to
                growing and developing our employees to help them reach their
                full potential.
                <br />
                At Sovereign Pacific Capital, you will find trusted colleague,
                committed mentors and a culture of that values diverse
                perspectives, individual intellect and cross collaboration. See
                how many of you can continue your journey with us.
              </p>
            </div>
            <div className="col-md-5">
              <img src={professional1} height="400px" width="500px" />
            </div>
          </div>
        </div>
        <div>
          <h2 className="fw-bold mt-5 mb-5 text-center headings">
            Our Current Opening
          </h2>
        </div>
        <div className="m-5">
          <MDBTabs pills className="mb-5 mt-5">
            <MDBTabsItem className="">
              <MDBTabsLink
                onClick={() => handleBasicClick("tab1")}
                active={basicActive === "tab1"}

              >
                Human Resource Manager
              </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem  className="">
              <MDBTabsLink
                onClick={() => handleBasicClick("tab2")}
                active={basicActive === "tab2"}
              >
                IT
              </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem  className="">
              <MDBTabsLink
                onClick={() => handleBasicClick("tab3")}
                active={basicActive === "tab3"}
              >
                Finance and Accounting
              </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem  className="">
              <MDBTabsLink
                onClick={() => handleBasicClick("tab4")}
                active={basicActive === "tab4"}
              >
                Operations
              </MDBTabsLink>
            </MDBTabsItem>
          </MDBTabs>
        </div>
        <MDBTabsContent>
          <MDBTabsPane show={basicActive === "tab1"}>
            <div className="row mb-2 mt-5">
              <div className="col-md-6 mx-auto" style={{ width: "90%" }}>
                <div className="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                  <div className="col p-4 d-flex flex-column position-static">
                    <h3 className="mb-0 headings text-center mb-3 m-3">
                      Human Resource Manager
                    </h3>
                    <p className="card-text mb-auto contentFont" style={{fontWeight:400}}>
                      The Human Resource Manager will be responsible for the
                      management of all HR operations function for Sovereign
                      Pacific Capital. We are seeking a solutions focused
                      professional who will manage a team catering for the needs
                      of an expanding global organisation.
                      <br />
                      Areas of focus include payroll and benefits, time keeping,
                      regulatory oversight and governance and project
                      management. The role will support and drive the Human
                      Resources strategic agenda, participating and leading
                      improvements to the current model, enhancing the overall
                      control environment and working to proactively mitigate
                      risk through effective governance. The individual will be
                      required to build positive relationships and partner with
                      external vendors, business and functional teams to ensure
                      consistency and best practice as well as effective
                      communication with stakeholders.
                    </p>
                    <div className="d-flex justify-content-center m-3">
                    <Modal2 text="Apply Now"/>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </MDBTabsPane>

          <MDBTabsPane show={basicActive === "tab2"}>
            <div className="row mb-2 mt-5">
              <div className="col-md-6 mx-auto" style={{ width: "90%" }}>
                <div className="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                  <div className="col p-4 d-flex flex-column position-static">
                    <h3 className="mb-0 headings text-center mb-3">IT</h3>
                    <p className="card-text mb-auto contentFont" style={{fontWeight:400}}>
                      Our mission is to continuously improve our IT systems and
                      services, support our business and strive to create and
                      innovate with technology. One should be able to perform
                      successfully in a fast-paced, high-performing,
                      service-oriented environment. One can apply and interpret
                      rules and guidelines flexibly to enrich the business and
                      in keeping with Sovereign Pacific Capitals values and
                      culture.
                    </p>
                    <div className="d-flex justify-content-center m-3">
                    <Modal2 text="Apply Now"/>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </MDBTabsPane>
          <MDBTabsPane show={basicActive === "tab3"}>
            <div className="row mb-2 mt-5">
              <div className="col-md-6 mx-auto" style={{ width: "90%" }}>
                <div className="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                  <div className="col p-4 d-flex flex-column position-static">
                    <h3 className="mb-0 headings text-center mb-3">
                      Finance and Accounting
                    </h3>
                    <p className="card-text mb-auto contentFont" style={{fontWeight:400}}>
                      Finance Accounting Management (FAM) is responsible for
                      maintaining a strong and consistent control environment
                      across Firm wide Finance, promoting early operational risk
                      identification and assessment, effective design and
                      evaluation of controls and sustainable solutions to
                      mitigate operational risk. Financial Head Attestation
                      Program and governing other external reporting initiatives
                      including Quality Assurance, Accountability and
                      Attestations, Issues and Errors Management.
                    </p>
                    <div className="d-flex justify-content-center m-3">
                    <Modal2 text="Apply Now"/>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </MDBTabsPane>
          <MDBTabsPane show={basicActive === "tab4"}>
            <div className="row mb-2 mt-5">
              <div className="col-md-6 mx-auto" style={{ width: "90%" }}>
                <div className="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                  <div className="col p-4 d-flex flex-column position-static">
                    <h3 className="mb-0 headings text-center mb-3">Operations</h3>
                    <p className="card-text mb-auto contentFont" style={{fontWeight:400}}>
                      Here at Sovereign Pacific Capital, we know that people
                      look for great value combined with an excellent experience
                      from an organization they can trust. One needs to have
                      experience in operation working with various teams across
                      the board. For us, that means keeping ourselves customer
                      oriented and always being open to trying new things. Above
                      all, it is about working with people who are passionate
                      about building the organization of the future.
                    </p>
                    <div className="d-flex justify-content-center m-3">
                    <Modal2 text="Apply Now"/>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </MDBTabsPane>
        </MDBTabsContent>
      </div>
    </>
  );
};

export default Professional;
