import React, { useEffect } from "react";
import Header from "../../components/Header/Header";
import CultureHeader from "../../assets/ourCulture.png";
import aboutCulture from "../../assets/culture (1).png";
import aboutCulture2 from "../../assets/careerFooter1.png";
import aboutCulture3 from "../../assets/careerFooter2.png";
import aboutCulture4 from "../../assets/careerFooter3.png";
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardImage,
  MDBBtn
} from 'mdb-react-ui-kit';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import { useLocation } from "react-router-dom";

const Culture = () => {
  const {pathname} = useLocation();

  useEffect(() => {
    document.documentElement.scrollTo({
      top:0,
      left:0,
      behavior:"auto"
    })
  }, [pathname])
  return (
    <>
      <Header src={CultureHeader} heading=" Our Culture" />
      <div className="">
      <div className="m-4 border-bottom">
        <div className="headings text-center">
          <p className="contentFont container-fluid" style={{ textAlign: "center",fontWeight:400 }}>
            Sovereign Pacific Capital work culture is driven by a mission to
            transform the investment management services industry through
            innovation, reputation, research and speed. That means creating the
            right culture, hiring the best people and making sure everyone has
            the resources to do and be their best. There&#39;s this notion that
            to grow a business, you have to be ruthless. But we know there&#39;s
            a better way to grow. One where what&#39;s good for the bottom line
            is also good for our clients. We believe businesses can grow with a
            conscience, and succeed with a soul and that they can do it with
            inbound. That&#39;s why we&#39;ve created an ecosystem uniting
            financial strategy plans, reports, technology and community to help
            businesses grow better every day.
          </p>
        </div>
      </div>
      <div className="container">
         <div className="row featurette column-full">
         <div className="col-md-7 m-auto align-middle">
           <p className="contentFont p-2" style={{color:"#000"}}>
           If you thrive in a high-energy, entrepreneurial environment, we invite you to share your passion, ideas, and excitement at SPC. We provide equal employment opportunities without regard to race, colour, religion, sex, gender identity, national origin, age, protected veteran status. SPC complies with applicable state and local laws governing non-discrimination in employment in every location in which the company has facilities. This policy applies to all terms and conditions of employment, including recruiting, hiring, placement, promotion, termination, layoff, recall, transfer, leaves of absence, compensation, and training. SPC is committed to the full inclusion of all qualified individuals. If reasonable accommodation is needed to participate in the job application or interview process, to perform essential job functions, and/or to receive other benefits and privileges of employment. Our commitment to inclusion and equality includes a h4 belief that the diversity of our team is instrumental to our success. We strive to create workplaces where employees are empowered to bring their authentic selves to work.
           </p>
         </div>
         <div className="col-md-5" style={{display:"flex",justifyContent:"start"}}>
         <img src={aboutCulture} height='450px' width='535px' />
         </div>
       </div>
  <h2 className=" mt-5 mb-5 text-center headings" style={{fontWeight: "bold"}}>Culture We Represent </h2>

       <Row className="mt-5 mb-5">
  <div className="col-sm-12  col-md">
  <MDBCard>
    <MDBCardImage src={aboutCulture2} position='top' alt='...' height='300px'/>
    <MDBCardTitle className="m-3 text-center">

                  <h5 style={{fontWeight: "bold",color: "#017129"}}>Critical Thinking</h5>

              </MDBCardTitle>
  </MDBCard>
  </div>
  <div className="col-sm-12  col-md">
  <MDBCard>
    <MDBCardImage src={aboutCulture4} position='top' alt='...' height='300px'/>
    <MDBCardTitle  className="m-3 text-center">

                  <h5 style={{fontWeight: "bold",color: "#017129"}}>Team Player</h5>

              </MDBCardTitle>
  </MDBCard>
  </div>
  <div className="col-sm-12 col-md">
  <MDBCard>
    <MDBCardImage src={aboutCulture3} position='top' alt='...' height='300px'/>
    <MDBCardTitle  className="m-3 text-center">

                  <h5 style={{fontWeight: "bold",color: "#017129"}}>Global Workforce</h5>

              </MDBCardTitle>
  </MDBCard></div>
  </Row>
      </div>
      </div>
    </>
  );
};

export default Culture;
