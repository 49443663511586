import React, { useEffect } from 'react'
import Header from '../../components/Header/Header'
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardImage,
  MDBBtn
} from 'mdb-react-ui-kit';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import health2 from '../../assets/health2.png'
import health3 from '../../assets/health3.png'
import energy1 from '../../assets/energy1.png'
import hotel1 from '../../assets/hotel1.png'
import real1 from '../../assets/real1.jpg'
import sustain1 from '../../assets/sustain1.png'
import header from '../../assets/IndustryHeaderIll.jpg'

import './Industry.css'
import Button from '../../components/Button/Button';
import { Link, useLocation } from 'react-router-dom';
const Healthcare = () => {
  const {pathname} = useLocation();

  useEffect(() => {
    document.documentElement.scrollTo({
      top:0,
      left:0,
      behavior:"auto"
    })
  }, [pathname])
  return (
    <>
      <Header src={header} heading="Industry"/>
      <div className='container mb-5 mt-5'>

      <div className="row featurette ">
      <div className="col column align-middle ">
      <h2 className=" headings fw-bold mt-0 mb-4 text-left">Healthcare</h2>
        <p className="contentFont">
        SPC power healthcare businesses which deliver specialized care to communities by designing custom solutions to build financial strength, resilience and growth. We know how quickly the healthcare landscape is changing and see the opportunities that lie ahead. Drawing on decades of industry experience, we cultivate close and long-term relationships by identifying your organization’s specific needs as you scale, whether you’re a global leader or an emerging start-up. We provide clients with financial investment planning for Health Systems, Healthcare Services, Healthcare IT and Life Sciences with custom solutions and advanced digital tools in credit and financing, treasury management, international banking and real estate services. We help you manage asset & financial management, costs, accelerate cash flow and improve overall operational management to drive future growth.
        </p>
      </div>
      <div className="col text-center">
      <img src={health3} height='400px' width='500px' />
      </div>
    </div>
    <div className="row featurette mt-5 ">
    <div className="col-md-5">
      <img src={health2} height='400px' width='500px' />
      </div>
      <div className="col-md-7 column align-middle col">
        <p className="contentFont">
        Our interest in the Healthcare industry sector is to invest, partner and support medical institutes and medical universities in establishing infrastructure build-up, strategic financial opportunities and growth, management setup and specialized practitioners in the various specialized fields of medicine to build sustainable well driven institutes and driving successful stories through our associations.
Our emphasis lies in building up multi-specialised hospitals, either from ground up or upgrading existing institutes with an all-inclusive well driven infrastructure and specialized medical experts in various fields for communities. Our passion is also driven to work on building up medical hospitals exclusively for athletes from all sports fields to get access to the best medical support for their needs.

        </p>
      </div>

    </div>
  <h2 className="fw-bold mt-5 mb-5 text-left head">More About Our Industries</h2>
    <Row className="mt-5 mb-5">
  <div className='col-md col-sm-12 mt-3'>
  <MDBCard>
    <MDBCardImage src={real1} position='top' alt='...' height='180px'/>
    <MDBCardBody>
      <MDBCardTitle className='text-center'>Real Estate</MDBCardTitle>
      <Link to='/industry/real-estate' className='d-flex justify-content-center btn'>Know More</Link>

    </MDBCardBody>
  </MDBCard>
  </div>
  <div className='col-md col-sm-12 mt-3'>
  <MDBCard>
    <MDBCardImage src={energy1} position='top' alt='...' height='180px'/>
    <MDBCardBody>
      <MDBCardTitle className='text-center'>Energy</MDBCardTitle>
      <Link  to='/industry/energy' className='d-flex justify-content-center btn'>Know More</Link>
    </MDBCardBody>
  </MDBCard>
  </div>
  <div className='col-md col-sm-12 mt-3'>
  <MDBCard>
    <MDBCardImage src={hotel1} position='top' alt='...' height='180px'/>
    <MDBCardBody>
      <MDBCardTitle className='text-center'>Hospitality</MDBCardTitle>
      <Link to='/industry/hospitality' className='d-flex justify-content-center btn'>Know More</Link>

    </MDBCardBody>
  </MDBCard></div>
  <div className='col-md col-sm-12 mt-3'>
  <MDBCard>
    <MDBCardImage src={sustain1} position='top' alt='...' height='180px'/>
    <MDBCardBody>
      <MDBCardTitle className='text-center'>Sustainability & Impact</MDBCardTitle>
      <Link to='/industry/sustainability-and-impact' className='d-flex justify-content-center btn'>Know More</Link>
    </MDBCardBody>
  </MDBCard></div>
  </Row>
      </div>
    </>
  )
}

export default Healthcare