import React, { useEffect } from 'react'
import Header from '../../components/Header/Header'
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardImage,
  MDBBtn
} from 'mdb-react-ui-kit';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import sustain2 from '../../assets/sustain3.png'
import sustain3 from '../../assets/sustain2.png'
import energy1 from '../../assets/energy1.png'
import health1 from '../../assets/health1.png'
import hotel1 from '../../assets/hotel1.png'
import real1 from '../../assets/real1.jpg'
import header from '../../assets/IndustryHeaderIll.jpg'
import './Industry.css';
import Button from '../../components/Button/Button';
import { Link, useLocation } from 'react-router-dom';


const SustainabilityAndImpact = () => {
  const {pathname} = useLocation();

  useEffect(() => {
    document.documentElement.scrollTo({
      top:0,
      left:0,
      behavior:"auto"
    })
  }, [pathname])
  return (
    <>
    <Header src={header} heading="Industry"/>
    <div className='container mb-5 mt-5'>
    <div className="row featurette ">
    <div className="col column align-middle">
    <h2 className="headings fw-bold mb-3 text-left5">Sustainability And Impact</h2>

      <p className="contentFont">
      SPC main sustainability and impact driven initiatives are to help and support fund companies as committed to a better world. We want to encourage our partner clients and organizations to act responsibly in addition to delivering financial returns.
<br/>We have our own social impact initiative that we run called Global Human Care Foundation that has been making tremendous impacts in the field of women empowerment, education, health and clean water accessibility.

      </p>
    </div>
    <div className="col text-center">
    <img src={sustain3} height='400px' width='500px' />
    </div>
  </div>
  <div className="row featurette mt-5 ">
  <div className="col-md-5">
    <img src={sustain2} height='400px' width='500px' />
    </div>
    <div className="col-md-7 column align-middle col">
      <p className="contentFont">
      Our passion pursuing sustainable development is to catalyze the development of our projects across various industries and also to improve human health worldwide. We drive innovations through a global network of partnerships and collaborators and also welcome new collaborators to advance creative solutions to global health challenges and all across empowerment programs. Our leadership team believes in giving back and have been the sole drivers for driving impact programs for more than a decade through our foundation in the Caribbean Islands, Asia and North America.

      </p>
    </div>

  </div>
  <h2 className="fw-bold mt-5 mb-5 text-left head">More About Our Industries</h2>

  <Row className="mt-5 mb-5">
   <div className='col-md col-sm-12 mt-3'>
  <MDBCard>
    <MDBCardImage src={energy1} position='top' alt='...' height='180px'/>
    <MDBCardBody>
      <MDBCardTitle className="text-center">Energy</MDBCardTitle>
      <Link to='/industry/energy' className='d-flex justify-content-center btn'>Know More</Link>

    </MDBCardBody>
  </MDBCard>
  </div>
   <div className='col-md col-sm-12 mt-3'>
  <MDBCard>
    <MDBCardImage src={health1} position='top' alt='...' height='180px'/>
    <MDBCardBody>
      <MDBCardTitle className="text-center">Healthcare</MDBCardTitle>
      <Link to='/industry/healthcare' className='d-flex justify-content-center btn'>Know More</Link>

    </MDBCardBody>
  </MDBCard>
  </div>
   <div className='col-md col-sm-12 mt-3'>
  <MDBCard>
    <MDBCardImage src={hotel1} position='top' alt='...' height='180px'/>
    <MDBCardBody>
      <MDBCardTitle className='text-center'>Hospitality</MDBCardTitle>
      <Link to='/industry/hospitality' className='d-flex justify-content-center btn'>Know More</Link>

    </MDBCardBody>
  </MDBCard></div>
   <div className='col-md col-sm-12 mt-3'>
  <MDBCard>
    <MDBCardImage src={real1} position='top' alt='...' height='180px'/>
    <MDBCardBody>
      <MDBCardTitle className='text-center'>Real Estate</MDBCardTitle>
      <Link to='/industry/real-estate' className='d-flex justify-content-center btn'>Know More</Link>

    </MDBCardBody>
  </MDBCard>
  </div>
  </Row>
    </div>
  </>
  )
}

export default SustainabilityAndImpact