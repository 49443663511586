import React from 'react'
import Header from '../../components/Header/Header'
// import CareerHeader from '../../assets/CareerHeader.jpg'
import career1 from '../../assets/careerHeader11.jpg'
import intern from '../../assets/intern.png'
import professional from '../../assets/professional.png'
import Career1 from '../../assets/career2.png'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import {
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCardImage,
    MDBCardText

  } from 'mdb-react-ui-kit';
import Button from '../../components/Button/Button'
import './Careers.css'
import { Link } from 'react-router-dom'
const Career = () => {
  return (
    <>
    <Header src={career1} heading="Careers" />
        <div className='container'>
    <div className='mt-5 mb-5'>
      <div className="row featurette">
      <div className="col align-middle column">
        <p className="contentFont" style={{fontWeight:"inherit"}}>
        Join our diverse team of global financial innovators who work together to guide the world’s most successful individuals and institutions through exceptional service, collective expertise and uncompromising values.
<br/>When you work at SPC, you know your colleagues are among the best in their fields. They come from diverse backgrounds, but they’re united in their collective drive to serve clients and innovate for the future for them. See what greater means to us.

greater means to us.
        </p>
      </div>
      <div className="col-md-5">
      <img src={Career1} height='400px' width='500px' />
      </div>
    </div>
      </div>
      <div className="m-4">
        <div className="px-4 pt-5 my-5 text-center border-bottom">
          <h4 className="headings">What are you looking for?</h4>
          <div className="col-lg-6 mx-auto">
            <p className="contentFont mb-4 text-center">
             Let us help you find an opportunity that best fits for you
            </p>
            <div className="d-grid gap-2 d-sm-flex justify-content-sm-center mb-5"></div>
          </div>
        </div>
        </div>
    <Row className='mt-5 mb-5'>
        <div className='col-md col-sm-12 mt-3'>
        <MDBCard style={{width: "80%"}} className="mx-auto">
      <MDBCardImage src={professional} position='top' alt='...' />
      <MDBCardBody>
        <MDBCardTitle className=' text-center headings'>For Professionals</MDBCardTitle>
        <MDBCardText className='lead-box text-center'>You've honed your skill set. Now, use it to your full potential at Sovereign Pacific Wealth
        </MDBCardText>
        <Link to='/careers/professionals' className='btn d-flex justify-content-center'>Know More</Link>
      </MDBCardBody>
    </MDBCard></div>
        <div className='col-md col-sm-12 mt-3'>
        <MDBCard style={{width: "80%"}} className="mx-auto">
      <MDBCardImage src={intern} position='top' alt='...' />
      <MDBCardBody>
        <MDBCardTitle className='text-center headings'>For Interns</MDBCardTitle>
        <MDBCardText className='lead-box text-center'>Ready to launch career with us? Learn about our internships, analyst programs and job openings
        </MDBCardText>
        <Link to="/careers/interns" className='btn d-flex justify-content-center'>Know More</Link>
      </MDBCardBody>
    </MDBCard></div>
    </Row>
    </div>
    </>
  )
}

export default Career