import React from "react";
import Img1 from "./images/Spotlightlatest2.jpg";
import Img2 from "./images/Spotlightlatest.jpg";

import "../SPC-Bank-Spotlight/SpcBank.css";

const ArchieveOne = () => {
  return (
    <section id="" className="main_spc_bank">
      <div className="container mt-3 mb-3">
        <div className="row no-gutters">
          <div className="image col-xl-3 m-auto">
            <img
              src={Img1}
              alt=""
              width={"90%"}
              style={{
                borderRadius: "25px",
                background: "green",
                padding: "10px",
              }}
            />
            <p
              style={{
                fontSize: "12px",
                width: "90%",
                textAlign: "center",
                fontWeight: 600,
              }}
            >
              Honourable Prime Minister Dr. Terrance Drew St. Kitts & Nevis
            </p>
          </div>
          <div className="col-xl-6 d-flex align-items-center">
            <div>
              <p>
                {" "}
                <h4
                  style={{
                    color: "green",
                    textAlign: "center",
                    fontFamily: "sans-serif",
                    letterSpacing: "1px",
                  }}
                >
                  H.E. Uma Shanker Mishra: Commissioned as <br />
                  Special Envoy (Diplomat) for Asia
                </h4>
                <br />
                <strong>
                  <i>
                    With deep honor, the SPC Group, SPC Bank, all esteemed
                    well-wishers, and privileged stakeholders extend our
                    sincerest congratulations to His Excellency Uma Shanker
                    Mishra on his appointment as a special envoy (Diplomat) for
                    Asia by the government of St. Kitts and Nevis. His extensive
                    diplomatic experience will undoubtedly enhance relations
                    between St. Kitts and Nevis and Asian nations.
                    <br />
                    <br />
                    His designation as a special envoy reaffirms St. Kitts and
                    Nevis's commitment to expanding its diplomatic presence and
                    fostering cooperation within the Asian region. His
                    appointment is a testament to his vast experience and
                    ability to effectively represent St. Kitts and Nevis on the
                    global stage, promoting mutual understanding and
                    cooperation.
                  </i>{" "}
                </strong>{" "}
              </p>
            </div>
          </div>
          <div className="image col-xl-3 m-auto">
            <img
              src={Img2}
              alt=""
              width={"90%"}
              style={{
                borderRadius: "25px",
                background: "green",
                padding: "10px",
              }}
            />
            <p
              style={{
                fontSize: "12px",
                width: "90%",
                textAlign: "center",
                fontWeight: 600,
              }}
            >
              Rt. Hon. Dr. Denzil L. Douglas, Minister of Economic Development
              and Investment, <br />
              St. Kitts and Nevis
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ArchieveOne;
