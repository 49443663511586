import React, {useState} from "react";
import {
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,
} from "mdb-react-ui-kit";
import sport1 from  '../../assets/sportstab1.png'
import sport2 from  '../../assets/sportstab2.png'
import sport3 from  '../../assets/sportstab3.png'

const TabContent = () => {
  const [basicActive, setBasicActive] = useState("tab1");
  const handleBasicClick = (value) => {
    if (value === basicActive) {
      return;
    }

    setBasicActive(value);
  };
  return (
    <div>

      <div className="align-left text-left">
      <h4 className="headings text-left mb-3">
        Sports University Wing
      </h4>
        <MDBTabs pills className="mx-auto">
          <MDBTabsItem className="">
            <MDBTabsLink
              onClick={() => handleBasicClick("tab1")}
              active={basicActive === "tab1"}
            >
              Sports Academy
            </MDBTabsLink>
          </MDBTabsItem>
          <MDBTabsItem className="">
            <MDBTabsLink
              onClick={() => handleBasicClick("tab2")}
              active={basicActive === "tab2"}
            >
             Rehabilitation Center
            </MDBTabsLink>
          </MDBTabsItem>
          <MDBTabsItem className="">
            <MDBTabsLink
              onClick={() => handleBasicClick("tab3")}
              active={basicActive === "tab3"}
            >
              Sports Medicine Institute
            </MDBTabsLink>
          </MDBTabsItem>
        </MDBTabs>
      </div>

      <div className="mt-5 mb-5">
        <MDBTabsContent>
          <MDBTabsPane show={basicActive === "tab1"}>
            <div className="row featurette column-full m-auto">
              <div className="col-md-7 column align-middle  ml-3">
                <p className="contentFont">
                  The Sports Academy will be a place where young athletes can
                  realize their full potential, the campus will inspire and
                  empower them to achieve extraordinary sports perfection in
                  their respective fields. We plan to incorporate progressive
                  and ambitious attitudes to reflect in success through various
                  training programs for students and staff across board they do
                  all the while working together. We plan to be Asia’s largest
                  concentration of world class facilities supporting a wide
                  range of sports, making it the best multi-sport facility.
                </p>
                <div className="d-flex justify-content-center mb-3"></div>
              </div>
              <div className="col-md-5">
                <img src={sport1} height="400px" width="500px" />
              </div>
            </div>
          </MDBTabsPane>

          <MDBTabsPane show={basicActive === "tab2"}>
            <div className="row featurette column-full mt-5">
              <div className="col-md-5 m-auto">
                <img src={sport2} height="400px" width="500px" />
              </div>
              <div className="col-md-7 column align-middle">
                <p className="contentFont">
                  The Medical Wing of the University will have athletes access
                  to the best medical facility and rehabilitation programs to
                  help them with their physical treatments. The facility will be
                  backed with the best medical support to assist athletes and
                  sports teams in a goal-oriented fashion, with a full range of
                  services from prevention, diagnostic and treatment of sport
                  injuries to rehabilitation.
                </p>
                <div className="d-flex justify-content-center mb-3"></div>

              </div>
            </div>
          </MDBTabsPane>
          <MDBTabsPane show={basicActive === "tab3"}>
            <div className="row featurette column-full m-auto mt-5">
              <div className="col-md-7 column align-middle  ml-3">
                <p className="contentFont">
                  The Education Wing of this initiative will support the highest
                  international quality of specialized learning in sports
                  medicine and will give the students competence that is
                  relevant for every modern sport medical challenge and
                  stimulate innovative thinking among them through medical
                  practice and technology. From Sports Medicine, Physical
                  Performance and Outdoor Sports Science the team of teaching
                  faculty and students can have both full time and part time
                  courses.
                </p>
                <div className="d-flex justify-content-center mb-3"></div>
              </div>
              <div className="col-md-5">
                <img src={sport3} height="400px" width="500px" />
              </div>
            </div>
          </MDBTabsPane>
        </MDBTabsContent>
      </div>
    </div>
  );
};

export default TabContent;
