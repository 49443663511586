import React, { useEffect } from 'react'
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Header from '../../components/Header/Header';
import investmentheader from '../../assets/wwoHead2.png'
import igf from '../../assets/IGF5.png';
import iwu2 from '../../assets/iwu2.png';
import './WhatWeOffer.css';
import { Link, useLocation } from 'react-router-dom';
const Investments = () => {
  const {pathname} = useLocation();

  useEffect(() => {
    document.documentElement.scrollTo({
      top:0,
      left:0,
      behavior:"auto"
    })
  }, [pathname])
  return (
    <>
    <Header src={investmentheader} heading="Investments"/>
    <div className="mt-5 mb-5 container">
     <Row>
        <div className='col-md-6 col-sm-12 '>
        <Card style={{ width: '80%' }} className="mx-auto mt-3">
      <Card.Img variant="top" src={iwu2} />
      <Card.Body>
        <h4 className="text-center" style={{fontWeight : "bold", color:"#017129"}}>Partner With Us</h4>
        <Link variant="primary" className="d-flex justify-content-center btn" to='/what-we-offer/invest-with-us'>Know More</Link>
      </Card.Body>
    </Card>
    </div>
    <div className='col-md-6 col-sm-12 '>
    <Card style={{ width: '80%' }} className="mx-auto mt-3">
      <Card.Img variant="top" src={igf} />
      <Card.Body>
        <h4  className="text-center" style={{fontWeight : "bold",color:"#017129"}}>India Growth Fund</h4>
        <Link variant="primary" to='/what-we-offer/india-growth-fund' className="d-flex justify-content-center btn">Know More</Link>
      </Card.Body>
    </Card>
    </div>
  </Row>
  </div>
    </>
  )
}

export default Investments