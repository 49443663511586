import React from "react";
import Header from "../../components/Header/Header";
import intern1 from "../../assets/intern1.png";
import intern2 from "../../assets/intern2.png";
import CareerInsideHeader from "../../assets/careerHeader11.jpg";
import Modal2 from "../../components/Modal/Modal2";
const Intern = () => {
  return (
    <div className="container-fluid">
      <Header src={CareerInsideHeader} heading="Interns" />
      <div className="container mt-5 mb-5">
        <div className="row featurette column-full">
          <div className="col-md-7 column align-middle col">
            <p className="contentFont">
              Explore all the ways to start your career at Sovereign Pacific
              Capital , no matter your field of interest, or background. If
              you’re ready to excel, we want you on our team.  Learn about
              career development opportunities at SPC, where you’ll collaborate
              with industry leaders and innovators who will guide and support
              you, as you forge a path to success.
              <br />
              At SPC, rewarding career opportunities for students and recent
              graduates range from internships to full-time employment, and as
              diverse and compelling as the people we hire.
            </p>
          </div>
          <div className="col-md-5">
            <img src={intern1} height="400px" width="500px" />
          </div>
        </div>
      </div>
      <h2 className="fw-bold mt-5 mb-5 text-center headings">
        Our Internship Programs
      </h2>
      <div className="container mt-5 mb-5">
        <div className="row featurette column-full">
          <div className="col-md-5">
            <img src={intern2} height="400px" width="500px" />
          </div>
          <div className="col-md-7 column align-middle">
            <p className="contentFont">
              Students in their last year of undergraduate studies or in their
              second year of business school with an anticipated graduation date
              may apply for our full-time Financial Analyst and Associate
              positions.
              <br />
              Discover opportunities at the intersection of finance and
              technology. People from around the world come here to gain
              real-world experience while making an impact. You can have a
              career that’s exciting, rewarding and full of possibilities. From
              a global exposure with the work that comes to you, to
              international site transfer for the work you’re assigned to.
              <br />
              We rely on our principles to guide how we interact with each
              other, our clients, the communities in which we operate, and all
              of our other stakeholders. These principles represent our core
              values, our aspirations, and our cultural language – lived and
              embraced by every employee at our company.
              Candidates selected for an interview should expect a
              first round video interview with a member of the SPC team followed
              by a series of meetings, if selected to move forward.
              <br />
            </p>
          </div>
        </div>
      </div>
      <h2 className="fw-bold text-center headings">
        Our Internship Program Benefits
      </h2>
      <ul
        className="list-group mx-auto mb-5"
        style={{ width: "80%"}}
      >
        <li className="list-group-item contentFont">
          We help more and more people experience financial well-being, which
          applies as much to you as it does our clients. At SPC, you’ll have
          access to our financial mentorship and educational resources to help
          you build a sound financial future.
        </li>
        <li className="list-group-item contentFont">
          We provide a great selection of benefits and programs to help you
          manage health care cost and insurance when you need one.
        </li>
        <li className="list-group-item contentFont">
        We understand that juggling various responsibilities such as personal life, work, and other commitments can be quite challenging. Therefore, we offer time off options that enable you to take a break from work without any added stress, allowing you to prioritize moments that hold significance to you. Whether you need to recharge or attend to pressing matters, our time off options provide the flexibility you need.
        </li>
        <li className="list-group-item contentFont">
          Focus on your strengths and build the expertise you need to further
          your career. Our training programs and employee networks provide
          resources for meaningful professional network, skill building and
          professional as well as personal growth.
        </li>
        <li className="list-group-item contentFont">
          We take care of our interns professional growth and give them access
          to all the global possibilities of work from international locations,
          all-expense paid work travel, medical insurance covered to any on the
          job requirement, growth exposure in the industry we work in.
        </li>
      </ul>

      <div className="text-center mb-5">
      <Modal2 text="Apply Now" />
</div>
     </div>
  );
};

export default Intern;
