import React, { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Footer from "./components/Footer/Footer";
import SideBar from "./components/SideBar/SideBar";
import "./index.css";
import ContactUs from "./Pages/ContactUs/ContactUs";
import Home from "./Pages/Home/Home";
import Energy from "./Pages/Industry/Energy";
import Hospitality from "./Pages/Industry/Hospitality";
import Healthcare from "./Pages/Industry/Healthcare";
import RealEstate from "./Pages/Industry/RealEstate";

import CorporateCounselling from "./Pages/WhatWeAre/CorporateCounselling";
import Investments from "./Pages/WhatWeAre/Investments";
import PortfolioManagement from "./Pages/WhatWeAre/PortfolioManagement";
import SustainabilityAndImpact from "./Pages/Industry/SustainabilityAndImpact";
import InvestWithUs from "./Pages/WhatWeAre/InvestWithUs";
import IndiaGrowthFund from "./Pages/WhatWeAre/IndiaGrowthFund";
import Error404 from "./Pages/Error404/Error404";
import Career from "./Pages/Careers/Career";
import Professional from "./Pages/Careers/Professional";
import Intern from "./Pages/Careers/Intern";
import About from "./Pages/AboutUs/About";
import DetailAboutTeam from "./Pages/AboutUs/DetailAboutTeam";
import Culture from "./Pages/AboutUs/Culture";
import Focus from "./Pages/AboutUs/Focus";
import Media from "./Pages/AboutUs/Media";
import TrustandEndowmentManagement from "./Pages/WhatWeAre/TrustandEndowmentManagement";
import OurWork from "./Pages/OurWork/OurWork";
import ElectricMobility from "./Pages/OurWork/ElectricMobility";
import BlogsMain from "./Pages/Blogs/BlogsMain";
import WorkInProgress from "./Pages/WorkInProgress/index";
import WorkInProgressHealth from "./Pages/WorkInProgress/WorkInProgressHealth";
import WorkInProgressHospital from "./Pages/WorkInProgress/WorkInProgressHospital";
import MergersAndAcquisitions from "./Pages/WhatWeAre/MergersAndAcquisitions";
import PartnerWithUs from "./Pages/PartnerWithUs/PartnerWithUs";
import SuncastleResorts from "./Pages/OurWork/SuncastleResorts";
import WealthManagement from "./Pages/WhatWeAre/WealthManagement";
import PrivacyPolicy from "./Pages/Terms/PrivacyPolicy";
import TermsAndCondition from "./Pages/Terms/TermsAndCondition";
import Message2023 from "./components/MessageFromFounder/Message2023";
import Gallery from "./Pages/AboutUs/Gallery";
import Upcoming from "./Pages/OurWork/Upcoming";
import SpcSpotlightArchive from "./Pages/SpcSpotlightArchive";
import Message2022 from "./components/MessageFromFounder/Message2022";
import MessageLatest from "./components/MessageFromFounder/MessageLatest";

function App() {
  // state
  return (
    <>
      <BrowserRouter>
        <SideBar />
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route
            path="/what-we-offer/invest-with-us"
            exact
            element={<InvestWithUs />}
          />
          <Route
            path="/spc-spotlight-archive"
            exact
            element={<SpcSpotlightArchive />}
          />
          <Route
            path="/what-we-offer/india-growth-fund"
            exact
            element={<IndiaGrowthFund />}
          />
          <Route
            path="/what-we-offer/portfolio-management"
            exact
            element={<PortfolioManagement />}
          />
          <Route
            path="/what-we-offer/trust-and-endowment-management"
            exact
            element={<TrustandEndowmentManagement />}
          />
          <Route
            path="/what-we-offer/corporate-counselling"
            exact
            element={<CorporateCounselling />}
          />
          <Route
            path="/what-we-offer/mergers-and-acquisitions"
            exact
            element={<MergersAndAcquisitions />}
          />
          <Route
            path="/what-we-offer/investments"
            exact
            element={<Investments />}
          />
          <Route
            path="/what-we-offer/wealth-management"
            exact
            element={<WealthManagement />}
          />
          <Route path="/industry/energy" exact element={<Energy />} />
          <Route path="/industry/healthcare" exact element={<Healthcare />} />
          <Route path="/industry/hospitality" exact element={<Hospitality />} />
          <Route path="/industry/real-estate" exact element={<RealEstate />} />
          <Route
            path="/industry/sustainability-and-impact"
            exact
            element={<SustainabilityAndImpact />}
          />
          <Route path="/careers" exact element={<Career />} />
          <Route
            path="/careers/professionals"
            exact
            element={<Professional />}
          />
          <Route path="/careers/interns" exact element={<Intern />} />
          <Route path="/about-us/our-people" exact element={<About />} />
          <Route path="/nov-msg" exact element={<Message2022 />} />
          <Route path="/march-msg" exact element={<Message2023 />} />
          <Route path="/sept-msg" exact element={<MessageLatest />} />

          <Route
            path="/our-people/our-team"
            exact
            element={<DetailAboutTeam />}
          />
          <Route path="/about-us/our-culture" exact element={<Culture />} />
          <Route path="/about-us/our-focus" exact element={<Focus />} />
          <Route path="/about-us/media" exact element={<Media />} />
          <Route path="/about-us/gallery" exact element={<Gallery />} />
          <Route path="/our-work" exact element={<OurWork />} />
          <Route
            path="/our-work/upcoming-projects"
            exact
            element={<Upcoming />}
          />
          <Route
            path="/our-work/suncastle-resorts"
            exact
            element={<SuncastleResorts />}
          />
          <Route
            path="/our-work/electric-mobility"
            exact
            element={<ElectricMobility />}
          />

          <Route path="/contact-us" exact element={<ContactUs />} />
          <Route path="*" element={<Error404 />} />
          <Route path="/blogs" exact element={<BlogsMain />} />
          <Route path="/work-in-progress" exact element={<WorkInProgress />} />
          <Route
            path="/work-in-progress/healthcare"
            exact
            element={<WorkInProgressHealth />}
          />
          <Route
            path="/work-in-progress/hospitality"
            exact
            element={<WorkInProgressHospital />}
          />
          <Route path="/partner-with-us" exact element={<PartnerWithUs />} />
          <Route path="/partner-with-us" exact element={<PartnerWithUs />} />
          <Route path="/privacy-policy" exact element={<PrivacyPolicy />} />
          <Route
            path="/terms-and-conditions"
            exact
            element={<TermsAndCondition />}
          />
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
