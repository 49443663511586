import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";

export default function Footer() {
  return (
    <footer id="footer" className="footer">
      <div className="footer-newsletter">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12 text-center">
              <h4>Our Newsletter</h4>
            </div>
            <div className="col-lg-6">
              <form action="" method="post">
                <input type="email" name="email" />
                <input type="submit" value="Subscribe" />
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-top">
        <div className="container">
          <div className="row gy-4">
            <div className="col-lg-5 col-md-12 footer-info">
              <Link to="/" className="logo  align-items-center">
                <img src="assets/img/logo.png" alt="" />
                <span>SPC</span>
              </Link>
              <p className="m-2" style={{ textAlign: "justify" }}>
                We are fully invested in being an organization you can trust us
                to provide the best financial solutions for you to succeed. We
                foster a diverse, connected, collaborative culture that
                encourages various ways of thinking with differentiated
                insights. We embrace innovation to address increasingly complex
                investing challenges to deliver long term sustainable Growth for
                you.
              </p>
            </div>

            <div className="col-lg-2 col-6 footer-links">
              <h4>Useful Links</h4>
              <ul>
                <li>
                  <i className="bi bi-chevron-right"></i>{" "}
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <i className="bi bi-chevron-right"></i>{" "}
                  <Link to="/about-us/our-people">About us</Link>
                </li>
                <li>
                  <i className="bi bi-chevron-right"></i>{" "}
                  <Link to="/blogs">Blogs</Link>
                </li>
                <li>
                  <i className="bi bi-chevron-right"></i>{" "}
                  <Link to="terms-and-conditions">Terms and conditions</Link>
                </li>
                <li>
                  <i className="bi bi-chevron-right"></i>{" "}
                  <Link to="/privacy-policy">Privacy policy</Link>
                </li>
              </ul>
            </div>

            <div className="col-lg-2 col-6 footer-links">
              <h4>Our Services</h4>
              <ul>
                <li>
                  <i className="bi bi-chevron-right"></i>{" "}
                  <Link to="/what-we-offer/wealth-management">
                    Wealth Management
                  </Link>
                </li>
                <li>
                  <i className="bi bi-chevron-right"></i>{" "}
                  <Link to="/what-we-offer/portfolio-management">
                    Portfolio Management
                  </Link>
                </li>
                <li>
                  <i className="bi bi-chevron-right"></i>{" "}
                  <Link to="/what-we-offer/corporate-counselling">
                    Corporate Counselling
                  </Link>
                </li>
                <li>
                  <i className="bi bi-chevron-right"></i>{" "}
                  <Link to="/what-we-offer/trust-and-endowment-management">
                    Trust and Endowment
                  </Link>
                </li>
                <li>
                  <i className="bi bi-chevron-right"></i>{" "}
                  <Link to="/what-we-offer/mergers-and-acquisitions">
                    Mergers and Acquisitions
                  </Link>
                </li>
              </ul>
            </div>

            <div className="col-lg-3 col-md-12 footer-contact text-center text-md-start">
              <h4>Contact Us</h4>
              <p>
                SPC Group PH04 16 Brookers Lane Toronto, Ontario M8V0A5, Canada{" "}
              </p>
              <p>
                <strong>Email:</strong> info@spcgroup.org <br />
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="copyright">
          &copy; Copyright{" "}
          <strong>
            <span>SPC</span>
          </strong>
          . All Rights Reserved
        </div>
      </div>
    </footer>
  );
}
