import React from "react";
import Button from "react-bootstrap/Button";
import "./InvestmentForm.css";
import Form from "react-bootstrap/Form";
const InvestmentForm = () => {
  return (
    <>
      <Form action="https://formsubmit.co/info@spcgroup.org" method="post">
        <Form.Group className="mb-3">
          <Form.Control placeholder="Name" name="name" />
        </Form.Group>
        <Form.Group>
          <Form.Control
            name="email"
            type="email"
            placeholder="Email"
            className="mb-3"
          />
        </Form.Group>

        <Form.Control
          as="textarea"
          name="description"
          placeholder="Description"
          className="mb-3"
        />
        <Form.Group>
          <Form.Control
            name="proposal"
            type="text"
            placeholder="Drive Link to your Proposal"
            className="mb-3"
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Control name="websiteLink" placeholder="Company Website Link" />
        </Form.Group>
        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>
    </>
  );
};

export default InvestmentForm;

{
  /* <Row className="mb-3">
<Form.Group as={Col} controlId="formGridEmail">
  <Form.Control type="text" placeholder="Title" />
</Form.Group>

<Form.Group as={Col} controlId="formGridPassword">
  <Form.Control type="text" placeholder="Company Name" />
</Form.Group>
</Row> */
}
