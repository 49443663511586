import React from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
const ProfessionalForm = () => {
  return (
    <>
      <Form action="https://formsubmit.co/info@spcgroup.org" method="post">
        <Form.Group className="mb-3">
          <Form.Control name="name" placeholder="Name" />
        </Form.Group>
        <Form.Group>
          <Form.Control
            name="email"
            type="email"
            placeholder="Email"
            className="mb-3"
          />
        </Form.Group>

        <Form.Control
          name="description"
          as="textarea"
          placeholder="Description"
          className="mb-3"
        />
        <Form.Group className="mb-3">
          <Form.Group className="mb-3">
            <Form.Control name="linkedIn" placeholder="LinkedIn Profile Link" />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Control name="resume" placeholder="Drive Link of Resume" />
          </Form.Group>
        </Form.Group>
        <div className="text-center">
          <Button variant="primary" type="submit">
            Submit
          </Button>
        </div>
      </Form>
    </>
  );
};

export default ProfessionalForm;
