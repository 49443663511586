import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import "../WhatWeAre/WhatWeOffer.css";
import {
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,
} from "mdb-react-ui-kit";
import heal1 from "../../assets/healthcareHome.png";
import heal2 from "../../assets/heal (2).png";
import Abhinav from "../../assets/abhinav.png";
import wInP from "../../assets/ourworkHeader.jpg";
import { Link, useLocation } from "react-router-dom";
import TabContent from "../../components/TabContent/TabContent";
const WorkInProgressHealth = () => {
  const [basicActive2, setBasicActive2] = useState("tab4");
  const { pathname } = useLocation();

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto",
    });
  }, [pathname]);
  const handleBasicClick = (value) => {
    if (value === basicActive2) {
      return;
    }

    setBasicActive2(value);
  };
  return (
    <>
      <Header src={wInP} heading="Projects in Progress" />
      <div className="container">
        <div className="mt-5 mb-5">
        <div className="row featurette">
        <div className="col-md-7 m-auto align-middlem ">
        <p style={{color:"red"}} className="headings">SPC Spotlight</p> 
              <h2 className=" headings fw-bold mt-1">Healthcare</h2>
              <p className="contentFont">
                SPC has taken the next step in introducing the largest most
                advanced Sports University with an investment partner, Abhinav
                Futuristics Private Limited, an organization that aims at
                bringing synergy of Technology, Expertise and Passion in the
                Indian Sports and Healthcare Ecosystem. PricewaterhouseCoopers
                our consultant partner for this project. SPC is the invest partner for building up Asia’s most advanced
                and biggest Sports and Medical rehabilitation Centre, to help
                athletes from all fields in India and South East Asia to have
                access to the best treatments and therapy programs to help them
                recover and rehabilitate. The Sports University will not only
                provide Sports Medicine to athletes but also provide them with
                an access to the best Sports Academy for training and mastering
                their art along with an institute to train future Medical
                experts in the field of Sports Medicine. This partner initiative
                with Abhinav Bhindra, an Olympic gold medallist and 5 time
                Olympian under the Abhinav Futuristic Private Limited will
                integrate Science and Technology in the Medical and Sports
                Ecosystem by distributing the Global Best practice..
              </p>
            </div>
            <div className="col-md-5">
              <img src={heal1} height="400px" width="500px" />
            </div>
          </div>
          <div className="row featurette mt-5">
            <div className="col-md-7 order-md-2 m-auto ">
              <p className="contentFont">
                This Center of Excellence for Sports will be made up of various
                wings that will build an entire ecosystem that will support
                upcoming athletes, sports teams and seasoned athletes in all
                their need to achieve their highest form and optimize their
                performance in all the international platforms they compete in
                the future. SPC understands that the healthcare sector worldwide
                amounts to be one of the most important and fast-growing
                industries especially in the field of Sports Medicine. The key
                drivers are demographic trends, medical progress, the resulting
                innovations, rising prosperity and improved access to medical
                infrastructure and services. In addition, there is a great need
                for new therapies for a steady increasing number of health
                issues that athletes in the world need. The innovation in
                therapy programs, products and business models remain strong in
                this field. We understand the need for an institute that
                provides Sports Medicine & Rehabilitation, Sports Academy for
                training and a Sports Institute for future Sports Medicine
                Specialists is the need of the hour for India and South East
                Asia.
              </p>
            </div>
            <div className="col-md-5 text-center order-md-1">
              <img src={heal2} height="400px" width="500px" />
            </div>
          </div>
        </div>
        <TabContent />
        <div className="row mb-5 container mx-auto">
          <div className="col-md-8 m-auto headings pb-5">
            <p className="contentFont" style={{ fontWeight: "lighter" }}>
              Abhinav Bindra is SPC’s Project Partner for the Sports
              University for India and South East Asia. He is India’s first
              individual Olympic Gold Medallist and a 5-Time Olympian. He
              is currently a member of the IOC Athletes’ Commission and
              has been involved in work with Global Sport on various key issues.
              Now an entrepreneur, he is also equally invested in Indian
              grassroots sports development, using sports science and
              technology. Abhinav Futuristics Pvt. Ltd. is known for its
              progressive, dynamic and customer-oriented approach in the
              international and domestic market. To cater to the needs of
              various sectors with world-class innovations, AFL’s objective is
              to distribute unparalleled products, services, and facilities
              through Functional, Friendly and Innovative experiences for the
              Sport and Health & Fitness
            </p>
            <div className="text-center">
              <a href="https://abhinavfuturistics.in/" target="_blank" className="btn">
                Know More
              </a>
            </div>
          </div>
          <div className="col-md-4  m-auto text-center">
            <img src={Abhinav} alt="" width="300px" height={"300px"} /> <br />
            <br />
            <div>
              <h5
                className="headings text-center"
                style={{ fontSize: "1.1rem" }}
              >
                Abhinav Bindra
              </h5>
            </div>
            <p
              className="lead text-center"
              style={{ color: "#017129", marginTop: "-5px" }}
            >
              <strong>
                Project Head <br />
                Sports University, India
              </strong>
            </p>
          </div>
        </div>
        <div>
          <h4
            className=" mt-5 mb-5 headings text-center"
            style={{ fontWeight: "bold" }}
          >
            Our Sports University Approach is on
          </h4>
          <div className="d-flex">
            <MDBTabs pills className="mx-auto">
              <MDBTabsItem className="">
                <MDBTabsLink
                  onClick={() => handleBasicClick("tab4")}
                  active={basicActive2 === "tab4"}
                >
                  Support
                </MDBTabsLink>
              </MDBTabsItem>
              <MDBTabsItem className="">
                <MDBTabsLink
                  onClick={() => handleBasicClick("tab5")}
                  active={basicActive2 === "tab5"}
                >
                  Leadership
                </MDBTabsLink>
              </MDBTabsItem>
              <MDBTabsItem className="">
                <MDBTabsLink
                  onClick={() => handleBasicClick("tab6")}
                  active={basicActive2 === "tab6"}
                >
                  Health and Wellbeing
                </MDBTabsLink>
              </MDBTabsItem>
            </MDBTabs>
          </div>
        </div>
        <MDBTabsContent>
          <MDBTabsPane show={basicActive2 === "tab4"}>
            <div className="row mb-2 mt-5">
              <div className="col-md-6 mx-auto" style={{ width: "90%" }}>
                <div className="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                  <div className="col p-4 d-flex flex-column position-static">
                    <h3
                      className="mb-0 headings text-center mb-3"
                      style={{ color: "#017129" }}
                    >
                      Support
                    </h3>
                    <p
                      className=" contentFont"
                      style={{ fontWeight: "inherit" }}
                    >
                      On development programs which enhance the development of
                      young athletes to provide a platform that gives them an
                      opportunity to get trained with the best coaches from the
                      world and perfect their skills to prepare them for
                      international events.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </MDBTabsPane>

          <MDBTabsPane show={basicActive2 === "tab5"}>
            <div className="row mb-2 mt-5">
              <div className="col-md-6 mx-auto" style={{ width: "90%" }}>
                <div className="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                  <div className="col p-4 d-flex flex-column position-static">
                    <h3
                      className="mb-0 headings text-center mb-3"
                      style={{ color: "#017129" }}
                    >
                      Leadership
                    </h3>
                    <p
                      className=" contentFont"
                      style={{ fontWeight: "inherit" }}
                    >
                      We aim to strengthen the Sports in the development sector
                      through Impact measurement, research and mentorship
                      programs by various sports leaders from the country and
                      the rest of the world, to work with our athletes and help
                      in developing them and prepare to be best in their
                      respective fields.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </MDBTabsPane>
          <MDBTabsPane show={basicActive2 === "tab6"}>
            <div className="row mb-2 mt-5">
              <div className="col-md-6 mx-auto" style={{ width: "90%" }}>
                <div className="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                  <div className="col p-4 d-flex flex-column position-static">
                    <h3
                      className="mb-0 headings text-center mb-3"
                      style={{ color: "#017129" }}
                    >
                      Health and Wellbeing
                    </h3>
                    <p
                      className=" contentFont"
                      style={{ fontWeight: "inherit" }}
                    >
                      Our university will also have a separate wing dedicated to
                      the rehabilitation of athletes supported by the best
                      sports therapist and medical specialist to help them with
                      their physical and mental healing and get them back on
                      their feet. The University will also provide various
                      programs for individuals looking to train and certify in
                      the field of sports medicines and physiotherapy.
                    </p>
                    <div className="d-flex justify-content-center"></div>
                  </div>
                </div>
              </div>
            </div>
          </MDBTabsPane>
        </MDBTabsContent>
      </div>
    </>
  );
};

export default WorkInProgressHealth;
