import React, { useEffect } from "react";
import Header from "../../components/Header/Header";
import FocusHeader from "../../assets/ourCulture.png";
import Focusabout1 from "../../assets/focusMedia (1).png";
import focusFooter3 from "../../assets/focusFooter3.png";
import focusFooter1 from "../../assets/focusFooter1.png";
import focusFooter2 from "../../assets/focusFooter2.png";
import { MDBCard, MDBCardImage, MDBCardTitle } from "mdb-react-ui-kit";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import { useLocation } from "react-router-dom";

const Focus = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto",
    });
  }, [pathname]);
  return (
    <>

      <Header src={FocusHeader} heading=" Our Focus" />
        <div className="container">
          <div className="row featurette column-full mt-5">
            <div className="col-md-7 m-auto align-middle">
              <p className="contentFont p-2" style={{ color: "black" }}>
              SPC’s main focus is designed to help increase customers’ long-
              term returns and lower taxes for retirement planning, building
              wealth, and other financial goals. Our focus is driven by a
              mission to transform the investment management services industry
              through innovation, reputation, research and speed. That means
              creating the right culture, hiring the best people and making sure
              everyone has the resources to do and be their best. There is this
              notion that to grow a business, you have to be ruthless. But we
              know there's a better way to grow. One where what is good for the
              bottom line is also good for our clients. We believe businesses
              can grow with a conscience, and succeed with a soul and that they
              can do it with inbound. That's why we have created an ecosystem
              uniting financial strategy plans, reports, technology and
              community to help businesses grow better every day.
              </p>
            </div>
            <div
              className="col-md-5"
              style={{ display: "flex", justifyContent: "start" }}
            >
              <img src={Focusabout1} height="450px" width="530px" />
            </div>
          </div>
          <h2
            className="mt-5 mb-5 text-center headings"
            style={{ fontWeight: "bold" }}
          >
            {" "}
            Our Focus
          </h2>

          <Row className="mt-5 mb-5">
            <div className="col-md col-sm-12">
              <MDBCard>
                <MDBCardImage
                  src={focusFooter1}
                  position="top"
                  alt="..."
                  height="300px"
                />
                <MDBCardTitle className="m-3 text-center">
                  <h5 style={{ fontWeight: "bold" ,color: "#017129"}}>Security</h5>
                </MDBCardTitle>
              </MDBCard>
            </div>
            <div className="col-md col-sm-12">
              <MDBCard>
                <MDBCardImage
                  src={focusFooter2}
                  position="top"
                  alt="..."
                  height="300px"
                />
                <MDBCardTitle className="m-3 text-center">
                  <h5 style={{ fontWeight: "bold",color: "#017129" }}>Trust</h5>
                </MDBCardTitle>
              </MDBCard>
            </div>
            <div className="col-md col-sm-12">
              <MDBCard>
                <MDBCardImage
                  src={focusFooter3}
                  position="top"
                  alt="..."
                  height="300px"
                />
                <MDBCardTitle className="m-3 text-center">
                  <h5 style={{ fontWeight: "bold" ,color: "#017129"}}>Strategy</h5>
                </MDBCardTitle>
              </MDBCard>
            </div>
          </Row>
        </div>
    </>
  );
};

export default Focus;
