import React, { useEffect } from "react";
import Header from "../../components/Header/Header";
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardImage,
  MDBLink,
} from "mdb-react-ui-kit";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import hotel2 from "../../assets/hotel2.png";
import hotel3 from "../../assets/hotel3.jpg";
import energy1 from "../../assets/energy1.png";
import health1 from "../../assets/health1.png";
import real1 from "../../assets/real1.jpg";
import sustain1 from "../../assets/sustain1.png";
import header from "../../assets/IndustryHeaderIll.jpg";
import "./Industry.css";
import { Link, useLocation } from "react-router-dom";
const Hospitality = () => {
  const {pathname} = useLocation();

  useEffect(() => {
    document.documentElement.scrollTo({
      top:0,
      left:0,
      behavior:"auto"
    })
  }, [pathname])
  return (
    <>
      <Header src={header} heading="Industry" />
      <div className="container mb-5 mt-5">

        <div className="row featurette">
          <div className="col column align-middle">
            <h2 className="headings fw-bold mt-5text-left">
          Hospitality
        </h2>
            <p className="contentFont">
            SPC focuses on investing in hotels and other hospitality - led real estate asset classes globally. With our footprint in the Caribbean islands of St. Kitts and Nevis, Fairfield (Toronto), Holiday Inn (New York), Mariotte (Nice, Paris), Mariotte (Frankfurt, Berlin) and Sheraton (London), we have future plans to expand globally in the near future. We strive to provide our clients with superior investment performance combined with first ‐ class service.
              <br />
              We invest across the capital stack in hotel and hospitality - related assets including single assets, portfolios and companies, with a focus on major cities and tourist destinations worldwide.
            </p>
          </div>
          <div className="col text-center">
            <img src={hotel2} height="400px" width="500px" />
          </div>
        </div>
        <div className="row featurette mt-5">
          <div className="col-md-5 ">
            <img src={hotel3} height="400px" width="500px" />
          </div>
          <div className="col-md-7 column align-middle col">
            <p className="contentFont">
            Our best in class team of professionals of industry experts bring together a truly global track record and unique skill sets across multiple real estate and hospitality disciplines including acquisitions, asset management and financial management and help us deliver superior risk adjusted returns to our investors. We target real estate assets in key gateway cities that benefit from a diverse range of demand drivers including luxury leisure and business demand. We seek to deploy the team’s significant global real estate experience towards identifying and unlocking attractive, underperforming and sometimes distressed hospitality real estate investment opportunities, most of which require significant strategy expertise in order to fully maximize its potential. Through active and intensive asset management and our competitive edge in hotel operational expertise allows us to drive value to the hotel operations and hence deliver superior returns on our investments.

            </p>
          </div>
        </div>
        <h2 className="fw-bold mt-5 mb-5 text-center head">
          Know More About Industries
        </h2>
        <Row className="mt-5 mb-5">
          <div className='col-md col-sm-12 mt-3'>
            <MDBCard>
              <MDBCardImage
                src={energy1}
                position="top"
                alt="..."
                height="180px"
              />
              <MDBCardBody>
                <MDBCardTitle className="text-center">Energy</MDBCardTitle>
                <Link
                 to="/industry/energy"
                  className="d-flex justify-content-center btn"
                >
                  Know More
                </Link>
              </MDBCardBody>
            </MDBCard>
          </div>
          <div className='col-md col-sm-12 mt-3'>
            <MDBCard>
              <MDBCardImage
                src={health1}
                position="top"
                alt="..."
                height="180px"
              />
              <MDBCardBody>
                <MDBCardTitle className="text-center">Healthcare</MDBCardTitle>
                <Link
                 to="/industry/healthcare"
                  className="d-flex justify-content-center btn"
                >
                  Know More
                </Link>
              </MDBCardBody>
            </MDBCard>
          </div>
          <div className='col-md col-sm-12 mt-3'>
            <MDBCard>
              <MDBCardImage
                src={sustain1}
                position="top"
                alt="..."
                height="180px"
              />
              <MDBCardBody>
                <MDBCardTitle className="text-center">
                  Sustainability And Impact
                </MDBCardTitle>
                <Link
                 to="/industry/sustainability-and-impact"
                  className="d-flex justify-content-center btn"
                >
                  Know More
                </Link>
              </MDBCardBody>
            </MDBCard>
          </div>
          <div className='col-md col-sm-12 mt-3'>
            <MDBCard>
              <MDBCardImage
                src={real1}
                position="top"
                alt="..."
                height="180px"
              />
              <MDBCardBody>
                <MDBCardTitle className="text-center">Real Estate</MDBCardTitle>
                <Link
                 to="/industry/real-estate"
                  className="d-flex justify-content-center btn"
                >
                  Know More
                </Link>
              </MDBCardBody>
            </MDBCard>
          </div>
        </Row>
      </div>
    </>
  );
};

export default Hospitality;
