import React, { useEffect } from "react";
import Header from "../../components/Header/Header";
import Modal from "../../components/Modal/Modal";
import PWUHeader from "../../assets/IndustryHeaderIll.jpg";
import PWU from "../../assets/pwu2.png";
import PWU1 from "../../assets/pwu3.png";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
const PartnerWithUs = () => {
  const {pathname} = useLocation();
const Comp = styled.div`
  text-align: center;
`
  useEffect(() => {
    document.documentElement.scrollTo({
      top:0,
      left:0,
      behavior:"auto"
    })
  }, [pathname])
  return (
    <>
      <Header src={PWUHeader} heading="Partner With us" />

      <div className="container mt-5">

      <div className="row featurette ">
          <div className="col-md-7 column ">
          <h4 className="mt-1 mb-5 text-left headings head">
        Impact And Scale
      </h4>
            <p className="contentFont">
              Communities drive banking innovation, and your skills, knowledge,
              and capabilities – combined with SPC’s leading financial strategic
              programs, tools, and support – have created an ecosystem that’s
              ready for success. IMPACT and SCALE will help you achieve that
              success. <br /> <br />
              Our Partnership Program is based on:
              <ul>
                <li>Strategic Advisor Service</li>
                <li>Sales Partner</li>
                <li>Service Delivery Partner</li>
                <li>Solution Provider</li>
                <li>Technology Partner</li>
              </ul>
            </p>
          </div>
          <div className="col-md-5">
            <img src={PWU} height="400px" width="500px" />
          </div>
        </div>
        <div className="row featurette mt-5">
          <div className="col-md-7 order-md-2 column">
            <p className="contentFont">
              Our Objective is to support partners in:
              <ul>
                <li>Building new Business Opportunities globally.</li>
                <li>Adding new capabilities</li>
                <li>Growing Secure Financial Practice</li>
              </ul>
            </p>
          </div>
          <div className="col-md-5 order-md-1">
            <img src={PWU1} height="400px" width="500px" />
          </div>
        </div>
<Comp className="mt-5 mb-5">
<Modal text="Partner With Us ->" />
</Comp>
      </div>
    </>
  );
};

export default PartnerWithUs;
