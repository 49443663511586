import React, { useEffect } from "react";
import Header from "../../components/Header/Header";
import HeaderImg from "../../assets/IndustryHeaderIll.jpg";
import { useLocation } from "react-router-dom";

const TermsAndCondition = () => {
    
  const {pathname} = useLocation();

  useEffect(() => {
    document.documentElement.scrollTo({
      top:0,
      left:0,
      behavior:"auto"
    })
  }, [pathname])
  return (
    <>
      <Header heading={"Terms and Conditions"} src={HeaderImg} />
      <h6 className="container contentFont mt-5" style={{fontWeight:400, lineHeight:"2rem"}}>
        Use of this website (www.spcgroup.org) and/or the services
        offered by us indicates your acceptance of our disclaimer. By visiting
        our site you are agreeing to be bound by the following terms and
        conditions. We may change these terms and conditions at any time. Your
        continued use of www.spcgroup.org means that you accept any
        new or modified terms and conditions that we come up with. Please
        re-visit the “Terms and Conditions” link at our site from time to time
        to stay abreast of any changes that we may introduce. By enrolling as a
        client of SPC, you consent to receiving calls from
        your Investment Manager, from our registered land line and mobile
        numbers, as and when required.
      </h6>
    </>
  );
};

export default TermsAndCondition;
