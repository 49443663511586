import React from "react";
import { Link } from "react-router-dom";

const PartnerWithUs = () => {
  return (
    <section
      id="pricing"
      className="pricing"
      style={{ background: "#c8edda49" }}
    >
      <div className="container" data-aos="fade-up">
        <header className="text-center mb-3">
          <h2 className="mb-3 headings">Partner With Us</h2>
          <span style={{ fontSize: "large" }}>
          Communities driven in banking innovation, skills, knowledge, and capabilities combined with SPC’s leading financial strategic programs, tools, and support have created an ecosystem that’s ready for success.

            <br />

          </span>
        </header>

        <div className="row gy-4" data-aos="fade-left">
          <div
            className="col-lg-6 col-md-6 mx-auto"
            data-aos="zoom-in"
            data-aos-delay="100"
          >
            <div
              className="line"
              style={{ height: "8px", backgroundColor: "#017129" }}
            ></div>
            <div className="box">

              <div>
                <Link to="/partner-with-us" className="btn-buy">
                  Know More
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PartnerWithUs;
