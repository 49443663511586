import React, { useEffect } from "react";
import hospitality1 from "../../assets/hospitalityCard.jpg";
import healthcare1 from "../../assets/healthcareHome.png";
import { Link, useLocation } from "react-router-dom";
const WorkInProgress = () => {
  const {pathname} = useLocation();

  useEffect(() => {
    document.documentElement.scrollTo({
      top:0,
      left:0,
      behavior:"auto"
    })
  }, [pathname])
  return (
    <section id="about2" className="about2 pt-4">
      <div className="container2">
      <div className='text-center mb-4'>
          <h2 className="headings" style={{fontSize:"2rem"}}>Projects in Progress</h2>
        </div>
        <div className="row no-gutters m-auto">
          <div
            className="content2 col-lg-6 col-md-6 col-sm-12 col-xs-12 text-center"
            data-aos="fade-up"
          >
            <div className="">
              <img src={healthcare1}  width="500px" height="100%"  className="img-fluid"/> <br />
            </div>
          </div>
          <div className="content2 col-lg-6 col-md-6 col-sm-12 col-xs-12 d-flex m-auto">
            <div className="m-auto"><h4 style={{fontWeight: "bold", color: "#017129"}}> Healthcare</h4>
              <p style={{ textAlign: "justify" }}>
              Sovereign Pacific Capital understands that the healthcare sector worldwide is the most important and fast-growing industries. The key drivers are demographic trends, medical progress, and the resulting innovations, rising prosperity and improved access to medical products and services.


              </p>
              <Link to="/work-in-progress/healthcare" className="about-btn2">
                Know More ➜
              </Link>
            </div>
          </div>
        </div>
        <div className="row no-gutters pt-4 m-auto">
          <div
            className="content2 col-lg-6 col-md-6 col-sm-12 col-xs-12 text-center"
            data-aos="fade-up"
          >
            <div className="">
              <img src={hospitality1} width="500px" height="100%" className="img-fluid"/> <br />
            </div>
          </div>
          <div className="content2 col-lg-6 col-md-6 col-sm-12 d-flex m-auto">
            <div className="m-auto "> <h4 style={{fontWeight: "bold", color: "#017129"}}> Hospitality</h4>
              <p style={{ textAlign: "justify" }}>
              Sovereign Pacific Capital is currently citing funds targeting hospitality investment opportunities in the Northeast states of India. With a total project investment size of 1000 rooms in luxury properties.
              </p>
              <Link to="/work-in-progress/hospitality" className="about-btn2">
                Know More ➜
              </Link>
            </div>
          </div>
        </div>

      </div>
    </section>
  );
};

export default WorkInProgress;
