import React, { useEffect } from "react";
import Header from "../../components/Header/Header";
import HeaderImg from "../../assets/IndustryHeaderIll.jpg";
import { useLocation } from "react-router-dom";

const PrivacyPolicy = () => {

  const {pathname} = useLocation();

  useEffect(() => {
    document.documentElement.scrollTo({
      top:0,
      left:0,
      behavior:"auto"
    })
  }, [pathname])
  return (
    <>
      <Header heading={"Privacy Policy"} src={HeaderImg} />
      <div className="container contentFont mt-5" style={{fontWeight:400}}>
        At SPC, we respect your privacy and are committed to
        protecting your personal information. Our privacy policy detailed below
        explains what information we may gather from your visit to our web sites
        and how such information may be used.
        <br /> <br />
        <h3 className="headings">1. How do we use information?</h3>
        We use the information that you provide to us when you submit on a page on
        this site, fill out a survey, or otherwise voluntarily tell us about
        yourself or your activities, for our own use to improve our products and
        services and the content of this site. In addition we may use the
        information you provide us to send you marketing information about
        SPC products/ services.
        <br /><br />
        <h3 className="headings">2. Third party providers</h3>
         Wemay provide links to third party sites. As we do not control these web
        sites, SPC is not responsible for the privacy
        practices of those web sites or the reliability of the information
        presented by such site. We therefore encourage you to review the
        policies posted on these and all third party sites.
        <br /><br />
        <h3 className="headings">3. Profile and/or Registration Information</h3>

        The information and preferences you provide
        during registration is used for presenting and improving content and
        services that may be applicable to you. We may share this information
        with our partners only if you indicate interest in products that are
        serviced through our partners and their related concerns. We may use
        demographic and profile data for statistical analysis.
        <br /><br />
        <h3 className="headings">4. Cookies</h3>
        SPC. uses browser-based cookies mainly for the
        purpose of identifying you on subsequent visits to our web sites.
        Occasionally, information from cookies may be used to tailor services
        that may better suit you. Please note that should you disable your
        browser from accepting cookies, some information or services may not be
        able to you.
        <br /><br />
        <h3 className="headings">5. Privacy Policy Updates</h3>

        As we add more features and
        services to help you make better financial decisions, we update our
        privacy policies from time to time. Please visit this page often to
        review latest information on privacy practices at Dynasty Pacific
        Wealth.
      </div>
    </>
  );
};

export default PrivacyPolicy;
