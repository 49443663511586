import React from "react";
import Img1 from "./images/spot-1.jpeg";
import Img from "./images/spot.webp";
import Img3 from "./images/spot-3.jpeg";
import Img4 from "./images/spot-4.jpeg";
import Img5 from "./images/spot-5.jpeg";
import Img6 from "./images/spot-6.jpeg";
import Img7 from "./images/spot-7.jpeg";
import Img8 from "./images/spot-8.jpeg";
import Img9 from "./images/spot-9.jpeg";
import Img10 from "./images/spot-10.jpg";

import "./SpcBank.css";

const SpcSpotlightLatest = () => {
  const [showDetails, setShowDetails] = React.useState(false);
  return (
    <section id="" className="main_spc_bank">
      <div className="row mb-4 d-flex align-items-center">
        <div
          className="col-lg-2 col-md-6 col-sm-8 col-xs-6"
          style={{ background: "#ff3131" }}
        >
          <div className="p-2" style={{ width: "100%" }}>
            <div className="">
              <h4 className="text-white">SPC Spotlight</h4>
            </div>
          </div>
        </div>
        <div className="col-lg-10 col-md-6 col-sm-4 col-xs-6">
          <hr />
        </div>
      </div>
      <div className="d-flex align-items-center">
        {" "}
        <h4
          style={{
            color: "green",
            margin: "auto",
            textAlign: "center",
            fontFamily: "sans-serif",
            letterSpacing: "1px",
          }}
        >
          “Building Global Bridges: <br />
          Milestones from the High-Level Saint Kitts and Nevis <br />
          MoFA Delegation in India”
          <br />
          <br />
        </h4>
      </div>
      <div className="container mt-3 mb-3">
        <div className="row no-gutters text-center">
          <div className="image col-xl-3">
            <div>
              <div className="image_number_style">L1</div>
              <a
                href={Img4}
                data-gallery="portfolio-gallery-app"
                class="glightbox preview-link"
              >
                <img
                  src={Img4}
                  alt=""
                  width={"90%"}
                  style={{
                    borderRadius: "25px",
                    background: "green",
                    padding: "3px",
                  }}
                />
              </a>
            </div>
            <div>
              <div className="image_number_style">L2</div>
              <a
                href={Img7}
                data-gallery="portfolio-gallery-app"
                class="glightbox preview-link"
              >
                <img
                  src={Img7}
                  alt=""
                  width={"90%"}
                  style={{
                    borderRadius: "25px",
                    background: "green",
                    padding: "3px",
                  }}
                />
              </a>
            </div>
          </div>
          <div className="image col-xl-6">
            <div>
              <div className="image_number_style_center">C1</div>
              <a
                href={Img5}
                data-gallery="portfolio-gallery-app"
                class="glightbox preview-link"
              >
                <img
                  className="glightbox preview-link"
                  src={Img5}
                  alt=""
                  width={"90%"}
                  style={{
                    borderRadius: "25px",
                    background: "green",
                    padding: "3px",
                  }}
                />
              </a>
            </div>
          </div>

          <div className="image col-xl-3">
            <div className="image_number_style">R1</div>
            <a
              href={Img3}
              data-gallery="portfolio-gallery-app"
              class="glightbox preview-link"
            >
              <img
                src={Img3}
                alt=""
                width={"90%"}
                style={{
                  borderRadius: "25px",
                  background: "green",
                  padding: "3px",
                }}
              />
            </a>
            <div className="image_number_style">R2</div>
            <a
              href={Img9}
              data-gallery="portfolio-gallery-app"
              class="glightbox preview-link"
            >
              <img
                src={Img9}
                alt=""
                width={"90%"}
                style={{
                  borderRadius: "25px",
                  background: "green",
                  padding: "3px",
                }}
              />
            </a>
          </div>
        </div>
      </div>
      <br />
      <div className="container mt-3 mb-3">
        <div className="row no-gutters text-center">
          <div className="image col-xl-3">
            <div className="image_number_style">L3</div>
            <a
              href={Img}
              data-gallery="portfolio-gallery-app"
              class="glightbox preview-link"
            >
              <img
                src={Img}
                alt=""
                width={"90%"}
                style={{
                  borderRadius: "25px",
                  background: "green",
                  padding: "3px",
                }}
              />
            </a>
            <br />
            <br />
            <div className="image_number_style">L4</div>
            <a
              href={Img10}
              data-gallery="portfolio-gallery-app"
              class="glightbox preview-link"
            >
              <img
                src={Img10}
                alt=""
                width={"90%"}
                style={{
                  borderRadius: "25px",
                  background: "green",
                  padding: "3px",
                }}
              />
            </a>
          </div>
          <div className="image col-xl-6">
            <div className="image_number_style_center">C2</div>
            <a
              href={Img1}
              data-gallery="portfolio-gallery-app"
              class="glightbox preview-link"
            >
              <img
                src={Img1}
                alt=""
                width={"90%"}
                style={{
                  borderRadius: "25px",
                  background: "green",
                  padding: "3px",
                }}
              />
            </a>
          </div>

          <div className="image col-xl-3">
            <div className="image_number_style">R3</div>
            <a
              href={Img8}
              data-gallery="portfolio-gallery-app"
              class="glightbox preview-link"
            >
              <img
                src={Img8}
                alt=""
                width={"90%"}
                style={{
                  borderRadius: "25px",
                  background: "green",
                  padding: "3px",
                }}
              />
            </a>

            <div className="image_number_style">R4</div>
            <a
              href={Img6}
              data-gallery="portfolio-gallery-app"
              class="glightbox preview-link"
            >
              <img
                src={Img6}
                alt=""
                width={"90%"}
                style={{
                  borderRadius: "25px",
                  background: "green",
                  padding: "3px",
                }}
              />
            </a>
          </div>
        </div>
      </div>
      <div className="text-center">
        <button class="btn" onClick={() => setShowDetails(!showDetails)}>
          Read {showDetails ? "Less" : "More"}
        </button>
      </div>
      {showDetails && (
        <div className="mt-5">
          <div className="row text-justify">
            <div className="col-md-4">
              L1:{" "}
              <i style={{ color: "gray", fontSize: "14px" }}>
                With honourable Shri Pralhad Joshi Minister of Consumer Affairs,
                Food and Public Distribution
              </i>{" "}
              <br />
              L2:{" "}
              <i style={{ color: "gray", fontSize: "14px" }}>
                With honourable Shri JP Nadda Minister of Health, Family Welfare
                and president of BJP India
              </i>
              <br />
            </div>
            <div className="col-md-4">
              C1:{" "}
              <i style={{ color: "gray", fontSize: "14px" }}>
                With honourable Dr. S. Jaishankar Minister of External Affairs{" "}
              </i>
              <br />
            </div>
            <div className="col-md-4">
              R1:{" "}
              <i style={{ color: "gray", fontSize: "14px" }}>
                honourable Shri Prime Minister of India and Dr. HR Nagendra
                Padma Shri, Writer and the Founder Vice-Chancellor of S-VYASA,
              </i>
              <br />
              R2:{" "}
              <i style={{ color: "gray", fontSize: "14px" }}>
                With honourable Shri HR Nagendra Padam Shri, Writer and the
                Founder Vice-Chancellor of S-VYASA and Shri Rabindra Acharya,
                S-VYASA
              </i>{" "}
              <br />
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-md-4">
              L3:{" "}
              <i style={{ color: "gray", fontSize: "14px" }}>
                MOU signed for digital transformation
              </i>{" "}
              <br />
              L4:{" "}
              <i style={{ color: "gray", fontSize: "14px" }}>
                Team visited hi-tech central warehouse of PMBI (Jan Aushadhi)
              </i>{" "}
              <br />
            </div>
            <div className="col-md-4">
              C2:{" "}
              <i style={{ color: "gray", fontSize: "14px" }}>
                With honourable Shri Jitin Prasada, State Minister of Commerce
                and Industry, Electronics and Information Technology{" "}
              </i>
              <br />
            </div>
            <div className="col-md-4">
              R3:{" "}
              <i style={{ color: "gray", fontSize: "14px" }}>
                With honourable Shri Mansukh L. Mandaviya Minister of Labour and
                Employment, Youth Affairs and Sports
              </i>{" "}
              <br />
              R4:{" "}
              <i style={{ color: "gray", fontSize: "14px" }}>
                Dinner hosted by honourable Dr. S Jaishankar Minister of
                External Affairs at Hyderabad House{" "}
              </i>
              <br />
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default SpcSpotlightLatest;
