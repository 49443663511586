import React from "react";
import wm from "../../assets/healthcareHome.png";
import { Link } from "react-router-dom";

const CurrentNews = () => {
  return (
    <section id="about2" className="about2 pt-4 pb-4">
      <div className="container2">
        <header className="text-center mb-5">
          <h1 className="headings" style={{fontSize:"2rem"}}>Current News</h1>
        </header>
        <div className="row no-gutters mb-4">
          <div className="content2 col-lg-6 col-md-6 col-sm-12 d-flex m-auto">
            <div>
              <div className="text-left">
                <h4 style={{ color: " #017129", fontWeight: "bold" }}>
                  Healthcare Partnership in India
                </h4>
              </div>
              <p style={{ textAlign: "justify" }}>
                SPC has taken the next step in introducing the largest most
                advanced Sports University with an investment partner - Abhinav
                Futuristics Private Limited, an organization that aims at
                bringing synergy of Technology, Expertise and Passion in the
                Sports and Healthcare Ecosystem. SPC is the investment partner
                for building up Asia’s most advanced and biggest
                Sports and Medical rehabilitation Centre, to help athletes from
                all fields in India and South East Asia to have access to the
                best treatments and therapy programs to help them recover and
                rehabilitate. The Sports University will not only provide Sports
                Medicine to athletes but also provide them with an access to the
                best Sports Academy for training and mastering their art along
                with an institute to train future Medical experts in the field
                of Sports Medicine.
              </p>
              <Link
                to="/work-in-progress/healthcare"
                className="about-btn2"
              >
                Know More ➜
              </Link>
            </div>
          </div>
          <div
            className="content2 col-lg-6 col-md-6 col-sm-12 text-center"
            data-aos="fade-up"
          >
            <div className="m-auto mt-3">
              <img src={wm} width="100%" height="100%" /> <br />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CurrentNews;
