import React from "react";
import { Link } from "react-router-dom";
import career from "../../assets/1.png";

const Careers = () => {
  return (
    <section id="features" className="features">
      <div className="container" data-aos="fade-up">
        <div className="row feture-tabs" data-aos="fade-up">
          <div className="col-lg-6" style={{ paddingTop: "8%" }}>
            <h3 style={{color:" #017129",fontWeight:"bolder"}}>SPC Careers</h3>
            <p>
              At SPC, we grow, learn, and innovate. We encourage creative
              thinking, collaboration, individuality, and diversity. When it
              comes to solving our clients biggest financial concerns, anything
              is possible at SPC.
            </p>

            <ul className="mb-3">
              <li>
                <Link
                  style={{
                    color: " #017129",
                    fontWeight: "bold",
                    fontSize: "larger",
                    textDecoration:"none"
                  }}
                  to="/careers"
                >
                  For Professionals ➜
                </Link>
              </li>
              <br />
              <li>
                <Link
                  style={{
                    color: "#017129",
                    fontWeight: "bold",
                    fontSize: "larger",
                    textDecoration:"none"
                  }}
                  to="/careers"
                >
                  For Interns ➜
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-lg-6">
            <img src={career} className="img-fluid" alt="" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Careers;
