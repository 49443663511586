import React, { useEffect } from "react";
import Header from "../../components/Header/Header";

import pm1 from "../../assets/pm1.png";
import pm2 from "../../assets/pm2.png";
import investmentHeader from "../../assets/wwoHead.jpg";

import pm from "../../assets/pm.png";
import "./WhatWeOffer.css";
import { useLocation } from "react-router-dom";
const PortfolioManagement = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto",
    });
  }, [pathname]);
  return (
    <>
      <Header src={investmentHeader} heading="What We Offer" />
      <div className="container mt-5">

        <div className="row featurette">

          <div className="col column">
          <h4 className=" headings mt-0 mb-2 text-left pt-5">
          Portfolio Management
        </h4>
            <p className="contentFont">
              SPC respects our clients achievements and acknowledges the time
              and effort the client has put in to reach the point where they sit
              with us and can strategically plan on how to secure and increase
              their wealth. We exclusively work with them to design secure
              financial plans. Our Portfolio Management Package helps our
              clients allocate assets with the right investment plan by looking
              at market trends of both past and present and design the best
              investment options they can opt for themselves. Our financial
              advisors also help to find the best options for an alternative
              investment plan, as we observe our previous clients shifting to
              alternatives to boost returns, generate income, provide
              diversification from traditional investments, and achieve their
              financial goals.

            </p>
          </div>
          <div className="col text-center">
            <img src={pm1} height="400px" width="500px" />
          </div>
        </div>
        <div className="row featurette mt-5 ">
          <div className="col-md-5 col-sm-12 order-md-2 column col">
            <p className="contentFont p-3">
              These alternative investments can be in private equity, private
              credit, infrastructure, and private real estate. These stand to be
              more complex and less frequently traded than public stocks and
              bonds, and give our clients a better access to additional sources
              of return. Public market investments such as hedge funds operate
              mainly in public markets but use less traditional tools such as
              short-selling and leverage. We at SPC look beyond public markets
              and traditional investments to find solutions in real estate,
              infrastructure and multi-alternatives.
              <br/>
              Our strength in building our portfolio management services lies in
              the priority we give to each of our clients financial plans, by
              looking into the risk management factor which evolves through time
              and diversify options for a secure investment. We give our clients
              access to quality opportunities of our services, greater
              transparency, an overall integrated view of the financial design
              plan and a new standard of alignment with a team of professional
              advisors and financial specialist.

            </p>
          </div>
          <div className="col-md-5 order-md-1">
            <img src={pm2} height="400px" width="500px" />
          </div>
        </div>
        <div className="row featurette mt-5">
          <div className="col-md-6 order-md-1 m-auto">
            <p className="contentFont">
              We seek to deliver outperformance with true partnership with all
              our clients. Our strength in building our portfolio management
              services lies in the priority we give to each of our clients
              financial plans and look into the risk management factor that can
              evolve through time and diversify options for them to securely
              invest through our financial plan. We give our clients access to
              quality opportunities of our services, greater transparency, an
              overall integrated view of the financial design plan we have for
              them and a new standard of alignment with a team of professional
              advisors and financial specialist to understand our clients’ needs
              and deliver SPC’s best use of skill, technology and scale to help
              achieve the clients goal.
            </p>
          </div>
          <div className="col-md-6 text-center order-md-2">
            <img src={pm} height="400px" width="500px" />
          </div>
        </div>
      </div>
    </>
  );
};

export default PortfolioManagement;
