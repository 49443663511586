import React from "react";
import Beryllus from "./images/beryllus-capital.jpeg";
import RBC from "./images/Royal-Bank-of-Canada.png";
import BON from "./images/bon.jpeg";

const Partners = () => {
  return (
    <section id="clients" className="clients section-bg">
      <div className=" mx-auto container">
        <h2 className="headings text-center" style={{ fontSize: "2rem" }}>
          Our Strategic Partners
        </h2>
        <div className="row">
          <div
            className=" mx-auto col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center"
            data-aos="zoom-in"
          >
            <img src={Beryllus} className="img-fluid" alt="" />
          </div>
          <div
            className=" mx-auto col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center"
            data-aos="zoom-in"
          >
            <img
              src="https://dynastypacificwealth.org/wp-content/uploads/2022/09/image2-300x169.png"
              className="img-fluid"
              alt=""
            />
          </div>
          <div
            className=" mx-auto col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center"
            data-aos="zoom-in"
          >
            <img
              src="https://1000logos.net/wp-content/uploads/2021/05/UBS-logo.png"
              className="img-fluid"
              alt=""
            />
          </div>

          <div
            className=" mx-auto  col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center"
            data-aos="zoom-in"
          >
            <img
              src="https://dynastypacificwealth.org/wp-content/uploads/2022/10/PwC-PricewaterhouseCoopers-Symbol-300x169.png"
              className="img-fluid"
              alt=""
            />
          </div>
        </div>
        <div className="row">
          <div
            className=" mx-auto  col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center"
            data-aos="zoom-in"
          >
            <img
              src="https://dynastypacificwealth.org/wp-content/uploads/2022/09/image4-300x169.png"
              className="img-fluid"
              alt=""
            />
          </div>
          <div
            className=" mx-auto  col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center"
            data-aos="zoom-in"
          >
            <img src={BON} className="img-fluid" alt="" />
          </div>
          <div
            className=" mx-auto  col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center"
            data-aos="zoom-in"
          >
            <img src={RBC} className="img-fluid" alt="" />
          </div>
          <div
            className=" mx-auto col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center"
            data-aos="zoom-in"
          >
            <img
              src="https://dynastypacificwealth.org/wp-content/uploads/2022/09/image6-300x169.png"
              className="img-fluid"
              alt=""
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Partners;
