import React from 'react'
import Header from '../../components/Header/Header'
import error404 from '../../assets/error404.jpg'
const Error404 = () => {
  return (
    <div>
        <Header heading="Error" src={error404}/>
    </div>
  )
}

export default Error404