import React, {useState} from 'react'
import Button from '../../components/Button/Button'
import InvestmentForm from '../InvestmentForm.js/InvestmentForm'
import {
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
  } from 'mdb-react-ui-kit';
import ProfessionalForm from '../ProfessionalForm.js/ProfessionalForm';
const Modal2 = (props) => {
    const [basicModal, setBasicModal] = useState(false);

    const toggleShow = () => setBasicModal(!basicModal);

  return (
    <>
      <button onClick={toggleShow} className="modal-lg btn btn">{props.text}</button>
      <MDBModal show={basicModal} setShow={setBasicModal} tabIndex='-1' className="modal-lg">
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>{props.title}</MDBModalTitle>
              <MDBBtn className='btn-close' color='none' onClick={toggleShow}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody><ProfessionalForm/></MDBModalBody>

            <MDBModalFooter>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  )
}

export default Modal2