import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import './ApiData.css'
const ApiData = () => {
  const showRef = useRef();
  const hideRef = useRef();
  const [data, setData] = useState([]);
  const options = {
    method: "GET",
    url: "https://yh-finance.p.rapidapi.com/market/v2/get-summary",
    params: { region: "IN" },
    headers: {
      "X-RapidAPI-Key": "54d3805ac4msha8224eb1a0e8888p181532jsn96cfc9e8b8df",
      "X-RapidAPI-Host": "yh-finance.p.rapidapi.com",
    },
  };
  useEffect(() => {
  }, []);

  const getData = () => {
    axios
      .request(options)
      .then(function (response) {
        console.log(response.data?.marketSummaryAndSparkResponse.result);

        setData(response.data?.marketSummaryAndSparkResponse.result)
      })
      .catch(function (error) {
        console.error(error);
      });
      if(data){
        showRef.current.style.display = "none"
        hideRef.current.style.display = "flex"
      }
  };
  const hideData = () => {
   setData([]);
   if(data){
    showRef.current.style.display = "flex"
    hideRef.current.style.display = "none"
  }
  };

  

  return (
    <div>

      <section
        id="pricing"
        className="pricing"
        style={{ background: "#c8edda49" }}
      >
        <div className="container" data-aos="fade-up">
          <header className="text-center mt-3 mb-3">
            <h2 className="mb-3 text-center" style={{fontWeight : "bold",color:"#017129"}}>Global Market Trends</h2>
            <button ref={showRef} onClick={getData} className="btn mx-auto">Show More</button> 
            <button ref={hideRef} onClick={hideData} className="btn mx-auto" style={{display:"none"}}>Show Less</button> 
          </header>

          <div className="row gy-4" data-aos="fade-left" >
          {data.filter((d) => (!(d.shortName == undefined || d.shortName === "CMC Crypto 200"))).map((market) => (
    <div
      className="col-lg-3 mx-auto"
      data-aos="zoom-in"
      data-aos-delay="100"
      key={market.shortName}

    >
      <div
        className="line"
        style={{ height: "8px", backgroundColor: "#017129",  }}
      ></div>
      <div className="box p-2" style={{ textAlign: "center"}}>
        <div className="row">
          <div className="col-md-6">
            <p>{market.shortName}</p>
          </div>
          <div className="col-md-6">
            <p>₹ {market.regularMarketPreviousClose?.fmt}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <p>Market Time</p>
          </div>
          <div className="col-md-6">
            <p>{market.regularMarketTime?.fmt}</p>
          </div>
        </div>
      </div>
    </div>
    ))}
  </div>
        </div>
      </section>
    </div>
  );
};

export default ApiData;
