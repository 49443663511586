import React, { useEffect } from 'react'
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import ContactForm from '../../components/ContactForm/ContactForm';
import Header from '../../components/Header/Header';
import contactHeader11 from '../../assets/contactHeader111.png'
import {
  MDBCard,
  MDBBtn
} from 'mdb-react-ui-kit';
import InvestmentForm from '../../components/InvestmentForm.js/InvestmentForm';
import { useLocation } from 'react-router-dom';
const ContactUs = () => {
  const {pathname} = useLocation();

  useEffect(() => {
    document.documentElement.scrollTo({
      top:0,
      left:0,
      behavior:"auto"
    })
  }, [pathname])
  return (
    <>
    <Header src={contactHeader11} heading="." />
    <MDBCard style={{width: "90%"}} className="mt-5 mb-5 p-3 mx-auto">
    <Row>
      <Col className='mx-auto'>
      <ContactForm/>
      </Col>

    </Row>
    </MDBCard>
    </>
  )
}

export default ContactUs