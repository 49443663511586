import React from 'react'
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

const ContactForm = () => {
  return (
    <>
    <Form action="https://formsubmit.co/info@spcgroup.org" method="post">

    <Form.Group className="mb-3" >
        <Form.Control name='name' placeholder="Name" />
      </Form.Group>
      <Form.Group >
          <Form.Control name='email' type="email" placeholder="Email" className="mb-3"/>
        </Form.Group>
      <Row className="mb-3">
        <Form.Group as={Col} controlId="formGridEmail">
          <Form.Control name='title' type="text" placeholder="Title" />
        </Form.Group>

        <Form.Group as={Col} controlId="formGridPassword">
          <Form.Control name='companyName' type="text" placeholder="Company Name" />
        </Form.Group>
      </Row>
      <Form.Control name='description' as="textarea" placeholder="Description" className='mb-3'/>
  <Button variant="primary" type="submit">
        Submit
      </Button>
    </Form>
    </>
  )
}

export default ContactForm