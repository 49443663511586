import React from "react";
import * as RiIcons from "react-icons/ri";

export const SideBarData = [
  {
    title: "Home",
    path: "/",
  },
  {
    title: "About Us",
    iconOpen: <RiIcons.RiArrowDownFill />,
    iconClose: <RiIcons.RiArrowDownFill />,
    subNav: [
      {
        title: "Our People",
        path: "/about-us/our-people/",
        cName: "sub-nav",
      },
      {
        title: "Our Culture",
        path: "/about-us/our-culture/",
        cName: "sub-nav",
      },
      {
        title: "Our Focus",
        path: "/about-us/our-focus/",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "Media",
    iconOpen: <RiIcons.RiArrowDownFill />,
    iconClose: <RiIcons.RiArrowDownFill />,
    subNav: [
      {
        title: "Gallery",
        path: "/about-us/gallery/",
        cName: "sub-nav",
      },
      {
        title: "News",
        path: "/about-us/media/",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "What We Offer",
    iconOpen: <RiIcons.RiArrowDownFill />,
    iconClose: <RiIcons.RiArrowDownFill />,
    subNav: [
      {
        title: "Investment Management",
        path: "/what-we-offer/investments",
        cName: "sub-nav",
      },
      {
        title: "Portfolio Management",
        path: "/what-we-offer/portfolio-management",
        cName: "sub-nav",
      },
      {
        title: "Wealth Management",
        path: "/what-we-offer/wealth-management",
        cName: "sub-nav",
      },
      {
        title: "Corporate Counselling",
        path: "/what-we-offer/corporate-counselling",
        cName: "sub-nav",
      },
      {
        title: "Trust and Endowment Management",
        path: "/what-we-offer/trust-and-endowment-management",
        cName: "sub-nav",
      },
      {
        title: "Mergers and Acquisitions",
        path: "/what-we-offer/mergers-and-acquisitions",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "Founder Message",
    iconOpen: <RiIcons.RiArrowDownFill />,
    iconClose: <RiIcons.RiArrowDownFill />,
    subNav: [
      {
        title: "Nov 2022",
        path: "/nov-msg",
        cName: "sub-nav",
      },
      {
        title: "March 2023",
        path: "/march-msg",
        cName: "sub-nav",
      },
      {
        title: "Sept 2024",
        path: "/sept-msg",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "SPC Spotlight",
    path: "/spc-spotlight-archive",
  },
  // {
  //   title: "Our Work",
  //   iconOpen: <RiIcons.RiArrowDownFill />,
  //   iconClose: <RiIcons.RiArrowDownFill />,
  //   subNav: [
  //     {
  //       title: "Our Projects",
  //       path: "/our-work",
  //       cName: "sub-nav",
  //     },
  //     {
  //       title: "Projects in Progress",
  //       path: "/work-in-progress",
  //       cName: "sub-nav",
  //     },
  //   ],
  // },

  {
    title: "Industry",
    iconOpen: <RiIcons.RiArrowDownFill />,
    iconClose: <RiIcons.RiArrowDownFill />,
    subNav: [
      {
        title: "Healthcare",
        path: "/industry/healthcare",
        cName: "sub-nav",
      },
      {
        title: "Energy",
        path: "/industry/energy",
        cName: "sub-nav",
      },
      {
        title: "Real Estate",
        path: "/industry/real-estate",
        cName: "sub-nav",
      },
      {
        title: "Hospitality",
        path: "/industry/hospitality",
        cName: "sub-nav",
      },
      {
        title: "Sustainability and Impact",
        path: "/industry/sustainability-and-impact",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "Careers",
    path: "/careers",
    iconOpen: <RiIcons.RiArrowDownFill />,
    iconClose: <RiIcons.RiArrowDownFill />,
  },
  {
    title: "Contact Us",
    path: "/contact-us",
  },
];
